
//AiFillCaretDown
import { IoCaretDownSharp, IoCaretUpSharp } from "react-icons/io5";
import {useState} from 'react'
import { useEffect } from "react";

import {timeZoneDatetimeConverter} from "../../../../src/Helpers/timeZoneDatetimeConverter"

import TimeZoneSetterCompo from './../../ForexHome/ForexHome_child_component/TimeZoneSetterCompo'



const Footer = ({
    liveUpdateSECcountdown,
    reloadALLchartGlobalFUNC,
    tf_def,
    currentORhistoric,
    datetime,
    forex_data_length,
    forex_data_index,
    day_of_week
}) => {

//console.log('day_of_week : ', day_of_week)


    const zero_gmt_deviation = 3//mql4 +3


    /*useEffect(()=>{
        const zero_gmt_deviation = 3
        const gmt_hour_count = -1
        console.log('datetime : ', datetime)
        //console.log('timeZoneCov : ', timeZoneDatetimeConverter('2023-01-01 04:59:40', gmt_hour_count, zero_gmt_deviation))
        console.log('timeZoneCov : ', timeZoneDatetimeConverter(datetime, gmt_hour_count, zero_gmt_deviation))
    }, [])*/

return (
<div
style={{
marginTop:'3.5vh',
//border: '1px solid black',
color: 'gray',
fontSize: '0.7em',
textAlign: 'center',
//marginTop:'10px',
fontStyle:'italic',
display: 'flex',
justifyContent: 'space-around',
alignItems: 'center',
}}
>

    <div
    style={{
        width: '100%',
        //height:'100%',
        //border: '1px solid black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        //marginLeft:'10%',

    }}
    >
        {false && <div
        style={{
            marginLeft:'20%',
        }}
        >
            {forex_data_index===forex_data_length-1 ? 'current price' : 'historic price'}
        </div>}

        <div
        style={{
            //width: '100%',
            height:'100%',
            //border: '1px solid black',
            display: 'flex',
            //justifyContent: 'center',
            alignItems: 'center',
            //marginLeft:'10%',
            fontWeight:'bold',
            color:'green'
        }}
        >
            <div>
                <div>Live</div>
                <div>Update</div>
            </div>

            <div className="coin4"
            style={{
                //border:'1px solid black',
                height:'10px', 
                width:'10px',
                borderRadius:'50%',
                backgroundColor:'lightgreen',
                marginLeft:'10px', marginRight:'10px'
            }}
            >{liveUpdateSECcountdown}</div>

            <div
            style={{color:'rgba(0,0,255,0.7)'}}
            >
                {`${liveUpdateSECcountdown}s`}
            </div>
        </div>
        
    </div>

    <div
    style={{
        width: '100%',
        display:'flex',
        justifyContent: 'center',
        //border: '1px solid black',
    }}
    >
        <div>{`${tf_def+' ago @ '}`}</div>

        {
            <TimeZoneSetterCompo
            id={'100'}
            zero_gmt_deviation={zero_gmt_deviation}
            datetime={datetime}
            reloadALLchartGlobalFUNC={reloadALLchartGlobalFUNC}
            />
        }

    </div>


    <div
    style={{
        width: '100%',
        //border: '1px solid black',
        borderRadius: '15px',
        //padding: '7px',
        //boxShadow:`${ `rgba(0, 0, 211, 0.4) 0px 7px 29px 0px` }`,
        //cursor:'pointer',
        //backgroundColor:'rgba(112,112,112, 0.5)',
        //color: 'black',
        display: 'flex',
        justifyContent: 'center', 
        alignItems: 'center',
    }}
    >

        <div
        style={{
            width: '70%',
            //border: '1px solid black',
            borderRadius: '15px',
            //padding: '7px',
            boxShadow:`${ `rgba(0, 0, 211, 0.4) 0px 7px 29px 0px` }`,
            //cursor:'pointer',
            backgroundColor:'white',
            //color: 'black',
        }}

        /*onClick={()=>{
            alert('edit datetime & update at the same index')
        }}*/
        >
        <div id={'tfCURRSdateId'}
        style={{
            color: `
            ${
                (
                    (
                        (forex_data_index===forex_data_length-1) &&
                    day_of_week >= 5 && 
                    Number(datetime.split(' ')[1].split(':')[0]) === 23 &&
                    Number(datetime.split(' ')[1].split(':')[1]) > 50)
                    || 
                    (
                        (forex_data_index===forex_data_length-1) &&
                        day_of_week === 6 
                    )
                )

                ? 
                'rgba(255,0,0,0.5)' :
                'black'
            }
            `,
            fontWeight: `
            ${
                (
                    (
                        (forex_data_index===forex_data_length-1) &&
                    day_of_week >= 5 && 
                    Number(datetime.split(' ')[1].split(':')[0]) === 23 &&
                    Number(datetime.split(' ')[1].split(':')[1]) > 50)
                    || 
                    (
                        (forex_data_index===forex_data_length-1) &&
                        day_of_week === 6 
                    )
                )

                ? 
                'bold' :
                ''
            }
            `,
        }}
        >
            {
                (
                    (
                        (forex_data_index===forex_data_length-1) &&
                    day_of_week >= 5 && 
                    Number(datetime.split(' ')[1].split(':')[0]) === 23 &&
                    Number(datetime.split(' ')[1].split(':')[1]) > 50)
                    || 
                    (
                        (forex_data_index===forex_data_length-1) &&
                        day_of_week === 6 
                    )
                )

                ? 
                'MARKET' : datetime.includes('1970') ? datetime.split(' ')[0] : 
                timeZoneDatetimeConverter(
                    datetime, 
                    localStorage.getItem('gmt_hour_count')===null ? zero_gmt_deviation : Number(localStorage.getItem('gmt_hour_count')), 
                    zero_gmt_deviation).split(' ')[0]
            }
        </div>
  
        <div
        /*onClick={()=>{
            //console.log('datetime clicked')

            //-----------------------------------------------------------------------------------------
            //fetch(`http://${ window.innerWidth < 400 ? '192.168.43.98' : 'localhost'}:5500/get_forex_data`)
            fetch(
                window.location.hostname==='localhost' && false
                ?
                `http://${ window.innerWidth < 400 ? '192.168.43.98' : 'localhost'}:5500/get_ip` 
                :
                ``
            )
            .then((res) => {
                return res.json()//{"ip":"127.0.0.1"}
            })
            .then((resData)=>{
                
                if(resData.error){//alert error, if: resObj.error_key exists in the response object, if image does not exist
                    //SERVER DOWN ALERT, please send us an email on support@currencystrengthzone.com to get it resolved ASAP
                    //alert('no internet')
                    //setIsShowServerDownAlertCompo(true)
                }
                else{//without an resObj.error_key: return the name of the image deleted & an image deletion success msg
                    //console.log(resData)
                    
                    console.log(resData.ip)
                    
                }
            })
        }}*/
      

        style={{
            color: `
            ${
                (
                    (
                        (forex_data_index===forex_data_length-1) &&
                    day_of_week >= 5 && 
                    Number(datetime.split(' ')[1].split(':')[0]) === 23 &&
                    Number(datetime.split(' ')[1].split(':')[1]) > 50)
                    || 
                    (
                        (forex_data_index===forex_data_length-1) &&
                        day_of_week === 6 
                    )
                )

                ? 
                'rgba(255,0,0,0.5)' :
                'green'
            }
            `,
            fontWeight: `
            ${
                (
                    (
                        (forex_data_index===forex_data_length-1) &&
                    day_of_week >= 5 && 
                    Number(datetime.split(' ')[1].split(':')[0]) === 23 &&
                    Number(datetime.split(' ')[1].split(':')[1]) > 50)
                    || 
                    (
                        (forex_data_index===forex_data_length-1) &&
                        day_of_week === 6 
                    )
                )

                ? 
                'bold' :
                'bold'
            }
            `,
        }}
        >
            { 
                (
                    (
                        (forex_data_index===forex_data_length-1) &&
                    day_of_week >= 5 && 
                    Number(datetime.split(' ')[1].split(':')[0]) === 23 &&
                    Number(datetime.split(' ')[1].split(':')[1]) > 50)
                    || 
                    (
                        (forex_data_index===forex_data_length-1) &&
                        day_of_week === 6 
                    )
                )

                ? 
                'CLOSED' : 
                `${datetime.includes('1970') ? datetime.split(' ')[1].split(':')[0] : timeZoneDatetimeConverter(
                    datetime, 
                    localStorage.getItem('gmt_hour_count')===null ? zero_gmt_deviation : Number(localStorage.getItem('gmt_hour_count')), 
                    zero_gmt_deviation).split(' ')[1].split(':')[0]} : 
                ${datetime.includes('1970') ? datetime.split(' ')[1].split(':')[1] : timeZoneDatetimeConverter(
                    datetime, 
                    localStorage.getItem('gmt_hour_count')===null ? zero_gmt_deviation : Number(localStorage.getItem('gmt_hour_count')), 
                    zero_gmt_deviation).split(' ')[1].split(':')[1]}`
            }
        </div>

        </div>
        
    </div>
    

</div>
)
}

export default Footer
