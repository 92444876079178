
import React, { useEffect, useRef, useState } from 'react';


import { FaRegCheckCircle } from "react-icons/fa";
import { RiCloseCircleLine } from "react-icons/ri";
import { IoCloseOutline } from "react-icons/io5";
import { IoIosRefresh } from "react-icons/io";
import { FaRegEye } from "react-icons/fa";
import { VscTools } from "react-icons/vsc";
import { BsLock, BsUnlock } from "react-icons/bs";
import { MdZoomOutMap, MdZoomInMap   } from "react-icons/md";
import { MdDoNotTouch } from "react-icons/md";
import { MdTouchApp } from "react-icons/md";
import { LiaFastBackwardSolid } from "react-icons/lia";




const ChartToolsAlertCompo = ({

    setIsShowChartToolsAlertCompo,
    isShowChartToolsAlertCompo,

    isScreenScrollLocked,
    canvasCompoId,

    canvasWidth,
}) => {



return (
<div
style={{
    //
    
    position:'absolute',
    top:'25%',
    //bottom:`${canvasWidth < 450 ? '38%' : '38%'}`,
    left:'0px',
    right:'0px',
    zIndex:'5'
}}
>
    <div
    style={{
        position:'relative',
        backgroundColor: 'rgba(0,0,0,0.70)',
        margin:'0px auto',
        //width:'80%'
        width:`${canvasWidth < 500 ? canvasWidth * 0.8 :  canvasWidth * 0.7}px`,
        height:'100%',
        borderRadius:'25px',
        padding:'7px',
        paddingBottom:'20px'
        //paddingLeft:`${canvasWidth < 500 ? '1px' : '7px'}`,
    }}
    >
        <div
        style={{
            position:'absolute',
            right:'5%',
            cursor:'pointer'
        }}

        onClick={()=>{
            setIsShowChartToolsAlertCompo(!isShowChartToolsAlertCompo)
        }}
        >
            <IoCloseOutline
            style={{
                backgroundColor:'white',
                borderRadius:'50%',
                transform:'scale(1.5)'
            }}
            />
        </div>

        <h3
        style={{
            color:'gold',
            marginLeft:'5%', marginRight:'5%', //marginTop:'5px'
        }}
        >
            <span
            style={{
                backgroundColor:'rgba(255, 215, 0, 0.9)',
                color:'black', paddingLeft:'5px', paddingRight:'5px'
            }}
            >See</span> How to USE (All) our Chart <u>Tools</u> <VscTools className='coin'
            style={{marginLeft:'4px', transform:'scale(1.2)', color:'lightgreen'}}
            /> :
        </h3>

        <div
        style={{
            marginTop:'20px',
            borderTop:'1px solid gold',
            borderBottom:'1px solid gold',
            padding:'5px',
            borderRadius:'5px',
            //minWidth:'50%'
            marginLeft:'5%', marginRight:'5%'
        }} 
        >

            <div
            style={{
                display:'flex',
                alignItems: 'center',
            }}
            >
                <MdDoNotTouch style={{color:'gold', border:'1px solid gold', padding:'5px', borderRadius:'5px', marginRight:'5px'}}/>
                <BsLock style={{color:'gold', border:'1px solid gold', padding:'5px', borderRadius:'5px', marginRight:'5px'}}/>
                <MdZoomInMap style={{color:'gold', border:'1px solid gold', padding:'5px', borderRadius:'5px', marginRight:'5px'}}/>
                <LiaFastBackwardSolid style={{color:'gold', border:'1px solid gold', padding:'5px', borderRadius:'5px', marginRight:'5px'}}/>
                <span style={{color:'gold'}}>& more...</span>
            </div>


            <div
            style={{marginLeft:'', marginTop:'12px'}}
            >
                
                <button //className={'coin'}
                style={{
                    fontWeight:'bold',
                    border:'0px',
                    borderRadius:'5px',
                    padding: '5px',
                    backgroundColor:'gold',
                    color:'black',
                    cursor:'pointer',
                    width:'50%'
                }}

                onClick={()=>{

                    setIsShowChartToolsAlertCompo(!isShowChartToolsAlertCompo)

                    //console.log('hello...')
                    if(isScreenScrollLocked){
                        document.getElementById(`unlockButton-${canvasCompoId}`).click()
                    }

                    setTimeout(()=>{
                        const targetDiv = document.getElementById(`chartTools-id`)
                        targetDiv.scrollIntoView({
                            behavior: 'smooth' // You can change this to 'auto' for immediate scrolling without smooth animation.
                        })
                    }, 500)
                    

                    

                    
                }}
                >See
                <FaRegEye className={'coin'}
                style={{marginLeft:'7px', marginTop:'2px', transform:'scale(1.2)'}}
                />
                </button>
                
                {false &&<button
                style={{
                    fontWeight:'bold',
                    border:'0px',
                    borderRadius:'5px',
                    padding: '5px',
                    backgroundColor:'red',
                    color:'white',
                    cursor:'pointer',
                    marginLeft:'5%', 
                }}

                onClick={()=>{
                    setIsShowChartToolsAlertCompo(!isShowChartToolsAlertCompo)
                }}
                >Close
                <RiCloseCircleLine 
                style={{marginLeft:'4px', transform:'scale(1.5)'}}
                />
                </button>}
                
            </div>

        </div>



        <div
        style={{
            color:'white',
            fontSize:'12px',
            marginTop:'25px',
            marginLeft:'5%', marginRight:'5%'
        }}
        >
             
             <span style={{fontWeight:'bold'}}>NOTE :</span> if any Chart <span style={{fontWeight:'bold', color:'lightblue'}}>problem or error or new chart experience</span> - then simply 
            Click on the <span style={{fontWeight:'bold', color:'gold'}}>RELOAD button <IoIosRefresh/> </span>  on the <span style={{
            fontWeight:'bold', color:'lightblue'}}>bottom-right corner</span>  of the chart - Reload the Chart and data afresh - for a fresh and new chart experience
        </div>


    </div>
    
</div>
)
}

export default ChartToolsAlertCompo
