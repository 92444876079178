
/*
const obj =  {
    EUR: 70,
    GBP: 100,
    AUD: 0,
    NZD: 41,
    USD: 50,
    CAD: 56,
    CHF: 64,
    JPY: 80
}


console.log("result : ", orderCurrPerf(obj))*/


function orderCurrPerf(obj, dataTypeID/*orderType, curr_names_array*/){

    let key_array = []
    let val_array = []

    /*if(orderType === 'u'){
        for(let i = 0; i < curr_names_array.length; i++){
            const key = curr_names_array[i]
            key_array.push(key)
            val_array.push(obj[key])
        }
    }
    else{*/
        for(let key in obj) {
            key_array.push(key)
            val_array.push(obj[key][dataTypeID])//obj[key][dataTypeID]
        }
    //}
    

    //console.log('key_array : ', key_array)//['EUR', 'GBP','AUD', 'NZD','USD', 'CAD','CHF', 'JPY']
    //console.log('val_array : ', val_array)//[70, 100,  0, 41,50,  56, 64, 80]

    /*if(orderType === 'u'){
        return ([key_array, val_array])
    }
    else{*/

        for(let j = 0; j < val_array.length-1; j++) {

            for(let i = 0; i < val_array.length-1; i++) {

                //console.log( key_array[i], ' : ', val_array[i])

                const current_val = val_array[i]
                const next_val    = val_array[i+1]
                const current_key = key_array[i]
                const next_key    = key_array[i+1]

                if(next_val > current_val) {
                    val_array[i]   = next_val
                    val_array[i+1] = current_val
                    key_array[i]   = next_key
                    key_array[i+1] = current_key
                }

            }

        }

        return ([key_array, val_array])
    //}

    //console.log('val_array : ', val_array)//[70, 100,  0, 41,50,  56, 64, 80]
    //console.log('key_array : ', key_array)//['EUR', 'GBP','AUD', 'NZD','USD', 'CAD','CHF', 'JPY']

    //return ([key_array, val_array])

}


export{
    orderCurrPerf
}