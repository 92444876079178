

import '../CurrTFsHistogram_style/CurrTFsHistogram.css';

import {AUD_img,CAD_img,CHF_img,EUR_img,GBP_img,JPY_img,NZD_img,USD_img} from '../../../Assets/export_Images'


import { useState, useEffect } from 'react' 
import Footer from '../CurrTFsHistogram_child_component/Footer'
import CURRheader from '../CurrTFsHistogram_child_component/CURRheader'
import PlayerHeader from '../CurrTFsHistogram_child_component/PlayerHeader'
import Body from '../CurrTFsHistogram_child_component/Body'

import PlayBackCompo from '../../TFCurrsHistogram/TFCurrsHistogram_child_component/PlayBackCompo'

import { GrClose } from "react-icons/gr";
import { FaPlay } from "react-icons/fa";
import { IoMdPause } from "react-icons/io";
import { IoPlayBackSharp, IoPlayForwardSharp } from "react-icons/io5";

import {get_rgb_color_val_by_perc_val} from '../../../Helpers/get_rgb_color_val_by_perc_val'
import {curr_perf_result_scaled} from '../../../Helpers/curr_perf_result_scaled'
import {randHourCurr} from '../../../Helpers/randHourCurr'


import TimeframeClockCompo from '../CurrTFsHistogram_child_component/TimeframeClockCompo'



  
const CurrTFsHistogram = ({
  /*
  const csmDATAtypeIDstrObj = {//csmDATAtypeIDstrObj, csmDATAtypeIDstr
    'time ago_current'    : 0,
    'candle ago_current'  : 1,
    'candle ago_previous' : 2
  }
  */

  liveUpdateSECcountdown,

  //----------playBACK-----FUNCs-------------------------------
  isPlaybackDATAloadedAndVALID,
  isINDEXatEndsFUNC,
  playBACKuseEffectNULLIFier,
  playBackMSGstr,
  setPlayBackMSGstr,
  playBackINDEXval,
  setPlayBackINDEXval,
  playBackMAXval,
  setPlayBackMAXval,
  isOnPLAYmode, 
  setIsOnPLAYmode,
  isPlayBackBtnClicked,
  setIsPlayBackBtnClicked,
  playbackArrayPRINTtester,
  updatePlayBackLoopMSGstr,
  onCLOSEplaybackDataArrayRESET,
  onPAUSEdataArrayRESET,
  updateCSMchartByPLAYBACKid,
  getPlayBACKdataFromDB,


  reloadALLchartGlobalFUNC,
  forexORcrypto,
  csmDATAtypeIDstrObj, csmDATAtypeIDstr,//access data - csmDATAtypeIDstrObj[csmDATAtypeIDstr = 'time ago_current', 'candle ago_current', 'candle ago_previous'] = [0, 1, 2]  
  setLocalInstruTFuseEffectReRunCounter,
  isChartDATArefreshedAtleastOnce,setIsChartDATArefreshedAtleastOnce,
  isChartHeaderOptionsSwitchedAtleastOnce,setIsChartHeaderOptionsSwitchedAtleastOnce,

  forexTFdataArray, 
  forexTFdataArray2,   
  get_live_forex_data,  
  currentORhistoric,
  setCurrentORhistoric,
  forex_data_index,
  forex_data_length,
  tfArray,
  setTfArray,
  switchOnAndInsertTFbyOrder,

  remove_hist_data,  
  headerName,

  //totalTFcurrPerfSumObj,
  //setTotalTFcurrPerfSumObj,
  //calc_total_TF_perf,
  isDateGetterClicked
}) => {

  const chartNum = 2
  
  const [isViewChartBtnClicked, setIsViewChartBtnClicked] = useState(false)

  const [playBackSTEPval, setPlayBackSTEPval] = useState(1)
  const [isShowPAUSEforwardICON, setIsShowPAUSEforwardICON]   = useState(false)
  const [isShowPAUSEbackwardICON, setIsShowPAUSEbackwardICON] = useState(false)
  const [playBACKuseEffectTimeout, setPlayBACKuseEffectTimeout] = useState(Date.now())
  const [isPlayIndexEQUALbeforePLAYmode, setIsPlayIndexEQUALbeforePLAYmode] = useState(false)
  const [prevPlayEpoch, setPrevPlayEpoch] = useState(Date.now())


  const [curr, setCurr] = useState(
    JSON.parse(window.localStorage.getItem(`selectedCURR` + forexORcrypto)) !== null ? 
    JSON.parse(window.localStorage.getItem(`selectedCURR` + forexORcrypto)) : 
    ["EUR","GBP","AUD","NZD","USD","CAD","CHF","JPY"][randHourCurr[String(new Date().getHours())]]
  )//[new Date().getDay()]

  const [defaultORchart, setDefaultORchart] = useState('default')
  const [defaultORchartBtnClicked, setDefaultORchartBtnClicked] = useState(false)

  const [timeframeClockBtnClicked, setTimeframeClockBtnClicked] = useState(false)

  const [isAllTFsSelected, setIsAllTFsSelected] = useState(false)


  const curr_image_loc = [EUR_img, GBP_img, AUD_img, NZD_img, USD_img, CAD_img, CHF_img, JPY_img]

  /*const [tfArray, setTfArray] = useState(
    ["mn", "w1", "d1", "h12", "h8", "h6", "h4", "h2", "h1", "m45", "m30", "m15", "m5"] 
  )*/


  const [defaultTfArray, setDefaultTfArray] = useState(["m5","m15","m30","h1","h4","d1","w1","mn"])

  const extra_tf = ["m45","h2","h6","h8","h12"]

  /*const switchOnAndInsertTFbyOrder = (tf_to_insert)=>{

    //const all_tf = ["m5","m15","m30", "m45", "h1", "h2", "h4", "h6","h8","h12", "d1","w1","mn"]
    //const tfIndexArray = ["m5_0","m15_1","m30_2","h1_3","h4_4","d1_5","w1_6","mn_7"]
    //const tfIndexArray = ["m5","m15","m30", "m45", "h1", "h2", "h4", "h6","h8","h12", "d1","w1","mn"]
    const tfIndexArray = ["mn", "w1", "d1", "h12", "h8", "h6", "h4", "h2", "h1", "m45", "m30", "m15", "m5"]

    const insertedTFarray = [...tfArray, tf_to_insert]

    const orderedTFarray = tfIndexArray.filter((tf_str, tf_str_index)=>{
      return insertedTFarray.includes(tf_str) === true
    })

    setTfArray(orderedTFarray)

  }*/


  const curr_image_index_array = ["EUR","GBP","AUD","NZD","USD","CAD","CHF","JPY"]


  const curr_name_str_array = ["EUR","GBP","AUD","NZD","USD","CAD","CHF","JPY"]

  const tf_bar_array = ["mn", "w1", "d1", "h12", "h8", "h6", "h4", "h2", "h1", "m45", "m30", "m15", "m5"]




  const [totalTFcurrPerfSumObj, setTotalTFcurrPerfSumObj] = useState({
    'EUR' : 0,
    'GBP' : 0,
    'AUD' : 0,
    'NZD' : 0,
    'USD' : 0,
    'CAD' : 0, 
    'CHF' : 0,
    'JPY' : 0
  })
  

  const calc_total_TF_perf = (forexTFdataArray2, tfArray)=>{

    //-----------------------
    const totalTFcurrPerfSumObj = {'EUR' : 0,'GBP' : 0,'AUD' : 0,'NZD' : 0,'USD' : 0,'CAD' : 0,'CHF' : 0,'JPY' : 0}

    const curr_name_str_array = ['EUR','GBP','AUD','NZD','USD','CAD','CHF','JPY']
    //----------------------

    if(tfArray.length===0){
      return totalTFcurrPerfSumObj
    }
    else if(tfArray.length>0){
      for(let tf of tfArray){
        //console.log('tf : ', tf) 

        let currs_perc_perf_obj;

        //const tf_obj = forexTFdataArray[tf]
        //const tf_obj = JSON.parse(forexTFdataArray2[tf])
        const tf_obj = forexTFdataArray2[tf]
        //console.log(`${tf}_live_data : `, tf_obj)
        //const tf_candle_id_obj = tf_obj[candleId]
        //console.log(`tf_candle_id_obj : `, tf_candle_id_obj)

        //obj key to grab: candle_body_perc_perf_scaled_curr_obj
        currs_perc_perf_obj = tf_obj.curr_perf_scaled


        for(let curr_name_str of curr_name_str_array){

          //console.log(curr_name_str)

          const dataTypeID = csmDATAtypeIDstrObj[csmDATAtypeIDstr]//0, 1, 2
          totalTFcurrPerfSumObj[curr_name_str] += currs_perc_perf_obj[curr_name_str][dataTypeID]//currs_perc_perf_obj[curr_name_str][csmDATAtypeIDstrObj[csmDATAtypeIDstr]]

        }

      }

      //console.log('totalTFcurrPerfSumObj : ', totalTFcurrPerfSumObj)
      
      const zeros_array_checker = []
      for(let obj_name in totalTFcurrPerfSumObj){
        //console.log(obj_name, ' : ', totalTFcurrPerfSumObj[obj_name])
        zeros_array_checker.push(Number(totalTFcurrPerfSumObj[obj_name]))
      }
      //console.log('zeros_array_checker : ', zeros_array_checker)
      
      /*const totalTFcurrPerfSumObj = {
        AUD:251,
        CAD:528,
        CHF:298,
        EUR:395,
        GBP:541,
        JPY:210,
        NZD:253,
        USD:582
      }*/


      //console.log('curr_perf_result_scaled : ', curr_perf_result_scaled(totalTFcurrPerfSumObj))
      
      /*if(tfArray.length > 0){
        setTotalTFcurrPerfSumObj(
          curr_perf_result_scaled(totalTFcurrPerfSumObj)
        )
      }*/
      
      if(zeros_array_checker.every((val)=> val===0)){
        return totalTFcurrPerfSumObj
      }
      else{
        return curr_perf_result_scaled(totalTFcurrPerfSumObj)
      }
    }
  
  }


  const MAX_PHONE_WIDTH = 500


  
  useEffect(() => {

    if(
      isPlayBackBtnClicked &&
      isOnPLAYmode &&
      isPlaybackDATAloadedAndVALID(chartNum) &&
      (isShowPAUSEforwardICON || isShowPAUSEbackwardICON)
    ){

      const timeoutSEC = 1000 * 1.3

      const isPLAYforward  = isShowPAUSEforwardICON
      const isPLAYbackward = isShowPAUSEbackwardICON

      //-------------------------------------------------
      /*console.log({
        isOnPLAYmode, timeoutSEC, isPLAYforward, isPLAYbackward, step:playBackSTEPval, index:playBackINDEXval
      })*/
      //playbackArrayPRINTtester(1)

      //updateCSMchartByPLAYBACKid = (chartNum=1, step=1, isForward=true)
      updateCSMchartByPLAYBACKid(
        playBackINDEXval,
        chartNum,
        playBackSTEPval,
        isPLAYforward,
        isPlayIndexEQUALbeforePLAYmode
      )
      //-------------------------------------------------

      if(isPlayIndexEQUALbeforePLAYmode){
        setIsPlayIndexEQUALbeforePLAYmode(false)
      }

      setTimeout(() => {
        setPlayBACKuseEffectTimeout(Date.now())
      }, timeoutSEC)
      
    }

  }, [playBACKuseEffectTimeout])




  useEffect(() => {

    if(
      isPlayBackBtnClicked &&
      isOnPLAYmode 
    ){
      //--------------------------------
      //Halt all plays to LOAD data from db
      setIsOnPLAYmode(false)
      setIsShowPAUSEforwardICON(false) 
      setIsShowPAUSEbackwardICON(false)
      //-------------------------------*/
    }

  }, [playBACKuseEffectNULLIFier])



  return (
    <div className="csmBodyWidthResizer"
      style={{
        margin:'0 auto',
        /*backgroundColor:'white',
        borderRadius : '15px', 
        width: `${window.innerWidth > 470 ? '90' : ''}%`,
        height:'65vh', 
        margin:'40px auto',
        position:'relative'*/
        //border:'1px solid black',
        backgroundColor:'white',
        borderRadius : '15px', 
        //width: `${window.innerWidth > 470 ? '90%' : '90%'}`,
        /*width: `${
          (currentORhistoric === 'current') ||  (forex_data_length===1)
          ? '100%' : 
          (window.innerWidth > (window.innerHeight + (window.innerWidth*0.3))&& forex_data_length===2) ? '48.5%' :  
          window.innerWidth > MAX_PHONE_WIDTH ? '45vh' : '90%'
        }`,*/
        height:'70vh',
        flexShrink:'0',
        //marginTop:'30px',
        /*marginRight:`${
          forex_data_index===forex_data_length-1 && forex_data_length > 1
          && currentORhistoric === 'historic'
          ? '2vh' : ''}`,
        marginLeft:`${
          forex_data_length > 1 && currentORhistoric === 'historic'
          ? '1vh' : ''}`,*/
        marginBottom:'30px',
        position:'relative',
        boxShadow:`rgba(1, 100, 111, 0.3) 0px 7px 29px 0px`,
      }}
    >





      <div style={{height:'1%'}}></div>
      

      <CURRheader 
        forexORcrypto={forexORcrypto}
        csmDATAtypeIDstr={csmDATAtypeIDstr}
        isChartHeaderOptionsSwitchedAtleastOnce={isChartHeaderOptionsSwitchedAtleastOnce}
        setIsChartHeaderOptionsSwitchedAtleastOnce={setIsChartHeaderOptionsSwitchedAtleastOnce}

        curr={curr}
        setCurr={setCurr}
        curr_image_loc={curr_image_loc}
        curr_name_str_array={curr_name_str_array}
        curr_image_index_array={curr_image_index_array}
        setTimeframeClockBtnClicked={setTimeframeClockBtnClicked}
        get_rgb_color_val_by_perc_val={get_rgb_color_val_by_perc_val}
        tfArray={tfArray}
        forexTFdataArray2={forexTFdataArray2}

        totalTFcurrPerfSumObj={totalTFcurrPerfSumObj}
        setTotalTFcurrPerfSumObj={setTotalTFcurrPerfSumObj}
        calc_total_TF_perf={calc_total_TF_perf}

        forex_data_length={forex_data_length}
        forex_data_index={forex_data_index}

        setCurrentORhistoric={setCurrentORhistoric}

      />



      {
        isPlayBackBtnClicked===false 

        ?

        <PlayerHeader 

          playBackMSGstr={playBackMSGstr}
          setPlayBackMSGstr={setPlayBackMSGstr}
          getPlayBACKdataFromDB={getPlayBACKdataFromDB}
          isPlayBackBtnClicked={isPlayBackBtnClicked}
          setIsPlayBackBtnClicked={setIsPlayBackBtnClicked}

          forexORcrypto={forexORcrypto}
          csmDATAtypeIDstr={csmDATAtypeIDstr}

          setLocalInstruTFuseEffectReRunCounter={setLocalInstruTFuseEffectReRunCounter}
          isChartDATArefreshedAtleastOnce={isChartDATArefreshedAtleastOnce}
          setIsChartDATArefreshedAtleastOnce={setIsChartDATArefreshedAtleastOnce}

          setDefaultORchartBtnClicked={setDefaultORchartBtnClicked}
          timeframeClockBtnClicked={timeframeClockBtnClicked}
          setTimeframeClockBtnClicked={setTimeframeClockBtnClicked}
          switchOnAndInsertTFbyOrder={switchOnAndInsertTFbyOrder}
          defaultTfArray={defaultTfArray}
          tfArray={tfArray}
          extra_tf={extra_tf} 
          setTfArray={setTfArray}
          setIsAllTFsSelected={setIsAllTFsSelected}

          get_live_forex_data={get_live_forex_data}
          forex_data_index={forex_data_index}
          forex_data_length={forex_data_length}

          remove_hist_data={remove_hist_data}
          headerName={headerName}

          currentORhistoric={currentORhistoric}
          setCurrentORhistoric={setCurrentORhistoric}

          forexTFdataArray2={forexTFdataArray2}
          calc_total_TF_perf={calc_total_TF_perf}

          isDateGetterClicked={isDateGetterClicked} 
        />

        :

        <PlayBackCompo
          
          setCurrIconBtnClicked={setTimeframeClockBtnClicked/*setCurrIconBtnClicked*/}//------replace by --setClock-Icon-----------
          setIsViewChartBtnClicked={setIsViewChartBtnClicked}//------only in chart 1--------

          chartNum={chartNum}
          isPlayBackBtnClicked={isPlayBackBtnClicked}
          setIsPlayBackBtnClicked={setIsPlayBackBtnClicked}
          setPlayBackSTEPval={setPlayBackSTEPval}
          setPlayBackINDEXval={setPlayBackINDEXval}
          setIsShowPAUSEbackwardICON={setIsShowPAUSEbackwardICON}
          setIsShowPAUSEforwardICON={setIsShowPAUSEforwardICON}
          setIsOnPLAYmode={setIsOnPLAYmode}
          onCLOSEplaybackDataArrayRESET={onCLOSEplaybackDataArrayRESET}
          playBackMSGstr={playBackMSGstr}
          updateCSMchartByPLAYBACKid={updateCSMchartByPLAYBACKid}
          isPlaybackDATAloadedAndVALID={isPlaybackDATAloadedAndVALID}
          playBackINDEXval={playBackINDEXval}
          playBackMAXval={playBackMAXval}
          playBackSTEPval={playBackSTEPval}
          isShowPAUSEforwardICON={isShowPAUSEforwardICON}
          setPlayBACKuseEffectTimeout={setPlayBACKuseEffectTimeout}
          isShowPAUSEbackwardICON={isShowPAUSEbackwardICON}
          setIsPlayIndexEQUALbeforePLAYmode={setIsPlayIndexEQUALbeforePLAYmode}
          isINDEXatEndsFUNC={isINDEXatEndsFUNC}
          isOnPLAYmode={isOnPLAYmode}
          prevPlayEpoch={prevPlayEpoch}
          setPrevPlayEpoch={setPrevPlayEpoch}
        />
      }


      {
        isPlayBackBtnClicked &&
        <TimeframeClockCompo
        isPlayBackBtnClicked={isPlayBackBtnClicked}
        timeframeClockBtnClicked={timeframeClockBtnClicked}
        setTimeframeClockBtnClicked={setTimeframeClockBtnClicked}
        setIsAllTFsSelected={setIsAllTFsSelected}
        forexORcrypto={forexORcrypto}
        setTfArray={setTfArray}
        tfArray={tfArray}
        csmDATAtypeIDstr={csmDATAtypeIDstr}
        switchOnAndInsertTFbyOrder={switchOnAndInsertTFbyOrder}
        extra_tf={extra_tf}
        defaultTfArray={defaultTfArray}
        isDateGetterClicked={isDateGetterClicked}
        />
      }


      <Body  
        forexORcrypto={forexORcrypto}
        curr={curr}
        tfArray={tfArray}
        tf_bar_array={tf_bar_array}
        forexTFdataArray={forexTFdataArray}
        forexTFdataArray2={forexTFdataArray2}
        get_rgb_color_val_by_perc_val={get_rgb_color_val_by_perc_val}

        csmDATAtypeIDstrObj={csmDATAtypeIDstrObj}
        csmDATAtypeIDstr={csmDATAtypeIDstr}
      />
      
 


      <Footer 
        liveUpdateSECcountdown={liveUpdateSECcountdown}
        reloadALLchartGlobalFUNC={reloadALLchartGlobalFUNC}
        csmDATAtypeIDstrObj={csmDATAtypeIDstrObj}
        csmDATAtypeIDstr={csmDATAtypeIDstr} 
        
        forexORcrypto={forexORcrypto}
        curr={curr}
        forex_data_length={forex_data_length}
        forex_data_index={forex_data_index}
        //datetime={forexTFdataArray2.all_datetime[tf][csmDATAtypeIDstrObj[csmDATAtypeIDstr]]}
        datetime={forexTFdataArray2.str_datetime}//'1970-01-01 00:00:00' = forexTFdataArray2.all_datetime[tf][csmDATAtypeIDstrObj[csmDATAtypeIDstr]]
        day_of_week={forexTFdataArray2.day_of_week}
      />


        
    </div>
    )
}

export default CurrTFsHistogram
