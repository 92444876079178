

import { IoCaretDownSharp, IoCaretUpSharp } from "react-icons/io5";
import {useState} from 'react'


import GMTlist from './GMTlist'




const TimeZoneSetterCompo = ({
    id,
    zero_gmt_deviation,
    datetime,
    reloadALLchartGlobalFUNC
}) => {

    const [isShowGMTlist, setIsShowGMTlist] = useState(false)


    const gmtValListObj = {
        
    
        'gmt+0'  : 0,
    
        'gmt+1'  : 1,
        'gmt+2'  : 2,
        'gmt+3'  : 3,
        'gmt+4'  : 4,
        'gmt+5'  : 5,
        'gmt+6'  : 6,
        'gmt+7'  : 7,
        'gmt+8'  : 8,
        'gmt+9'  : 9,
        'gmt+10' : 10,
        'gmt+11' : 11,
        'gmt+12' : 12,

        'gmt-12' : -12,
        'gmt-11' : -11,
        'gmt-10' : -10,
        'gmt-9'  : -9,
        'gmt-8'  : -8,
        'gmt-7'  : -7,
        'gmt-6'  : -6,
        'gmt-5'  : -5,
        'gmt-4'  : -4,
        'gmt-3'  : -3,
        'gmt-2'  : -2,
        'gmt-1'  : -1,
    }


    const gmtArrayList = [
    
        'gmt+0',
    
        'gmt+1',
        'gmt+2',
        'gmt+3',
        'gmt+4',
        'gmt+5',
        'gmt+6',
        'gmt+7',
        'gmt+8',
        'gmt+9',
        'gmt+10',
        'gmt+11',
        'gmt+12',

        'gmt-12',
        'gmt-11',
        'gmt-10',
        'gmt-9' ,
        'gmt-8' ,
        'gmt-7' ,
        'gmt-6' ,
        'gmt-5' ,
        'gmt-4' ,
        'gmt-3' ,
        'gmt-2' ,
        'gmt-1' ,
    ]




return (
<div id={'gmtId' + id}
style={{
    //border: '1px solid black',
    marginLeft:'5px',
    position:'relative',
    padding:'2px',
    color:'black',

    borderRadius: '15px',
    boxShadow:`${ `rgba(0, 0, 211, 0.5) 0px 7px 29px 0px` }`,
    backgroundColor:'white',
    cursor:'pointer',

    display:'flex',
    alignItems: 'center',
}}

onClick={(()=>{
    setIsShowGMTlist(!isShowGMTlist)

    

    document.getElementById('gmtId' + id).style.backgroundColor = 'white'
    document.getElementById('gmtId2' + id).style.backgroundColor = 'white'
    document.getElementById('gmtId2' + id).style.color = 'black'
    document.getElementById('gmtIconId' + id).style.color = 'black'

    /*const gmt_val = localStorage.getItem('gmt_hour_count')===null ? ('gmt+' + String(zero_gmt_deviation)) : 
        Number(localStorage.getItem('gmt_hour_count')) < 0 ? ('gmt' + String(Number(localStorage.getItem('gmt_hour_count')))) : 
        ('gmt+' + String(Number(localStorage.getItem('gmt_hour_count'))))

    setTimeout(()=>{
        const targetDiv = document.getElementById(gmt_val);

        targetDiv.scrollIntoView({
            behavior: 'smooth' // You can change this to 'auto' for immediate scrolling without smooth animation.
        });
    },500)*/
    
})}

onMouseOver={(e)=>{
    //console.log('onMouseOver')
    /*e.target.style.color = 'white'
    e.target.style.backgroundColor = 'rgba(0,0,255,0.65)'*/
    if(isShowGMTlist===false){
        document.getElementById('gmtId' + id).style.backgroundColor = 'rgba(0,0,255,0.99)'
        document.getElementById('gmtId2' + id).style.backgroundColor = 'rgba(0,0,255,0.99)'
        document.getElementById('gmtId2' + id).style.color = 'white'
        document.getElementById('gmtIconId' + id).style.color = 'white'
    }
}}

onMouseOut={(e)=>{
    //console.log('onMouseOut')
    document.getElementById('gmtId' + id).style.backgroundColor = 'white'
    document.getElementById('gmtId2' + id).style.backgroundColor = 'white'
    document.getElementById('gmtId2' + id).style.color = 'black'
    document.getElementById('gmtIconId' + id).style.color = 'black'

}}

>
    <div id={'gmtId2' + id}
    style={{
        textAlign:'center',
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center',
        marginLeft:`${id==='300' ? '10px' : '2px'}`
        //backgroundColor:'rgba(255,255,255,0.0)'
    }}
    >
        {
        `${'gmt+/-'}`
        /*localStorage.getItem('gmt_hour_count')===null ? ('gmt+' + String(zero_gmt_deviation)) : 
        Number(localStorage.getItem('gmt_hour_count')) < 0 ? ('gmt' + String(Number(localStorage.getItem('gmt_hour_count')))) : 
        ('gmt+' + String(Number(localStorage.getItem('gmt_hour_count'))))*/
        }

        
        
        {isShowGMTlist===false && <IoCaretDownSharp id={'gmtIconId' + id}//rich
        style={{
            marginLeft:'2px',
            //color:'red'
        }}
        />}

        {isShowGMTlist && <IoCaretUpSharp id={'gmtIconId' + id}//rich
        style={{
            marginLeft:'2px',
            //color:'red'
        }}
        />}
    </div>

    {isShowGMTlist && 

    <GMTlist
    id={id}

    gmtArrayList={gmtArrayList}
    gmtValListObj={gmtValListObj}
    zero_gmt_deviation={zero_gmt_deviation}
    datetime={datetime}
    setIsShowGMTlist={setIsShowGMTlist}
    reloadALLchartGlobalFUNC={reloadALLchartGlobalFUNC}
    />
    
    
    }
    
</div>
)
}

export default TimeZoneSetterCompo
