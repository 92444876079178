




const TFheader = ({
    forexORcrypto,
    csmDATAtypeIDstr,
    isChartHeaderOptionsSwitchedAtleastOnce,setIsChartHeaderOptionsSwitchedAtleastOnce,
    setTF,
    tf,
    default_tf,
    extra_tf
}) => {




return (
<div //timeframe header:
style={{}}
>


  <div
    style={{
      //border: `1px solid red`,
      //height: "6%",
      width: "85%",
      margin:'0px auto',
      marginTop: '20px',
      borderRadius : '15px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow:`rgba(1, 100, 111, 0.1) 0px 7px 29px 0px`,
      zIndex: '6',
    }}
  >

    {
      [...default_tf].map((timeframe, index)=>{
        return (
          <div className="tf_select_btn" key={index}
            style={{
              backgroundColor: `${timeframe === tf ? 'rgba(0,0,255,0.65)' : '' }`,
              color: `${timeframe === tf ? 'white' : '' }`,
              fontSize: "12px",
              height: "3vh",
              //border: `1px solid black`,
              width: "3vh",
              margin:'0px auto',
              borderRadius : '15px',
              display: 'flex',
              justifyContent: 'center', 
              alignItems: 'center',
              cursor: 'pointer',
              zIndex: '6',
              boxShadow:`${timeframe === tf ? `rgba(0, 0, 211, 0.9) 0px 7px 29px 0px` : `rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`}`,
            }}

            onClick={()=>{

              //isChartHeaderOptionsSwitchedAtleastOnce,setIsChartHeaderOptionsSwitchedAtleastOnce,
              if(isChartHeaderOptionsSwitchedAtleastOnce===false){
                setIsChartHeaderOptionsSwitchedAtleastOnce(true)
              }

              setTF(timeframe)
              localStorage.setItem('tf' + forexORcrypto, timeframe)
            }}
          >
            {timeframe}
          </div>
        )
      })
    }

  </div>


  {true && <div
    style={{
      //border: `1px solid black`,
      //height: "6%",
      width: "65%",
      margin:'10px auto',
      //marginTop:'5px',
      borderRadius : '15px',
      //marginTop:'20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow:`rgba(1, 100, 111, 0.1) 0px 7px 29px 0px`,
      zIndex: '6'
    }}
  >

    {
      [...extra_tf].map((timeframe, index)=>{
        return (
          <div className="tf_select_btn" key={index} id='tfBTNid'
            style={{
              userSelect:'none',
              backgroundColor: `${csmDATAtypeIDstr.includes('candle') ? 'white' : timeframe === tf ? 'rgba(0,0,255,0.65)' : '' }`,
              color: `${timeframe === tf || (csmDATAtypeIDstr.includes('candle')) ? 'white' : '' }`,
              fontSize: "12px",
              height: "3vh",
              //border: `1px solid black`,
              width: "3vh",
              margin:'0px auto',
              borderRadius : '15px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: `${csmDATAtypeIDstr.includes('candle')===false ? 'pointer' : ''}`,
              zIndex: '6',
              boxShadow:`${timeframe === tf ? `rgba(0, 0, 211, 0.9) 0px 7px 29px 0px` : `rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`}`,
            }}

            onClick={()=>{

              if(csmDATAtypeIDstr.includes('candle')===false){
                //isChartHeaderOptionsSwitchedAtleastOnce,setIsChartHeaderOptionsSwitchedAtleastOnce,
                if(isChartHeaderOptionsSwitchedAtleastOnce===false){
                  setIsChartHeaderOptionsSwitchedAtleastOnce(true)
                }

                setTF(timeframe)
                localStorage.setItem('tf' + forexORcrypto, timeframe)
                //console.log(typeof(timeframe))
              }
            }}

            /*onMouseOver={()=>{

              if(csmDATAtypeIDstr.includes('candle')){
                document.getElementById('tfBTNid').style.backgroundColor = 'rgba(255,255,255,0.0)'
                console.log('mouse over')
              }
              
            }}*/
          >
            {timeframe}
          </div>
        )
      })
    }

  </div>}


</div>
)
}

export default TFheader
