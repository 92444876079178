//NOTE: we had disable App - <React.StrictMode> to prevent useEffect Hook from running twice - in order to prevent the chart from printing twice

import '../ChartSignals_style/ChartSignals.css'

//import {AUD_img,CAD_img,CHF_img,EUR_img,GBP_img,JPY_img,NZD_img,USD_img} from '../../../Assets/export_Images'
import {EURUSD_index_OHLC} from '../../../Helpers/candlesticks_data'

import Chart from '../ChartSignals_child_component/ChartProject'
import Alert from '../ChartSignals_child_component/Alert'
import SelectTimeZoneCompo from '../ChartSignals_child_component/SelectTimeZoneCompo'
import ChartPatterns from '../ChartSignals_child_component/ChartPatterns'
import UpDownChartNavFixedCompo from '../ChartSignals_child_component/UpDownChartNavFixedCompo'
import AllChartSettingsCompo from '../ChartSignals_child_component/AllChartSettingsCompo'
//import Chart from '../ChartSignals_child_component/Chart1'


import React, { useEffect, useRef, useState } from 'react';



const CandlestickChart = ({ 
  
  liveUpdateSECcountdown,
  reloadALLchartGlobalFUNC,
  liveMT4datetimeSTR, liveMT4dayOfWeekNUM,
  pageScrollBarWidthSize,
  globalDataStorageObj, setGlobalDataStorageObj,
  forexORcrypto, setForexORcrypto,
  isShowServerDownAlertCompo, setIsShowServerDownAlertCompo,
  reloadAllChartsUseEffectCounter, setReloadAllChartsUseEffectCounter,
  isDontTouchChart, setIsDontTouchChart,
  isTouchScreenDevice, setIsTouchScreenDevice,
  globalMaxWindowOuterHeight, setGlobalMaxWindowOuterHeight,
  globalMaxWindowOuterWidth, setGlobalMaxWindowOuterWidth,
  tfArray, setTfArray,
  currIndexArray, setCurrIndexArray,
  currPairArray, setCurrPairArray,
  cryptoCurrIndexArray, setCryptoCurrIndexArray,
  cryptoCurrPairArray, setCryptoCurrPairArray,
  defaultPREVInstruTFobjFunc,
  defaultChartInstruTFsettingsObj,
  chartNumArrays,

  //----------------------------------------------------------------------------  
  defaultChartInfoObj, setDefaultChartInfoObj,
  chartInfoObj1,   setChartInfoObj1,
  chartInfoObj2,   setChartInfoObj2,
  chartInfoObj3,   setChartInfoObj3,


  //------------------------------------------------------------------------------
  globalInstruTFuseEffectReRunCounter1, 
  globalInstruTFuseEffectReRunCounter2, 
  globalInstruTFuseEffectReRunCounter3, 

  navToOnlyCHARTnum1instruTFuseEffectReRunCounter,

  isShowChartPatternsOnScreen, setIsShowChartPatternsOnScreen

}) => {

  //const [useEffectReRunCounter, setUseEffectReRunCounter] = useState(0)
  //const [data, setData] = useState({})
  //const [width, setWidth] = useState(window.innerWidth - 50)
  //const [height, setHeight] = useState(window.outererHeight)

  //const [globalDataStorageObj, setGlobalDataStorageObj] = useState({})

  //const [forexORcrypto, setForexORcrypto] = useState('forex')
  const [deviceOnStartWidth, setDeviceOnStartWidth] = useState(window.innerWidth)
  const [zero_gmt_deviation, setZero_gmt_deviation] = useState(3)//const zero_gmt_deviation = 3//mql4 +3

  const [isScrollAllChartsAtSameDISTANCE, setIsScrollAllChartsAtSameDISTANCE] = useState(false)

  const [isShowAllChartSettingsCompo, setIsShowAllChartSettingsCompo] = useState(false)

  const [isScrollAllChartsTogether, setIsScrollAllChartsTogether] = useState(false)
  const [chartScrollTogetherUseEffectReRunCounter, setChartScrollTogetherUseEffectReRunCounter] = useState(Date.now())
  const [allChartsToDEFAULTstructureUseEffectReRunCounter, setAllChartsToDEFAULTstructureUseEffectReRunCounter] = useState(Date.now())

  const [dataShiftCount, setDataShiftCount] = useState(15/*2*2*/)//5
  const [defaultNumOfCandlesToShowOnChart, setDefaultNumOfCandlesToShowOnChart] = useState(//canvasWidth <= 500 ? 18 : (canvasWidth * 18) / 500
    //WARNING: be WARY of this settings as could make all drawings invincible
    //if: we use window.innerWidth in any of its settings
  
    //(globalMaxWindowOuterWidth <= 500 ? 18 : (globalMaxWindowOuterWidth * 18) / 500)//18 //35
    //(deviceOnStartWidth <= 500 ? 18 : (deviceOnStartWidth * 18) / 500)//18 //35
    //18
    //35
    //+ dataShiftCount

    isTouchScreenDevice ? 18 + dataShiftCount : 35 + dataShiftCount
  )//35 = window.width / (pixelSize we want for each candle either on PC or mobile)
  
  //const [isDontTouchChart, setIsDontTouchChart] = useState(false)

  /*const [isTouchScreenDevice, setIsTouchScreenDevice] = useState(
      //onst isTouchScreen = 
      'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
  )*/


  /*const [globalMaxWindowOuterHeight, setGlobalMaxWindowOuterHeight] = useState(
    //500
        //(window.outerHeight*0.85) > 786 ? 786 : (window.outerHeight*0.85)
        ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)===false &&//if not touchscreen
        (window.outerHeight*0.8) > window.outerWidth ? window.outerWidth : (window.outerHeight*0.8)
  )*/

  /*const [globalMaxWindowOuterWidth, setGlobalMaxWindowOuterWidth]   = useState(
    //500
    isTouchScreenDevice ? window.innerWidth : window.innerWidth-pageScrollBarWidthSize// -18 : is to takeout the px occupied by the scrollbar thumb of browser - whenever the page becomes length & scrollable
  )*/

  /*const chartNumArrays = [
    'chart_1', 'chart_2', 'chart_3', 'chart_4', 'chart_5',
    'chart_6', 'chart_7', 'chart_8', 'chart_9', 'chart_10'
  ]*/

  /*const [tfArray, setTfArray] = useState([
    'm1', 'm5', 'm15', 'm30', 'h1', 'h4', 'd1', 'w1', 'mn'
  ])*/

  /*const [currIndexArray, setCurrIndexArray] = useState([
      'EUR', 'GBP', 'AUD', 'NZD', 'USD', 'CAD', 'CHF', 'JPY'
  ])*/

  /*const [currPairArray, setCurrPairArray] = useState([
      "EURGBP", "EURAUD", "EURNZD", "EURUSD", "EURCAD", "EURCHF", "EURJPY", 
      "GBPAUD", "GBPNZD", "GBPUSD", "GBPCAD", "GBPCHF", "GBPJPY", 
      "AUDNZD", "AUDUSD", "AUDCAD", "AUDCHF", "AUDJPY", 
      "NZDUSD", "NZDCAD", "NZDCHF", "NZDJPY", 
      "USDCAD", "USDCHF", "USDJPY", 
      "CADCHF", "CADJPY", 
      "CHFJPY" 
  ])*/


  /*const [cryptoCurrIndexArray, setCryptoCurrIndexArray] = useState([
    'FDUSD', 'USDC', 'TUSD', 'BNB', 'USDT', 'BTC', 'ETH', 'TRY'
  ])*/

  /*const [cryptoCurrPairArray, setCryptoCurrPairArray] = useState([
    'FDUSDUSDC', 'FDUSDTUSD', 'BNBFDUSD', 'FDUSDUSDT', 'BTCFDUSD', 'ETHFDUSD', 'FDUSDTRY',
    'USDCTUSD', 'BNBUSDC', 'USDCUSDT', 'BTCUSDC', 'ETHUSDC', 'USDCTRY', 'BNBTUSD',
    'TUSDUSDT', 'BTCTUSD', 'ETHTUSD', 'TUSDTRY', 'BNBUSDT', 'BNBBTC',
    'BNBETH', 'BNBTRY', 'BTCUSDT', 'ETHUSDT',
    'USDTTRY', 'BTCETH', 'BTCTRY', 'ETHTRY', 
  ])*/


  /*const defaultPREVInstruTFobjFunc = (array, defaultTF='h1')=>{
    const obj = {}

    array.forEach((el, elInd)=>{
      obj[el] = defaultTF
    })

    return(obj)

  }*/


  //all chart infos states separated to avoid re-running whole other charts when editing a giving one 
  //but all chartInfoObjs are put inside a single Obj for the storage in LStorage 
  //where: most LStorage done for a given chart_num, at ( onMouseUp, onTouchEnd, ) & after specific chart_num ( settings & other btn clicks)
  //allChartInfos & specified chart_num settings + settings of instru_tf
  /*const [defaultChartInfoObj,   setDefaultChartInfoObj]   = useState({

    candleORrsi : 'candle',

    indexORpair  : 'pair',//'index'

    instruName   : 'EURUSD',
    
    tf           : 'h1',
    //lineTF           : 'h4',
    isdrawingToolOn : true,
    isLinedrawingToolOn : true,
    isLineCorrWindowOn : true,

    prevCandlePairInstruAndTF  : {instruName:'EURUSD', tf:'h1'},
    prevCandleIndexInstruAndTF : {instruName:'EUR',    tf:'h1'},

    prevInstruTFbeforeLineChart : {instruName:'EURUSD', tf:'h1'},

    //lineCount : 10,

    prevRsiIndexTF             : {tf:'h1'},
    prevStochIndexTF           : {tf:'h1'},

    //prevTFlineCount: {m1:100, m5:100, m15:100, m30:100, h1:100, h4:100, d1:100, w1:100, mn:100},

    //to avoid opening diff tf than the last visited tf of a given pair or index
    //get func to generate default pair & index prevInstruTFobj set to a given default TF
    pairPrevTF  : defaultPREVInstruTFobjFunc(currPairArray),// {'EURUSD' : 'h1', 'AUDCAD' : 'h1'},
    indexPrevTF : defaultPREVInstruTFobjFunc(currIndexArray)//{'EUR' : 'h1', 'CAD' : 'h1'}
  })*/


  //chart1 will take care of all the navs from the main page header
  //Purposely put into this global scope, in order to edit which chart type/form to show when main page header nav clicked
  /*const [chartInfoObj1,   setChartInfoObj1]   = useState({

    candleORrsi : 'candle',

    indexORpair  : 'pair',//'index'

    instruName   : 'EURUSD',
    
    tf           : 'h1',
    //lineTF           : 'h4',
    isdrawingToolOn : true,
    isLinedrawingToolOn : true,
    isLineCorrWindowOn : true,

    prevCandlePairInstruAndTF  : {instruName:'EURUSD', tf:'h1'},
    prevCandleIndexInstruAndTF : {instruName:'EUR',    tf:'h1'},

    prevInstruTFbeforeLineChart : {instruName:'EURUSD', tf:'h1'},

    //lineCount : 10,

    prevRsiIndexTF             : {tf:'h1'},
    prevStochIndexTF           : {tf:'h1'},

    //prevTFlineCount: {m1:100, m5:100, m15:100, m30:100, h1:100, h4:100, d1:100, w1:100, mn:100},

    //to avoid opening diff tf than the last visited tf of a given pair or index
    //get func to generate default pair & index prevInstruTFobj set to a given default TF
    pairPrevTF  : defaultPREVInstruTFobjFunc(currPairArray),// {'EURUSD' : 'h1', 'AUDCAD' : 'h1'},
    indexPrevTF : defaultPREVInstruTFobjFunc(currIndexArray)//{'EUR' : 'h1', 'CAD' : 'h1'}
  })*/

  /*const [chartInfoObj2,   setChartInfoObj2]   = useState({

    candleORrsi : 'candle',

    indexORpair  : 'index',//'index'

    instruName   : 'USD',
    
    tf           : 'h1',
    //lineTF           : 'h4',
    isdrawingToolOn : true,
    isLinedrawingToolOn : true,
    isLineCorrWindowOn : true,

    prevCandlePairInstruAndTF  : {instruName:'EURUSD', tf:'h1'},
    prevCandleIndexInstruAndTF : {instruName:'USD',    tf:'h1'},

    prevInstruTFbeforeLineChart : {instruName:'EURUSD', tf:'h1'},

    //lineCount : 10,

    prevRsiIndexTF             : {tf:'h1'},
    prevStochIndexTF           : {tf:'h1'},

    //prevTFlineCount: {m1:100, m5:100, m15:100, m30:100, h1:100, h4:100, d1:100, w1:100, mn:100},

    //to avoid opening diff tf than the last visited tf of a given pair or index
    //get func to generate default pair & index prevInstruTFobj set to a given default TF
    pairPrevTF  : defaultPREVInstruTFobjFunc(currPairArray),// {'EURUSD' : 'h1', 'AUDCAD' : 'h1'},
    indexPrevTF : defaultPREVInstruTFobjFunc(currIndexArray)//{'EUR' : 'h1', 'CAD' : 'h1'}
  })
  const [chartInfoObj3,   setChartInfoObj3]   = useState({})*/



  const [scrollAllChartsTogetherDefaultSettingsObj, setScrollAllChartsTogetherDefaultSettingsObj] = useState({
    //have a separate useEffect in all charts that sets the ff into the chart states of that chart
    //, eg: setIsYmagnificationActivated(allChartEqualScrollInfo.isYmagnificationActivated)
    //onMouseMove of current chart update the (setAllChartEqualScrollInfo) state & the global reRunUseEffect state
    //after the global reRunUseEffect state finish setting the chart states, RUN the chart reRun useEffect at its end

    isYmagnificationActivated: false,

    dataCandleStartEndIndex: {dataCandleStartIndex: 0, dataCandleEndIndex: 0},

    beforeYmagChartInfoTracker: {dataCandleStartIndex: 0, dataCandleEndIndex: 0},

    XmagnificationPercRatio: 0,
    YmagnificationPercRatio: 0,
    chartXvelocity: 0,
    chartYvelocity: 0,

    beforeYmagCandleIndexInfoObj: {},
    
    //candleWidthSize : globalMaxWindowOuterWidth / (defaultCandlesticksNumOnChart.dataCandleEndIndex - defaultCandlesticksNumOnChart.dataCandleStartIndex),

    isStateLoaded : false,

    timer : Date.now()
  })



  const [scrollAllChartsTogetherInfoTracker, setScrollAllChartsTogetherInfoTracker] = useState(
    scrollAllChartsTogetherDefaultSettingsObj
  )

 

  /*const defaultChartInstruTFsettingsObj = {
    XmagnificationPercRatio: 0,
    YmagnificationPercRatio: 0,
    beforeYmagCandleIndexInfoObj: {},
    bodyORwickPercPerfValToShow: "Body",
    candlePercStrengthMinMaxRangeVal: {min: 70, max: 100},
    chartXvelocity: 0,
    chartYvelocity: 0,
    dataCandleStartEndIndex: {dataCandleStartIndex: 0, dataCandleEndIndex: 0},//defaultCandlesticksNumOnChart,
    beforeYmagChartInfoTracker: {dataCandleStartIndex: 0, dataCandleEndIndex: 0},
    dayOfWeekHighlightVal: "Wednesday",
    isActivateDailyVerticalLineMarkUp: false,
    isHighlightMidWeekReversalCandles: false,
    isHighlightTimeOfDay: false,
    isHighlightTradingSessions: false,
    isShowCandlePercPerfRange: false,
    isYmagnificationActivated: false,
    openTimeIntervalVal: "Daily",
    timeOfDayHighlightVal: "08:00",
    tradingSessionVal: "All",
    //candleWidthSize : globalMaxWindowOuterWidth / (defaultCandlesticksNumOnChart.dataCandleEndIndex - defaultCandlesticksNumOnChart.dataCandleStartIndex)
  }*/





  //all chart infos states separated to avoid re-running whole other charts when editing a giving one 
  //but all chartInfoObjs are put inside a single Obj for the storage in LStorage - {'chart_1':{}, 'chart_2':{},}
  //where: most LStorage done for a given chart_num, at ( onMouseUp, onTouchEnd, ) & after specific chart_num ( settings & other btn clicks)
  //which chart_num to show or minimize
  const [isShowChart1,   setIsShowChart1]   = useState(true)
  const [isShowChart2,   setIsShowChart2]   = useState(true)
  const [isShowChart3,   setIsShowChart3]   = useState(true)


  const showAllDrawingTools = ()=>{
    setChartInfoObj1({...chartInfoObj1, isdrawingToolOn:true})
    setChartInfoObj2({...chartInfoObj2, isdrawingToolOn:true})
    setChartInfoObj3({...chartInfoObj3, isdrawingToolOn:true})
  }
  
  const hideAllDrawingTools = ()=>{
    setChartInfoObj1({...chartInfoObj1, isdrawingToolOn:false})
    setChartInfoObj2({...chartInfoObj2, isdrawingToolOn:false})
    setChartInfoObj3({...chartInfoObj3, isdrawingToolOn:false})
  }

  const isAllDrawingToolsShown = ()=>{
    return(
      chartInfoObj1.isdrawingToolOn &&
      chartInfoObj2.isdrawingToolOn &&
      chartInfoObj3.isdrawingToolOn 
    )
  }


  //const [allChartsInfoObj, setChartInfoObj10] = useState({})

  const [isShowChart, setIsShowChart] = useState(true)
  const [isShowDisableBtnOfUntoucheableCharts, setIsShowDisableBtnOfUntoucheableCharts] = useState(true)
  const [isShowChartUpDownNavigatorBtn, setIsShowChartUpDownNavigatorBtn] = useState(false)
  const [isShowOpenedChartInstruOrderList, setIsShowOpenedChartInstruOrderList] = useState(false)
  const [arrowOntoAdId, setArrowOntoAdId] = useState(1)
  const [isEditChartOrderList, setIsEditChartOrderList] = useState(false)
  const [isAnyChartOrderListEdited, setIsAnyChartOrderListEdited] = useState(false)
  const [chartOrderListToEditNum, setChartOrderListToEditNum] = useState(0)
  const [lastLockedChartIdTracker, setLastLockedChartIdTracker] = useState(-1)

 




  /*useEffect(() => {

    setData(EURUSD_index_OHLC)
    //console.log('EURUSD_index_OHLC : ', typeof EURUSD_index_OHLC.total_datetime_str)
    //console.log(EURUSD_index_OHLC.total_datetime_str.split("="))

  }, [useEffectReRunCounter]);*/


  useEffect(() => {//useEffect runs once onPageLoad

    //essential to listen to a specific element event by id
    //eg: document.getElementById('lockButton').addEventListener
    //console.log({defaultPREVInstruTFobjFunc:defaultPREVInstruTFobjFunc(currPairArray)})

    const bol = window.localStorage.getItem(`isChartUpDownNavigate` + forexORcrypto)  
    const isDataExist3 = bol !== null

    if(isDataExist3){
      //console.log('hey2')
      const data = JSON.parse(bol)
      setIsShowChartUpDownNavigatorBtn(data)
    }



    //---------------------------------------------------------------------------------------------
    window.localStorage.setItem("replayDatetimeLineLockedObj" + forexORcrypto, JSON.stringify({}))  
    //---------------------------------------------------------------------------------------------


    //---------------------------------------------------------------------------------------------
    window.addEventListener('resize', function() {
      // Adjust canvas size or position here
      setGlobalMaxWindowOuterWidth(
          //500
          isTouchScreenDevice ? window.innerWidth : window.innerWidth-pageScrollBarWidthSize// -18 : is to takeout the px occupied by the scrollbar thumb of browser - whenever the page becomes length & scrollable
      )

      setReloadAllChartsUseEffectCounter(Date.now())


      //console.log('am window resized')
      /*console.log({
          innerWidth:window.innerWidth,
          outerWidth:window.outerWidth,
      })*/

      

      //document.getElementById(`canvasCompoId-${1}`).style.width = window.innerWidth
    });
    //---------------------------------------------------------------------------------------------


    //---------------------------------------------------------------------------------------------
    window.addEventListener('scroll', function() {

      //console.log('pageScroll : ', window.scrollY)

      //1. 
      //to show or hide page header menu by rate of scroll Up/Down


      //2.
      //Unlock all chart from top of page
      if(isTouchScreenDevice){
        /*setUnlockChart(
          
        )*/
      }


      //unlock any chart before any navigations
      /*if(lastLockedChartIdTracker >= 0){
        document.getElementById(`unlockButton-${lastLockedChartIdTracker}`).click()
      }*/


      //3.
      //track pageScroll position compared to the position of a Compo 
      //to know which chart we are currently scrolling over
      //page position of a given Compo is only essential for updating data loaded at onInit of first useEffect of a give Compo, but not for Loading our 1st data from db
      //console.log('pageScroll : ', window.scrollY)
      /*
      //how to know the exact Y page position of an html element using the id of that element
      //Load the code below for all the Charts #ids 
      //inside & when the chart switch arrow btn to switch charts up/down is clicked
      
      const element = document.getElementById('myElement');

      // Get the bounding rectangle of the element
      const rect = element.getBoundingClientRect();

      // The top property of rect is the element's top edge distance from the viewport top
      // To get the distance from the top of the document, add window.scrollY (vertical scroll offset)
      const topPosition = rect.top + window.scrollY;

      console.log(topPosition);

      This topPosition variable now holds the vertical distance from the top of the document to the top edge of the element with the specified ID.

      */
    });
    //---------------------------------------------------------------------------------------------
  }, [])







  return(//NOTE: we had disable App - <React.StrictMode> to prevent useEffect Hook from running twice - in order to prevent the chart from printing twice
    <div
    style={{
      position:'relative'
      //border:'3px solid black',
      //width: '600px',
      //height: '450px',
      //margin: '0 auto',
      //border: '1px solid #000',
    }}
    >


      {
        false &&
        <UpDownChartNavFixedCompo

          setIsShowAllChartSettingsCompo={setIsShowAllChartSettingsCompo}
          isShowAllChartSettingsCompo={isShowAllChartSettingsCompo}

          globalMaxWindowOuterHeight={globalMaxWindowOuterHeight}
          isShowChartUpDownNavigatorBtn={isShowChartUpDownNavigatorBtn}
          setIsShowChartUpDownNavigatorBtn={setIsShowChartUpDownNavigatorBtn}

          lastLockedChartIdTracker={lastLockedChartIdTracker}
          setLastLockedChartIdTracker={setLastLockedChartIdTracker}
          arrowOntoAdId={arrowOntoAdId}
          setArrowOntoAdId={setArrowOntoAdId}
          setIsEditChartOrderList={setIsEditChartOrderList}
          setIsAnyChartOrderListEdited={setIsAnyChartOrderListEdited}
          setIsShowOpenedChartInstruOrderList={setIsShowOpenedChartInstruOrderList}
          isShowOpenedChartInstruOrderList={isShowOpenedChartInstruOrderList}

          isAnyChartOrderListEdited={isAnyChartOrderListEdited}
          setChartOrderListToEditNum={setChartOrderListToEditNum}
          isEditChartOrderList={isEditChartOrderList}
          chartOrderListToEditNum={chartOrderListToEditNum}

          forexORcrypto={forexORcrypto}

          isShowChartPatternsOnScreen={isShowChartPatternsOnScreen}
          setIsShowChartPatternsOnScreen={setIsShowChartPatternsOnScreen}
        />
      }


      {isShowAllChartSettingsCompo &&
        <AllChartSettingsCompo
        setIsShowAllChartSettingsCompo={setIsShowAllChartSettingsCompo}
        isShowAllChartSettingsCompo={isShowAllChartSettingsCompo}

        //isScrollAllChartsAtSameDISTANCE={isScrollAllChartsAtSameDISTANCE}
        //setIsScrollAllChartsAtSameDISTANCE={setIsScrollAllChartsAtSameDISTANCE}

        isScrollAllChartsTogether={isScrollAllChartsTogether}
        setIsScrollAllChartsTogether={setIsScrollAllChartsTogether}
    
        canvasWidth={globalMaxWindowOuterWidth}

        scrollAllChartsTogetherInfoTracker={scrollAllChartsTogetherInfoTracker}
        setScrollAllChartsTogetherInfoTracker={setScrollAllChartsTogetherInfoTracker}
        scrollAllChartsTogetherDefaultSettingsObj={scrollAllChartsTogetherDefaultSettingsObj}

        setChartScrollTogetherUseEffectReRunCounter={setChartScrollTogetherUseEffectReRunCounter}
        setAllChartsToDEFAULTstructureUseEffectReRunCounter={setAllChartsToDEFAULTstructureUseEffectReRunCounter}
        />
      }



      {isShowChartPatternsOnScreen &&
        <ChartPatterns
         isShowChartPatternsOnScreen={isShowChartPatternsOnScreen}
         setIsShowChartPatternsOnScreen={setIsShowChartPatternsOnScreen}
        />
      }








      {
        (chartInfoObj1.isLoaded)//isLoaded: true - is to make chart loading wait until chartObj settings is fully checked & loaded
        
        &&

        <Chart 

          //-----------------------------------------------------------------
          //unique Compo keys
          canvasCompoId={1}
          chartInRowName= {chartNumArrays[1-1]}//id='chart_1', 'chart_2' 
          isShowChart={isShowChart1}
          setIsShowChart={setIsShowChart1}
          chartInfoObj={chartInfoObj1}
          setChartInfoObj={setChartInfoObj1}
          globalInstruTFuseEffectReRunCounter={globalInstruTFuseEffectReRunCounter1}
          navToOnlyCHARTnum1instruTFuseEffectReRunCounter={navToOnlyCHARTnum1instruTFuseEffectReRunCounter}
          //-----------------------------------------------------------------

          //-----------------------------------------------------------------
          //Same keys shared with All Compos

          
          liveUpdateSECcountdown={liveUpdateSECcountdown}

          zero_gmt_deviation={zero_gmt_deviation}
          
          isScrollAllChartsTogether={isScrollAllChartsTogether}
          setIsScrollAllChartsTogether={setIsScrollAllChartsTogether}
          
          liveMT4datetimeSTR={liveMT4datetimeSTR}

          isShowServerDownAlertCompo={isShowServerDownAlertCompo}
          setIsShowServerDownAlertCompo={setIsShowServerDownAlertCompo}

          chartScrollTogetherUseEffectReRunCounter={chartScrollTogetherUseEffectReRunCounter}
          setChartScrollTogetherUseEffectReRunCounter={setChartScrollTogetherUseEffectReRunCounter}
          allChartsToDEFAULTstructureUseEffectReRunCounter={allChartsToDEFAULTstructureUseEffectReRunCounter}

          scrollAllChartsTogetherInfoTracker={scrollAllChartsTogetherInfoTracker}
          setScrollAllChartsTogetherInfoTracker={setScrollAllChartsTogetherInfoTracker}

          chartNumArrays={chartNumArrays}
          isDontTouchChart={isDontTouchChart}
          setIsDontTouchChart={setIsDontTouchChart}
          isShowDisableBtnOfUntoucheableCharts={isShowDisableBtnOfUntoucheableCharts}
          setIsShowDisableBtnOfUntoucheableCharts={setIsShowDisableBtnOfUntoucheableCharts}

          reloadAllChartsUseEffectCounter={reloadAllChartsUseEffectCounter}
          setReloadAllChartsUseEffectCounter={setReloadAllChartsUseEffectCounter}

          setLastLockedChartIdTracker={setLastLockedChartIdTracker}
          canvasHeight={globalMaxWindowOuterHeight}
          canvasWidth={globalMaxWindowOuterWidth}

          showAllDrawingTools={showAllDrawingTools}
          hideAllDrawingTools={hideAllDrawingTools}
          isAllDrawingToolsShown={isAllDrawingToolsShown}

          forexORcrypto={forexORcrypto}

          tfArray={tfArray}
          currIndexArray={currIndexArray}
          currPairArray={currPairArray}


          defaultChartInstruTFsettingsObj={defaultChartInstruTFsettingsObj}

          dataShiftCount={dataShiftCount}
          setDataShiftCount={setDataShiftCount}
          defaultNumOfCandlesToShowOnChart={defaultNumOfCandlesToShowOnChart}
          setDefaultNumOfCandlesToShowOnChart={setDefaultNumOfCandlesToShowOnChart}

          globalDataStorageObj   ={globalDataStorageObj}
          setGlobalDataStorageObj={setGlobalDataStorageObj}
          //-----------------------------------------------------------------------
        />
      }





      {//make 3 charts - 2currIndex & 1pair
        (chartInfoObj2.isLoaded)//isLoaded: true - is to make chart loading wait until chartObj settings is fully checked & loaded 
        
        &&

        <Chart 

          //-----------------------------------------------------------------
          //unique Compo keys
          canvasCompoId={2}
          chartInRowName= {chartNumArrays[2-1]}//id='chart_1', 'chart_2' 
          isShowChart={isShowChart2}
          setIsShowChart={setIsShowChart2}
          chartInfoObj={chartInfoObj2}
          setChartInfoObj={setChartInfoObj2}
          globalInstruTFuseEffectReRunCounter={globalInstruTFuseEffectReRunCounter2}
          navToOnlyCHARTnum1instruTFuseEffectReRunCounter={2}//static val=2 so that its useEffect wont reRun - but only chart1 will reRun its fetch data useEffect - to have only chart#1 been NAVed to from a Header click
          //-----------------------------------------------------------------


          //-----------------------------------------------------------------
          //Same keys shared with All Compos

          
          liveUpdateSECcountdown={liveUpdateSECcountdown}

          zero_gmt_deviation={zero_gmt_deviation}

          isScrollAllChartsTogether={isScrollAllChartsTogether}
          setIsScrollAllChartsTogether={setIsScrollAllChartsTogether}

          liveMT4datetimeSTR={liveMT4datetimeSTR}

          isShowServerDownAlertCompo={isShowServerDownAlertCompo}
          setIsShowServerDownAlertCompo={setIsShowServerDownAlertCompo}

          chartScrollTogetherUseEffectReRunCounter={chartScrollTogetherUseEffectReRunCounter}
          setChartScrollTogetherUseEffectReRunCounter={setChartScrollTogetherUseEffectReRunCounter}
          allChartsToDEFAULTstructureUseEffectReRunCounter={allChartsToDEFAULTstructureUseEffectReRunCounter}

          scrollAllChartsTogetherInfoTracker={scrollAllChartsTogetherInfoTracker}
          setScrollAllChartsTogetherInfoTracker={setScrollAllChartsTogetherInfoTracker}

          chartNumArrays={chartNumArrays}
          isDontTouchChart={isDontTouchChart}
          setIsDontTouchChart={setIsDontTouchChart}
          isShowDisableBtnOfUntoucheableCharts={isShowDisableBtnOfUntoucheableCharts}
          setIsShowDisableBtnOfUntoucheableCharts={setIsShowDisableBtnOfUntoucheableCharts}

          reloadAllChartsUseEffectCounter={reloadAllChartsUseEffectCounter}
          setReloadAllChartsUseEffectCounter={setReloadAllChartsUseEffectCounter}

          setLastLockedChartIdTracker={setLastLockedChartIdTracker}
          canvasHeight={globalMaxWindowOuterHeight}
          canvasWidth={globalMaxWindowOuterWidth}

          //globalMaxWindowOuterWidth={globalMaxWindowOuterWidth}

          showAllDrawingTools={showAllDrawingTools}
          hideAllDrawingTools={hideAllDrawingTools}
          isAllDrawingToolsShown={isAllDrawingToolsShown}

          forexORcrypto={forexORcrypto}

          tfArray={tfArray}
          currIndexArray={currIndexArray}
          currPairArray={currPairArray}


          defaultChartInstruTFsettingsObj={defaultChartInstruTFsettingsObj}

          dataShiftCount={dataShiftCount}
          setDataShiftCount={setDataShiftCount}
          defaultNumOfCandlesToShowOnChart={defaultNumOfCandlesToShowOnChart}
          setDefaultNumOfCandlesToShowOnChart={setDefaultNumOfCandlesToShowOnChart}

          globalDataStorageObj   ={globalDataStorageObj}
          setGlobalDataStorageObj={setGlobalDataStorageObj}
          //------------------------------------------------------------------------
        />
      }



      

      {//make 3 charts - 2currIndex & 1pair
        (chartInfoObj3.isLoaded)//isLoaded: true - is to make chart loading wait until chartObj settings is fully checked & loaded 
        
        &&

        <Chart 

          //-----------------------------------------------------------------
          //unique Compo keys
          canvasCompoId={3}
          chartInRowName= {chartNumArrays[3-1]}//id='chart_1', 'chart_2' 
          isShowChart={isShowChart3}
          setIsShowChart={setIsShowChart3}
          chartInfoObj={chartInfoObj3}
          setChartInfoObj={setChartInfoObj3}
          globalInstruTFuseEffectReRunCounter={globalInstruTFuseEffectReRunCounter3}
          navToOnlyCHARTnum1instruTFuseEffectReRunCounter={3}//static val=2 so that its useEffect wont reRun - but only chart1 will reRun its fetch data useEffect - to have only chart#1 been NAVed to from a Header click
          //-----------------------------------------------------------------


          //-----------------------------------------------------------------
          //Same keys shared with All Compos
          
          liveUpdateSECcountdown={liveUpdateSECcountdown}

          zero_gmt_deviation={zero_gmt_deviation}

          isScrollAllChartsTogether={isScrollAllChartsTogether}
          setIsScrollAllChartsTogether={setIsScrollAllChartsTogether}

          liveMT4datetimeSTR={liveMT4datetimeSTR}

          isShowServerDownAlertCompo={isShowServerDownAlertCompo}
          setIsShowServerDownAlertCompo={setIsShowServerDownAlertCompo}

          chartScrollTogetherUseEffectReRunCounter={chartScrollTogetherUseEffectReRunCounter}
          setChartScrollTogetherUseEffectReRunCounter={setChartScrollTogetherUseEffectReRunCounter}
          allChartsToDEFAULTstructureUseEffectReRunCounter={allChartsToDEFAULTstructureUseEffectReRunCounter}

          scrollAllChartsTogetherInfoTracker={scrollAllChartsTogetherInfoTracker}
          setScrollAllChartsTogetherInfoTracker={setScrollAllChartsTogetherInfoTracker}

          chartNumArrays={chartNumArrays}
          isDontTouchChart={isDontTouchChart}
          setIsDontTouchChart={setIsDontTouchChart}
          isShowDisableBtnOfUntoucheableCharts={isShowDisableBtnOfUntoucheableCharts}
          setIsShowDisableBtnOfUntoucheableCharts={setIsShowDisableBtnOfUntoucheableCharts}

          reloadAllChartsUseEffectCounter={reloadAllChartsUseEffectCounter}
          setReloadAllChartsUseEffectCounter={setReloadAllChartsUseEffectCounter}

          setLastLockedChartIdTracker={setLastLockedChartIdTracker}
          canvasHeight={globalMaxWindowOuterHeight}
          canvasWidth={globalMaxWindowOuterWidth}

          //globalMaxWindowOuterWidth={globalMaxWindowOuterWidth}

          showAllDrawingTools={showAllDrawingTools}
          hideAllDrawingTools={hideAllDrawingTools}
          isAllDrawingToolsShown={isAllDrawingToolsShown}

          forexORcrypto={forexORcrypto}

          tfArray={tfArray}
          currIndexArray={currIndexArray}
          currPairArray={currPairArray}


          defaultChartInstruTFsettingsObj={defaultChartInstruTFsettingsObj}

          dataShiftCount={dataShiftCount}
          setDataShiftCount={setDataShiftCount}
          defaultNumOfCandlesToShowOnChart={defaultNumOfCandlesToShowOnChart}
          setDefaultNumOfCandlesToShowOnChart={setDefaultNumOfCandlesToShowOnChart}

          globalDataStorageObj   ={globalDataStorageObj}
          setGlobalDataStorageObj={setGlobalDataStorageObj}
          //------------------------------------------------------------------------
        />
      }





      {
        <SelectTimeZoneCompo
        reloadALLchartGlobalFUNC={reloadALLchartGlobalFUNC}
        liveMT4datetimeSTR={liveMT4datetimeSTR}
        day_of_week={liveMT4dayOfWeekNUM}
        zero_gmt_deviation={zero_gmt_deviation}
        />
      }




      {//Alerts Compo1
        false &&
        <Alert
          alertID={1}
          alertHeight={globalMaxWindowOuterHeight*0.9}
          alertWidth={globalMaxWindowOuterWidth}

          isDontTouchChart={isDontTouchChart}
          setIsDontTouchChart={setIsDontTouchChart}
        />

      }




      

      {false &&
        <div
        style={{
          height:'20vh',
          border:'1px solid black'
          //backgroundColor:'pink'
        }}
        >
          Watch how to use our systems & charts better
          <button>watch</button>

          youtube title : how to use currency strength meter, indexes and charts Profitably

          
        </div>
      }



      {false &&
        <div
        style={{
          height:'20vh',
          border:'1px solid black'
          //backgroundColor:'pink'
        }}
        >
          Join Our Telegram to see more use case results of our system
          <button>Join Telegram</button>

          use Telegram as 1st social tool to collate followers , where you show 3 images of 
          either : 
          1. curr_index + curr_index = curr_pair - or,

          2. curr_pair = curr_index + curr_index

          & alert infos & time it happened

          & later offer 24/7 Alerts to phone & pc service

          then through the Telegram i advertise & share all services & links or socials
        </div>
      }

      


      {false &&
        <div
        style={{
          height:'20vh',
          border:'1px solid black'
          //backgroundColor:'pink'
        }}
        >
          Usage:

          2 ways to use it :

          1. create your own strategies using our systems, 

          2. OR: apply the following recommendable use case strategy of our system

              a. curr_index on demand 

              b. curr_pair at supply

              c. confirm if their pair has any reversal confirmation

              d. curr_pair trade enrtry at - open/closed reversal candle in the current tf of the pattern formation or breakout trade entry in the LTF topDown Analysis (m1/m15, m5/h1, m15/h4, ...) 


          - Join Our Telegram to see more use case results of our system <button>Join Telegram</button>

        </div>
      }

      {false &&
        <div
        style={{
          height:'20vh',
          border:'1px solid black',
          overflowX:'scroll'
        }}
        >
          Wants to get <button>24/7 Live Phone & PC Alerts</button>  based on your own customizable Alert settings ? :
          if you are profitable using our Alerts or 
          wants to have live Alerts in order to have the best out of our system or 
          dont want to miss various Alerts & Likes, 
          Contact Us to negotiate at a charge/fee/cost : to have your custom Alert settings
          sent to your phone or pc 24/7  - (alerts.csz.com)
          <button>Contact Sales - sales@csz.com</button>
          or still enjoy all our free Services as long as you could stay watching your phone or pc or if you
          are busy like some of Us Contact Us to negotiate
          - only when we are being contacted by email (given link to deposit first, after deposit given alerts.csz.com link & a token 
          to enter & signup with details: to have access to the alert custom dashboard to customize & push to db & the alert table as well,
          last step is get us your telegram bot details to send Alerts,)

          - this signup or access steps should be done in stepwise without revealing the next step until the current step is done, eg:
          contact us by email, pay to address, get a token & a link to alerts.csz.com to signup to customize, edit & push into db,
          get guided to get us telegram bot details to send Alerts

          - in the alerts.csz.com signup page space : be alerting the users on the countdown to renew subscriptions
          where we add more access time to the day of expiry
        </div>
      }


      


    </div>
  );
};

export default CandlestickChart;

