

const randHourCurr = {

    '0' : 0,
    '1' : 1,
    '2' : 2,
    '3' : 3,
    '4' : 4,
    '5' : 5,
    '6' : 6,
    '7' : 7,
    '8' : 0,
    '9' : 1,
    '10' : 2,
    '11' : 3,
    '12' : 4,
    '13' : 5,
    '14' : 6,
    '15' : 7,
    '16' : 0,
    '17' : 1,
    '18' : 2,
    '19' : 3,
    '20' : 4,
    '21' : 5,
    '22' : 6,
    '23' : 7,

  }



  export {
    randHourCurr
  }