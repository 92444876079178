

const default_forex_db_data_obj_ = {

    d1: "{\"curr_perf_scaled\":{\"EUR\":23,\"GBP\":9,\"AUD\":0,\"NZD\":0,\"USD\":21,\"CAD\":23,\"CHF\":34,\"JPY\":100}}",
    day_of_week: 2,
    h1: "{\"curr_perf_scaled\":{\"EUR\":29,\"GBP\":100,\"AUD\":7,\"NZD\":21,\"USD\":44,\"CAD\":28,\"CHF\":0,\"JPY\":27}}",
    h2: "{\"curr_perf_scaled\":{\"EUR\":75,\"GBP\":79,\"AUD\":0,\"NZD\":9,\"USD\":87,\"CAD\":100,\"CHF\":27,\"JPY\":36}}",
    h4: "{\"curr_perf_scaled\":{\"EUR\":45,\"GBP\":31,\"AUD\":19,\"NZD\":36,\"USD\":0,\"CAD\":37,\"CHF\":24,\"JPY\":100}}",
    h6: "{\"curr_perf_scaled\":{\"EUR\":28,\"GBP\":31,\"AUD\":36,\"NZD\":52,\"USD\":0,\"CAD\":55,\"CHF\":26,\"JPY\":100}}",
    h8: "{\"curr_perf_scaled\":{\"EUR\":11,\"GBP\":10,\"AUD\":0,\"NZD\":9,\"USD\":8,\"CAD\":11,\"CHF\":14,\"JPY\":100}}",
    h12: "{\"curr_perf_scaled\":{\"EUR\":15,\"GBP\":14,\"AUD\":0,\"NZD\":4,\"USD\":9,\"CAD\":15,\"CHF\":13,\"JPY\":100}}",
    id: 1,
    m5: "{\"curr_perf_scaled\":{\"EUR\":80,\"GBP\":14,\"AUD\":0,\"NZD\":12,\"USD\":100,\"CAD\":43,\"CHF\":45,\"JPY\":29}}",
    m15: "{\"curr_perf_scaled\":{\"EUR\":43,\"GBP\":91,\"AUD\":0,\"NZD\":21,\"USD\":92,\"CAD\":52,\"CHF\":41,\"JPY\":100}}",
    m30: "{\"curr_perf_scaled\":{\"EUR\":13,\"GBP\":100,\"AUD\":0,\"NZD\":43,\"USD\":57,\"CAD\":52,\"CHF\":20,\"JPY\":23}}",
    m45: "{\"curr_perf_scaled\":{\"EUR\":34,\"GBP\":100,\"AUD\":0,\"NZD\":27,\"USD\":37,\"CAD\":27,\"CHF\":43,\"JPY\":52}}",
    mn: "{\"curr_perf_scaled\":{\"EUR\":62,\"GBP\":51,\"AUD\":30,\"NZD\":63,\"USD\":18,\"CAD\":0,\"CHF\":56,\"JPY\":100}}",
    sql_datetime: "2022-12-20T12:59:53.000Z",
    str_datetime: "2022-12-20 13:59:53",
    trading_hour: 13,
    trading_minute: 59,
    w1: "{\"curr_perf_scaled\":{\"EUR\":42,\"GBP\":9,\"AUD\":0,\"NZD\":13,\"USD\":26,\"CAD\":26,\"CHF\":45,\"JPY\":100}}"

}




const default_forex_db_data_obj1 = {

    d1: "{\"curr_perf_scaled\":{\"EUR\":0,\"GBP\":0,\"AUD\":0,\"NZD\":0,\"USD\":0,\"CAD\":0,\"CHF\":0,\"JPY\":0}}",
    day_of_week: 2,
    h1: "{\"curr_perf_scaled\":{\"EUR\":0,\"GBP\":0,\"AUD\":0,\"NZD\":0,\"USD\":0,\"CAD\":0,\"CHF\":0,\"JPY\":0}}",
    h2: "{\"curr_perf_scaled\":{\"EUR\":0,\"GBP\":0,\"AUD\":0,\"NZD\":0,\"USD\":0,\"CAD\":0,\"CHF\":0,\"JPY\":0}}",
    h4: "{\"curr_perf_scaled\":{\"EUR\":0,\"GBP\":0,\"AUD\":0,\"NZD\":0,\"USD\":0,\"CAD\":0,\"CHF\":0,\"JPY\":0}}",
    h6: "{\"curr_perf_scaled\":{\"EUR\":0,\"GBP\":0,\"AUD\":0,\"NZD\":0,\"USD\":0,\"CAD\":0,\"CHF\":0,\"JPY\":0}}",
    h8: "{\"curr_perf_scaled\":{\"EUR\":0,\"GBP\":0,\"AUD\":0,\"NZD\":0,\"USD\":0,\"CAD\":0,\"CHF\":0,\"JPY\":0}}",
    h12: "{\"curr_perf_scaled\":{\"EUR\":0,\"GBP\":0,\"AUD\":0,\"NZD\":0,\"USD\":0,\"CAD\":0,\"CHF\":0,\"JPY\":0}}",
    id: 1,
    m5: "{\"curr_perf_scaled\":{\"EUR\":0,\"GBP\":0,\"AUD\":0,\"NZD\":0,\"USD\":0,\"CAD\":0,\"CHF\":0,\"JPY\":0}}",
    m15: "{\"curr_perf_scaled\":{\"EUR\":0,\"GBP\":0,\"AUD\":0,\"NZD\":0,\"USD\":0,\"CAD\":0,\"CHF\":0,\"JPY\":0}}",
    m30: "{\"curr_perf_scaled\":{\"EUR\":0,\"GBP\":0,\"AUD\":0,\"NZD\":0,\"USD\":0,\"CAD\":0,\"CHF\":0,\"JPY\":0}}",
    m45: "{\"curr_perf_scaled\":{\"EUR\":0,\"GBP\":0,\"AUD\":0,\"NZD\":0,\"USD\":0,\"CAD\":0,\"CHF\":0,\"JPY\":0}}",
    mn: "{\"curr_perf_scaled\":{\"EUR\":0,\"GBP\":0,\"AUD\":0,\"NZD\":0,\"USD\":0,\"CAD\":0,\"CHF\":0,\"JPY\":0}}",
    sql_datetime: "1970-01-01T13:59:53.000Z",
    str_datetime: "1970-01-01 00:00:00",
    trading_hour: 0,
    trading_minute: 0,
    w1: "{\"curr_perf_scaled\":{\"EUR\":0,\"GBP\":0,\"AUD\":0,\"NZD\":0,\"USD\":0,\"CAD\":0,\"CHF\":0,\"JPY\":0}}"

}



const default_forex_db_data_obj = {

    d1: {curr_perf_scaled:{EUR:[0,0,0],GBP:[0,0,0],AUD:[0,0,0],NZD:[0,0,0],USD:[0,0,0],CAD:[0,0,0],CHF:[0,0,0],JPY:[0,0,0]}},
    day_of_week: 2,
    h1: {curr_perf_scaled:{EUR:[0,0,0],GBP:[0,0,0],AUD:[0,0,0],NZD:[0,0,0],USD:[0,0,0],CAD:[0,0,0],CHF:[0,0,0],JPY:[0,0,0]}},
    h2: {curr_perf_scaled:{EUR:[0,0,0],GBP:[0,0,0],AUD:[0,0,0],NZD:[0,0,0],USD:[0,0,0],CAD:[0,0,0],CHF:[0,0,0],JPY:[0,0,0]}},
    h4: {curr_perf_scaled:{EUR:[0,0,0],GBP:[0,0,0],AUD:[0,0,0],NZD:[0,0,0],USD:[0,0,0],CAD:[0,0,0],CHF:[0,0,0],JPY:[0,0,0]}},
    h6: {curr_perf_scaled:{EUR:[0,0,0],GBP:[0,0,0],AUD:[0,0,0],NZD:[0,0,0],USD:[0,0,0],CAD:[0,0,0],CHF:[0,0,0],JPY:[0,0,0]}},
    h8: {curr_perf_scaled:{EUR:[0,0,0],GBP:[0,0,0],AUD:[0,0,0],NZD:[0,0,0],USD:[0,0,0],CAD:[0,0,0],CHF:[0,0,0],JPY:[0,0,0]}},
    h12: {curr_perf_scaled:{EUR:[0,0,0],GBP:[0,0,0],AUD:[0,0,0],NZD:[0,0,0],USD:[0,0,0],CAD:[0,0,0],CHF:[0,0,0],JPY:[0,0,0]}},
    id: 1,
    m5: {curr_perf_scaled:{EUR:[0,0,0],GBP:[0,0,0],AUD:[0,0,0],NZD:[0,0,0],USD:[0,0,0],CAD:[0,0,0],CHF:[0,0,0],JPY:[0,0,0]}},
    m15: {curr_perf_scaled:{EUR:[0,0,0],GBP:[0,0,0],AUD:[0,0,0],NZD:[0,0,0],USD:[0,0,0],CAD:[0,0,0],CHF:[0,0,0],JPY:[0,0,0]}},
    m30: {curr_perf_scaled:{EUR:[0,0,0],GBP:[0,0,0],AUD:[0,0,0],NZD:[0,0,0],USD:[0,0,0],CAD:[0,0,0],CHF:[0,0,0],JPY:[0,0,0]}},
    m45: {curr_perf_scaled:{EUR:[0,0,0],GBP:[0,0,0],AUD:[0,0,0],NZD:[0,0,0],USD:[0,0,0],CAD:[0,0,0],CHF:[0,0,0],JPY:[0,0,0]}},
    mn: {curr_perf_scaled:{EUR:[0,0,0],GBP:[0,0,0],AUD:[0,0,0],NZD:[0,0,0],USD:[0,0,0],CAD:[0,0,0],CHF:[0,0,0],JPY:[0,0,0]}},
    sql_datetime: "1970-01-01T13:59:53.000Z",
    str_datetime: "1970-01-01 00:00:00",
    trading_hour: 0,
    trading_minute: 0,
    w1: {curr_perf_scaled:{EUR:[0,0,0],GBP:[0,0,0],AUD:[0,0,0],NZD:[0,0,0],USD:[0,0,0],CAD:[0,0,0],CHF:[0,0,0],JPY:[0,0,0]}},
    all_datetime: {//[time_ago, candle_ago_current, candle_ago_previous]
        d1: ["1970-01-01 00:00:00","1970-01-01 00:00:00","1970-01-01 00:00:00"],
        h1: ["1970-01-01 00:00:00","1970-01-01 00:00:00","1970-01-01 00:00:00"],
        h2: ["1970-01-01 00:00:00","1970-01-01 00:00:00","1970-01-01 00:00:00"],
        h4: ["1970-01-01 00:00:00","1970-01-01 00:00:00","1970-01-01 00:00:00"],
        h6: ["1970-01-01 00:00:00","1970-01-01 00:00:00","1970-01-01 00:00:00"],
        h8: ["1970-01-01 00:00:00","1970-01-01 00:00:00","1970-01-01 00:00:00"],
        h12: ["1970-01-01 00:00:00","1970-01-01 00:00:00","1970-01-01 00:00:00"],
        m5: ["1970-01-01 00:00:00","1970-01-01 00:00:00","1970-01-01 00:00:00"],
        m15: ["1970-01-01 00:00:00","1970-01-01 00:00:00","1970-01-01 00:00:00"],
        m30: ["1970-01-01 00:00:00","1970-01-01 00:00:00","1970-01-01 00:00:00"],
        m45: ["1970-01-01 00:00:00","1970-01-01 00:00:00","1970-01-01 00:00:00"],
        mn: ["1970-01-01 00:00:00","1970-01-01 00:00:00","1970-01-01 00:00:00"],
        w1: ["1970-01-01 00:00:00","1970-01-01 00:00:00","1970-01-01 00:00:00"],
    }
}



/*
function get_default_forex_db_data(){
    return default_forex_db_data_obj
}*/


export {
    //get_default_forex_db_data
    default_forex_db_data_obj
}

