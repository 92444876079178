
//import data from './forex_json_data.json'
//const data = require('./forex_json_data.json');
const data = require('../forex_json_data.json');

//console.log('hello world')

//console.log("data : ", data[0])



//console.log("get_live_forex_data(data) : ", get_live_forex_data(data) )

//console.log("get_hist_forex_data(data) : ", get_live_forex_data(data) )


function get_live_forex_data(/*data*/){

    //----------------------------------------------------------------------------
    //GET: live_forex_data_objs into array:
    const live_tf_obj = {//"m5","m15","m30","h1","h4","d1","w1","mn"
        "m5" :[],
        "m15":[],
        "m30":[],
        "h1" :[],
        "h4" :[],
        "d1" :[],
        "w1" :[],
        "mn" :[]
    }//tf_splits : []

    const live_forex_data_db_index = 122 - 1

    const live_forex_data_obj = data[live_forex_data_db_index]

    for(const key in live_forex_data_obj) {

        //console.log(key)

        //if(key === "h4_hist_forex_data_obj"){
        if( key.includes("live") ){
            const tf_forex_data_obj = JSON.parse(live_forex_data_obj[key])
            //console.log(tf_forex_data_obj, " : ", tf_forex_data_obj)

            //add to hist_tf_obj : {tf_datetime_str, tf_hist_forex_data_obj}
            const tf_str = key.split("_")[0]
            //console.log("tf_str : ", tf_str)

            live_tf_obj[tf_str].push(tf_forex_data_obj)
            
            ///break
        }

    }

    //console.log("live_tf_obj : ", live_tf_obj)

    return live_tf_obj;
    //---------------------------------------------------------------------------

}




function get_hist_forex_data(/*data*/){
    //----------------------------------------------------------------------------
    //GET: hist_forex_data_objs into array:
    const hist_tf_obj = {//"m5","m15","m30","h1","h4","d1"
        "m5" :[],
        "m15":[],
        "m30":[],
        "h1" :[],
        "h4" :[],
        "d1" :[]
    }//tf_splits : []


    for(let i = 0; i < 121; i++){//122, for live_data

        const hist_forex_data_obj = data[i]

        for(const key in hist_forex_data_obj) {

            //console.log(key)

            //if(key === "h4_hist_forex_data_obj"){
            if( key.includes("hist") ){
                const tf_forex_data_obj = JSON.parse(hist_forex_data_obj[key])
                //console.log(tf_forex_data_obj, " : ", tf_forex_data_obj)

                //add to hist_tf_obj : {tf_datetime_str, tf_hist_forex_data_obj}
                const tf_str = key.split("_")[0]
                //console.log("tf_str : ", tf_str)

                hist_tf_obj[tf_str].push(tf_forex_data_obj)
                
                ///break
            }

            
        }

        ///break

    }

    //console.log("hist_tf_obj : ", hist_tf_obj)

    return hist_tf_obj;
    //------------------------------------------------------------------------------*/
}



export {
    get_live_forex_data,
    get_hist_forex_data
}

/*
live_tf_obj :  {
  m5: [],
  m15: [],
  m30: [],
  h1: [],
  h4: [],
  d1: [],
  w1: [],
  mn: [
    {
      EUR_GBP: [Object],
      EUR_AUD: [Object],
      EUR_NZD: [Object],
      EUR_USD: [Object],
      EUR_CAD: [Object],
      EUR_CHF: [Object],
      EUR_JPY: [Object],
      GBP_AUD: [Object],
      GBP_NZD: [Object],
      GBP_USD: [Object],
      GBP_CAD: [Object],
      GBP_CHF: [Object],
      GBP_JPY: [Object],
      AUD_NZD: [Object],
      AUD_USD: [Object],
      AUD_CAD: [Object],
      AUD_CHF: [Object],
      AUD_JPY: [Object],
      NZD_USD: [Object],
      NZD_CAD: [Object],
      NZD_CHF: [Object],
      NZD_JPY: [Object],
      USD_CAD: [Object],
      USD_CHF: [Object],
      USD_JPY: [Object],
      CAD_CHF: [Object],
      CAD_JPY: [Object],
      CHF_JPY: [Object],
      candle_body_perc_perf_curr_obj: [Object],
      candle_wick_perc_perf_curr_obj: [Object],
      candle_body_pip_moved_curr_obj: [Object],
      candle_wick_pip_moved_curr_obj: [Object],
      candle_body_perc_perf_scaled_curr_obj: [Object],
      candle_wick_perc_perf_scaled_curr_obj: [Object],
      candle_body_pip_moved_scaled_curr_obj: [Object],
      candle_wick_pip_moved_scaled_curr_obj: [Object]
    }
  ]
}


hist_tf_obj :  {
  m5: [],
  m15: [],
  m30: [],
  h1: [],
  h4: [],
  d1: [
    {
      EUR_GBP: [Object],
      EUR_AUD: [Object],
      EUR_NZD: [Object],
      EUR_USD: [Object],
      EUR_CAD: [Object],
      EUR_CHF: [Object],
      EUR_JPY: [Object],
      GBP_AUD: [Object],
      GBP_NZD: [Object],
      GBP_USD: [Object],
      GBP_CAD: [Object],
      GBP_CHF: [Object],
      GBP_JPY: [Object],
      AUD_NZD: [Object],
      AUD_USD: [Object],
      AUD_CAD: [Object],
      AUD_CHF: [Object],
      AUD_JPY: [Object],
      NZD_USD: [Object],
      NZD_CAD: [Object],
      NZD_CHF: [Object],
      NZD_JPY: [Object],
      USD_CAD: [Object],
      USD_CHF: [Object],
      USD_JPY: [Object],
      CAD_CHF: [Object],
      CAD_JPY: [Object],
      CHF_JPY: [Object],
      candle_body_perc_perf_curr_obj: [Object],
      candle_wick_perc_perf_curr_obj: [Object],
      candle_body_pip_moved_curr_obj: [Object],
      candle_wick_pip_moved_curr_obj: [Object],
      candle_body_perc_perf_scaled_curr_obj: [Object],
      candle_wick_perc_perf_scaled_curr_obj: [Object],
      candle_body_pip_moved_scaled_curr_obj: [Object],
      candle_wick_pip_moved_scaled_curr_obj: [Object]
    }
  ]
}
*/
