
import {TfiAlarmClock} from 'react-icons/tfi'
import {MdOutlineKeyboardArrowDown} from 'react-icons/md'
import {VscRefresh, VscClose} from 'react-icons/vsc'
import {HiCurrencyDollar} from 'react-icons/hi'
import {TfiControlBackward, TfiControlForward, TfiControlPlay, TfiClose} from 'react-icons/tfi'
import {FiFastForward, FiPlay, FiPause} from 'react-icons/fi'
import { FaPlay } from "react-icons/fa";
import { IoPlayBackSharp } from "react-icons/io5";


import VisibleCurrCompo from '../TFCurrsHistogram_child_component/VisibleCurrCompo'


import {useState} from 'react'

 
const PlayerHeader = ({
    playBackMSGstr,
    setPlayBackMSGstr,
    getPlayBACKdataFromDB,
    setIsViewChartBtnClicked,
    isPlayBackBtnClicked,
    setIsPlayBackBtnClicked,
    
    forexORcrypto,
    setLocalInstruTFuseEffectReRunCounter,
    isChartDATArefreshedAtleastOnce,setIsChartDATArefreshedAtleastOnce,
    setDefaultORchartBtnClicked,
    isPlayBtnSet,
    get_live_forex_data,
    forex_data_length,
    forex_data_index,
    remove_hist_data,
    setCurrentORhistoric,
    setIsAllCURRsSelected,
    setCurrArray,
    currArray,
    switchOnAndInsertCURRbyOrder,
    extra_curr,
    defaultCurrArray,
    headerName,
    
    isDateGetterClicked
}) => {


    const [currIconBtnClicked, setCurrIconBtnClicked] = useState(false)



return (
<div //className='HistogramBarClass'
    style={{
    backgroundColor:`rgba(255,255,255,0.0)`,
    width: `90%`,
    //height: '6.6%',
    //border:'1px solid black',
    margin:'0px auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: '3'
    }}

    onClick={()=>{
    setDefaultORchartBtnClicked(false)
    }}
>

    
    

    {
      <VisibleCurrCompo
        isPlayBackBtnClicked={isPlayBackBtnClicked}
        currIconBtnClicked={currIconBtnClicked}
        setCurrIconBtnClicked={setCurrIconBtnClicked}
        forex_data_index={forex_data_index}
        setCurrArray={setCurrArray}
        currArray={currArray}
        setIsAllCURRsSelected={setIsAllCURRsSelected}
        isDateGetterClicked={isDateGetterClicked}
        defaultCurrArray={defaultCurrArray}
        switchOnAndInsertCURRbyOrder={switchOnAndInsertCURRbyOrder}
        extra_curr={extra_curr}
        forexORcrypto={forexORcrypto}
      />
    }


    {false &&
    <div
    
    style={{
        //backgroundColor: 'blue',
        //border: '1px solid black',
        borderRadius: '15px',
        height:'2.2vh',
        padding: '7px',
        width: `${isPlayBtnSet=== true ? '80%' : '100%'}`,
        marginLeft:'7%',
        display:'flex', //justifyContent:'center', 
        alignItems:'center',
        position:'relative',
        //boxShadow:`${isPlayBtnSet=== true ? 'rgba(1,100,111,0.1) 0px 7px 29px 0px' : ''}`,
        zIndex: '5'
    }}
    >

        {
          <div style={{width:'100%'}}></div>
        }

        {forex_data_index===forex_data_length-1 && <div
        style={{
        //border: '1px solid black',
        //height: '100%',
        //width: '100%',
        //marginLeft:'-10%',
        display:'flex', 
        //justifyContent:`${ 'end'}`, 
        alignItems:'center',
        }}
        >

            <VscRefresh 
            style={{
                width:'2vh',
                height:'2vh',
                transform: 'rotate(90deg)',
                backgroundColor: 'white',
                borderRadius: '50%',
                padding: '0.5vh',
                boxShadow:`${ `rgba(1, 100, 111, 0.5) 0px 7px 29px 0px` }`,
                color:'rgba(128,128,128,1)',
                position:'relative',
                zIndex: '5',
                cursor: 'pointer'
            }}

            onClick={()=>{

              if(window.navigator.onLine===false && window.location.hostname !== 'localhost') {
                //console.log("Oops! You're offline. Please check your network connection..."); 
                setTimeout(()=>{

                    if(document.getElementById('isOnLineId')){
                      document.getElementById('isOnLineId').style.display = ''
                    }
    
                    setTimeout(()=>{
                      if(document.getElementById('isOnLineId')){
                        document.getElementById('isOnLineId').style.display = 'none'
                      }
                    }, 1500)
                }, 100)
              } 
              else {

                  if(document.getElementById('isLoadingId')){
                    document.getElementById('isLoadingId').style.display = ''
                  }

                  //isChartDATArefreshedAtleastOnce,setIsChartDATArefreshedAtleastOnce
                  if(isChartDATArefreshedAtleastOnce===false){
                    setIsChartDATArefreshedAtleastOnce(true)
                  }

                  //get_live_forex_data()//(headerName)
                  setLocalInstruTFuseEffectReRunCounter(Date.now())
              }
                /*///console.log('hello world')
                const chartContainer = document.getElementById('chartContainerId')
                ///console.log('chartContainer : ', chartContainer)
                const chatContainerFullScrollSize = (chartParentBarWidth * (hist_forex_data.length + 1))
                chartContainer.scrollLeft = chatContainerFullScrollSize;*/
            }}
            />

            <div
            style={{
                fontSize:'0.7em',
                color: 'gray',
                position: 'absolute',
                bottom:`-1.2em`,
                right:'0.15em'
            }}
            >
                Refresh
            </div>



        </div>}




        {forex_data_index!==forex_data_length-1 && <div
        style={{
        //border: '1px solid black',
        //height: '100%',
        width: '100%',
        //marginLeft:'-10%',
        display:'flex', 
        justifyContent:`${ 'end'}`, 
        alignItems:'center',
        }}
        >

            <VscClose 
            style={{
                width:'2vh',
                height:'2vh',
                //transform: 'rotate(55deg)',
                backgroundColor: 'white',
                borderRadius: '50%',
                padding: '0.5vh',
                boxShadow:`${ `rgba(1, 100, 111, 0.5) 0px 7px 29px 0px` }`,
                color:'rgba(128,128,128,1',
                position:'relative',
                zIndex: '5',
                cursor: 'pointer'
            }}

            onClick={()=>{
                ///console.log('remove hist data')
                if(forex_data_length===2){
                    setCurrentORhistoric('current')
                }
                remove_hist_data(forex_data_index, headerName)

                document.getElementById('tf_currs_id').scrollLeft += 50 
                setTimeout(()=>{
                    document.getElementById('tf_currs_id').scrollLeft -= 50 
                },500)
            }}
            />

            {true && <div
            style={{
                fontSize:'0.7em',
                color: 'gray',
                position: 'absolute',
                bottom:`-1.2em`,
                right:'0.5em'
            }}
            >
                {'Close'}
            </div>}



        </div>}


        

    </div>
    }


    <div
    style={{
      display:'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor:'pointer',
      whiteSpace: 'nowrap',
      boxShadow:`${/*currIconBtnClicked === true ? `rgba(0, 0, 211, 0.9) 0px 7px 29px 0px` : */`rgba(0, 0, 211, 0.4) 0px 7px 29px 0px`}`,
      position: 'relative',
      zIndex: '11',
      borderRadius: '15px',
      height:'3vh',
      paddingLeft: '15px', paddingRight: '15px',
      marginRight:'0%'
    }}

    onClick={()=>{
      setIsPlayBackBtnClicked(!isPlayBackBtnClicked)
      setIsViewChartBtnClicked(false)

      setPlayBackMSGstr('Loading...')

      setTimeout(()=>{
        const chartNum = 1
        getPlayBACKdataFromDB(chartNum)
      }, 1)

      setCurrIconBtnClicked(false)
    }}
    >
      <IoPlayBackSharp
        style={{
          color:'rgba(0,0,255,0.5)',
          transform:'scale(1.5)'
        }}
      />
      <span style={{
        fontSize:'0.7em',
        color: 'gray',
        marginLeft:'11px'
      }}>play-back</span>
    </div>

</div>
)
}

export default PlayerHeader
