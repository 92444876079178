

import {MdOutlineKeyboardArrowDown} from 'react-icons/md'
import {HiCurrencyDollar} from 'react-icons/hi'
import {useState} from 'react'




const VisibleCurrCompo = ({
    isPlayBackBtnClicked,
    currIconBtnClicked, 
    setCurrIconBtnClicked,
    forex_data_index,
    setCurrArray, currArray,
    setIsAllCURRsSelected,
    isDateGetterClicked,
    defaultCurrArray,
    switchOnAndInsertCURRbyOrder,
    extra_curr,
    forexORcrypto,
}) => {




return (
<div
style={{
    border: `${currIconBtnClicked === true ? '' : ''}`,
    boxShadow:`${currIconBtnClicked === true ? `rgba(0, 0, 211, 0.9) 0px 7px 29px 0px` : `rgba(0, 0, 211, 0.6) 0px 7px 29px 0px`}`,
    /*boxShadow:`rgba(128, 128, 128, 1.0) 0px 7px 29px 0px`,
    backgroundColor:'rgba(255, 255, 255, 0.0)',*/
    //width:'3vh',
    height:'3vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop:'0vh',
    borderRadius: '15px',
    backgroundColor:`${currIconBtnClicked === true ? 'rgba(0,0,255,0.65)' : '' }`,
    position: isPlayBackBtnClicked ? 'absolute' : 'relative',
    top : isPlayBackBtnClicked ? '26%' : '',
    right : (isPlayBackBtnClicked && currIconBtnClicked) ? '45%' : isPlayBackBtnClicked ? '5.7%' : '',
    zIndex: '11',
    marginLeft: isPlayBackBtnClicked ? '' : '45%',
    //right:'5.7%',
}}
>

    <HiCurrencyDollar style={{
    width:'2.5vh',
    height:'2.5vh',
    padding: '0.2vh',
    color:`${currIconBtnClicked === true ? 'white' : 'rgba(0,0,0,0.7)' }`,
    cursor:'pointer',
    }}
    
    onClick={()=>{
    setCurrIconBtnClicked(!currIconBtnClicked)
    }}
    />

    <MdOutlineKeyboardArrowDown style={{
    width:'2.5vh',
    height:'2.5vh',
    padding: '0.2vh',
    transform:`${currIconBtnClicked === true ? `rotate(180deg)` : '' }`,
    color:`${currIconBtnClicked === true ? 'white' : 'rgba(0,0,0,0.7)' }`,
    cursor:'pointer',
    }}
    
    onClick={()=>{
    setCurrIconBtnClicked(!currIconBtnClicked)
    }}
    />


    {
    forex_data_index === forex_data_index &&
    currIconBtnClicked === true && 
    isDateGetterClicked === false
    
    &&

    <div
        style={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor:'white',
        position:'absolute',
        width:'490%',
        borderBottomLeftRadius:'7px', 
        borderBottomRightRadius:'7px',
        height:'1300%',
        borderTopRightRadius:`${true ? '7px' : ''}` ,
        bottom:`-1390%` ,
        left:'-200%',
        //border:'1px solid black',
        //cursor:'pointer',
        }}
    >

        <div>

        {
        defaultCurrArray.map((curr_name, curr_name_index)=>{
            return (
            <div key={curr_name_index}
                style={{
                //border: '1px solid black', 
                height:'6vh',
                display:'flex', justifyContent:'center', alignItems:'center',
                borderBottomLeftRadius:'7px', 
                borderBottomRightRadius:'7px',
                borderTopRightRadius:`${curr_name_index===0 ? '7px' : ''}` ,
                boxShadow:`rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`,
                position: 'relative'
                }}
            >

                <div>
                {curr_name}
                </div>


                <div
                style={{
                color: 'white',
                backgroundColor:`${currArray.includes(curr_name) ? 'rgba(0,0,255,0.7)' : 'gray'}`,
                width: '7vh',
                height: '2.5vh',
                borderRadius: '15px',
                marginLeft:`${curr_name !== 'm15' && curr_name !== 'm30' ? '2.3vh' : '1vh'}` ,
                cursor:'pointer',

                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                }}

                onClick={()=>{
                //console.log('heollo world')
                if(currArray.includes(curr_name)){
                    setCurrArray( 
                    currArray.filter((tf_str, tf_str_index)=>{
                        return tf_str !== curr_name
                    })
                    )

                    /*localStorage.setItem( 'curr_array' + forexORcrypto,
                    JSON.stringify(
                        currArray.filter((tf_str, tf_str_index)=>{
                        return tf_str !== curr_name
                        })
                    )
                    )*/
                }
                else{
                    switchOnAndInsertCURRbyOrder(curr_name)

                }
                }}
                >

                {
                currArray.includes(curr_name) === true
                && 
                <div
                style={{
                //border:'1px solid black'
                fontSize:'0.8em'
                }}
                >
                ON
                </div>}
                
                <div
                style={{
                backgroundColor:'white',
                borderRadius: '50%',
                width: '2.3vh',
                height: '2.3vh',
                }}
                >

                </div>

                {
                currArray.includes(curr_name) === false
                && 
                <div
                style={{
                fontSize:'0.8em'
                }}
                >
                OFF
                </div>
                }

                </div>


                
                
            </div>
            )
        })
        }
        </div>



        <div>

        {
        [...extra_curr].map((curr_name, curr_name_index)=>{
            return (
            <div key={curr_name_index}
                style={{
                //border: '1px solid black', 
                height:'6vh',
                width: '90%',
                marginLeft: '10%',
                display:'flex', justifyContent:'center', alignItems:'center',
                borderBottomLeftRadius:'7px', 
                borderBottomRightRadius:'7px',
                borderTopRightRadius:`${curr_name_index===0 ? '7px' : ''}` ,
                boxShadow:`rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`,
                position: 'relative'
                }}
            >

                <div>
                {curr_name}
                </div>


                <div
                style={{
                color: 'white',
                backgroundColor:`${currArray.includes(curr_name) ? 'rgba(0,0,255,0.7)' : 'gray'}`,
                width: '8vh',
                height: '2.5vh',
                borderRadius: '15px',
                marginLeft:`${curr_name !== 'm15' && curr_name !== 'm30' && curr_name !== 'h12' && curr_name !== 'm45' && curr_name !== 'ALL' ? '2.3vh' : '1vh'}` ,
                cursor:'pointer',

                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                }}

                onClick={()=>{
                //console.log('heollo world')
                if(currArray.includes(curr_name)){
                    setCurrArray(
                    currArray.filter((tf_str, tf_str_index)=>{
                        return tf_str !== curr_name
                    })
                    )

                    /*localStorage.setItem( 'curr_array' + forexORcrypto,
                    JSON.stringify(
                        currArray.filter((tf_str, tf_str_index)=>{
                        return tf_str !== curr_name
                        })
                    )
                    )*/
                    setIsAllCURRsSelected(false)
                }
                else{
                    switchOnAndInsertCURRbyOrder(curr_name)
                    if(currArray.length===13){
                    setIsAllCURRsSelected(true)
                    }
                    
                }
                }}
                >

                {
                currArray.includes(curr_name) === true
                && 
                <div
                style={{
                //border:'1px solid black'
                fontSize:'0.8em'
                }}
                >
                ON
                </div>}
                
                <div
                style={{
                backgroundColor:'white',
                borderRadius: '50%',
                width: '2.3vh',
                height: '2.3vh',
                }}
                >

                </div>

                {
                currArray.includes(curr_name) === false
                && 
                <div
                style={{
                fontSize:'0.8em'
                }}
                >
                OFF
                </div>
                }

                </div>


                
            </div>
            )
        })
        }


        {
        true
        
        && 

        <div
        style={{
            //color: 'white',
            //backgroundColor:'rgba(0,0,255,0.7)',
            //position: 'absolute',
            marginLeft: '-80%',
            marginTop: '9.5%',
            width: '167%',
            height: '10%',
            right:'-18vh',
            display:'flex', justifyContent:'space-between', alignItems:'center',
            borderRadius: '15px',
            /*borderTopRightRadius:'7px', 
            borderBottomRightRadius:'7px',*/
            fontSize: '0.8em',
            cursor:'pointer',
            //border:'1px solid black',
            //boxShadow:`rgba(1, 100, 111, 1.0) 0px 7px 29px 0px`,
        }}

        onClick={()=>{
            
        }}
        >
            <div
            style={{
            color: `${currArray.length===8 ? 'white' : 'gray'}`,
            backgroundColor:`${currArray.length===8 ? 'rgba(0,0,255,0.7)' : 'white'}`,
            //border:'1px solid black',
            height: '100%',
            width:'47%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderTopLeftRadius: '15px',
            borderBottomLeftRadius: '15px',
            boxShadow:`rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`,
            }}

            onClick={()=>{
            setCurrArray(
                ["EUR","GBP","AUD","NZD","USD","CAD","CHF","JPY"]
            )

            /*localStorage.setItem( 'curr_array' + forexORcrypto,
                JSON.stringify(
                ["EUR","GBP","AUD","NZD","USD","CAD","CHF","JPY"]
                )
            )*/
            setIsAllCURRsSelected(true)
            }}
            >
            ALL
            </div>

            <div
            style={{
            color: `${currArray.length===0 ? 'white' : 'gray'}`,
            backgroundColor:`${currArray.length===0 ? 'rgba(0,0,255,0.7)' : 'white'}`,
            //border:'1px solid black',
            height: '100%',
            width:'47%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderTopRightRadius: '15px',
            borderBottomRightRadius: '15px',
            boxShadow:`rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`,
            }}

            onClick={()=>{
            setCurrArray(
                []
            )

            /*localStorage.setItem( 'curr_array' + forexORcrypto,
                JSON.stringify(
                ["USD"]
                )
            )*/
            setIsAllCURRsSelected(false)
            }}
            >
            NONE
            </div>


        </div>
        }


        {
        true
        
        && 

        <div
        style={{
            color: 'white',
            backgroundColor:'rgba(0,0,255,0.7)',
            //position: 'absolute',
            marginLeft: '-82%',
            marginTop: '9.5%',
            width: '170%',
            height: '20%',
            right:'-18vh',
            display:'flex', justifyContent:'space-around', alignItems:'center',
            borderRadius: '7px',
            /*borderTopRightRadius:'7px', 
            borderBottomRightRadius:'7px',*/
            fontSize: '0.8em',
            cursor:'pointer',
            boxShadow:`rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`,
        }}

        onClick={()=>{
            localStorage.setItem( 'curr_array' + forexORcrypto,
            JSON.stringify(
                currArray.length===0 ? ["USD"] : currArray
            )
            )
            setCurrIconBtnClicked(!currIconBtnClicked)
        }}
        >
            {'DONE'}
        </div>
        }
        </div>

    </div>
    
    }


</div>
)
}

export default VisibleCurrCompo
