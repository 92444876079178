
import { Matrix, Table, TFCurrsHistogram} from '../../export_Pages'


 
import {get_live_forex_data, get_hist_forex_data} from '../../../Helpers/get_forex_data_func'

import ForexHomeBody from '../ForexHome_child_component/ForexHomeBody'

import CandlestickChart from '../../ChartSignals/ChartSignals_parent_component/ChartSignals_parent_component'


import {VscRefresh, VscClose} from 'react-icons/vsc'
import {MdOutlineNotificationsActive} from 'react-icons/md'
import {BsWifiOff} from 'react-icons/bs'
import {FaTelegramPlane} from 'react-icons/fa'
import {AiOutlineDown, AiOutlineUp} from 'react-icons/ai'
import { RxHamburgerMenu } from "react-icons/rx";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TfiAlarmClock } from "react-icons/tfi";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import { FaGooglePlay } from "react-icons/fa6";
import { FaTelegram } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";


import '../ForexHome_style/ForexHome.css';

import { useState, useEffect } from 'react'
import {useParams} from 'react-router-dom'

//------------------------------------------------------------------------------------------------
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAOhYbwbkn5IAN-MA5dQ-TTRMa9mvPmAQY",
  authDomain: "myforexapp-dc69f.firebaseapp.com",
  projectId: "myforexapp-dc69f",
  storageBucket: "myforexapp-dc69f.appspot.com",
  messagingSenderId: "455761441754",
  appId: "1:455761441754:web:e7569ce55eac6808c686e3",
  measurementId: "G-SJTYBRN94V"
};



/*
LATER: on update of mobile app
REPLACE : 'isPhone'
BY      : '{"isPhone":true,"isAndroid":true,"appVersion":1.0.0,"isCrypto":false,"key1":value1}'
& DUE TO: old versions having 'isPhone' - Auth by - if('str'.includes('isPhone'))
HAVE    : Update coverScreen for old version users with 'isPhone'

-----
NEW : - '{"isPhone":true,"isAndroid":true,"appVersion":1.0.0,"isCrypto":false,"key1":value1}'
-----

-----------------------------
isForex:true  - for forex App  - const [forexORcrypto, setForexORcrypto] = useState('forex')
isForex:false - for Crypto App - const [forexORcrypto, setForexORcrypto] = useState('crypto')
HINT: db links & others or likes - will be switched to either crypto or forex related links 
      - based on the App being situated for either crypto or forex
-----------------------------

--------------
BEST PRACTICE:
--------------
localStorage.getItem('isPhone') || localStorage.getItem('isPhoneApp')
if(localStorage.getItem('isPhone')){ then: display an UPDATE coverSreen - while updating google analytics }
if(localStorage.getItem('isPhoneApp')){ then: access app version & compare with db new app version - while updating google analytics }

--------------
WEBVIEW api & methods: should be well mastered & used effeciently for all mobile apps - 
                      - to implement furthers like Admob monetization 
                      - modifying the DOM of the html loaded content
                      - performing CRUD between the web-api & the native-app itself
                      - GETTING app version from web-content into the native-app - to test & cause Google play api to display UPDATE app screenCover
--------------
*/
const app = initializeApp(firebaseConfig);//HINT: iPhone should have separate firebase app config key
const analytics = localStorage.getItem('isPhone') !== null ? getAnalytics(app) : undefined
//localStorage.setItem('isPhone', JSON.stringify(true))
//localStorage.removeItem('isPhone')






const ForexHome = ({}) => {

  const {page_type} = useParams()//page_type==='youtube'

  const pageScrollBarWidthSize  = 18

  const [forexORcrypto, setForexORcrypto] = useState('forex')

  const [liveMT4datetimeSTR, setLiveMT4datetimeSTR] = useState('1970-01-01 00:00:00')//'2024-12-01 00:00:00'
  const [liveMT4dayOfWeekNUM, setLiveMT4dayOfWeekNUM] = useState(5)//5 - friday

  const [isShowChartPatternsOnScreen, setIsShowChartPatternsOnScreen] = useState(false)

  //------------------------------------------------------------------------------
  //candlesticks chart states
  //includes solely stuff that might be sharable with other Compos
  const [globalDataStorageObj, setGlobalDataStorageObj] = useState({isObjEMPTY:true})//reset it to false after filled
  //const [globalDataStorageObj1, setGlobalDataStorageObj1] = useState({isObjEMPTY:true})//reset it to false after filled
  const [reloadAllChartsUseEffectCounter, setReloadAllChartsUseEffectCounter] = useState(Date.now())
  const [isDontTouchChart, setIsDontTouchChart] = useState(false)

  //after data loaded from db
  //& then updated into globalDataStorageObj
  //now reload or updated chartNum through useEffectRerunCounter => globalInstruTFuseEffectReRunCounter#
  const [globalInstruTFuseEffectReRunCounter1, setGlobalInstruTFuseEffectReRunCounter1] = useState(Date.now())
  const [globalInstruTFuseEffectReRunCounter2, setGlobalInstruTFuseEffectReRunCounter2] = useState(Date.now())
  const [globalInstruTFuseEffectReRunCounter3, setGlobalInstruTFuseEffectReRunCounter3] = useState(Date.now())
  const [globalInstruTFuseEffectReRunCounter11, setGlobalInstruTFuseEffectReRunCounter11] = useState(Date.now())//for 'pattern_obj' alerts
  const [globalInstruTFuseEffectReRunCounter100, setGlobalInstruTFuseEffectReRunCounter100] = useState(Date.now())//for 'live_forex_csm'

  //to fetch new data from db on useEffect trigger - when header rsi or stoch is clicked
  const [navToOnlyCHARTnum1instruTFuseEffectReRunCounter, setNavToOnlyCHARTnum1instruTFuseEffectReRunCounter] = useState(Date.now())
  
  const [isTouchScreenDevice, setIsTouchScreenDevice] = useState(
    //onst isTouchScreen = 
      'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
  )

  const [globalMaxWindowOuterHeight, setGlobalMaxWindowOuterHeight] = useState(
    //500
        //(window.outerHeight*0.85) > 786 ? 786 : (window.outerHeight*0.85)
        //('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)===false &&//if not touchscreen
        (window.innerHeight*0.87) //> window.innerWidth ? window.innerWidth : (window.innerHeight*0.9)
  )

  const [globalMaxWindowOuterWidth, setGlobalMaxWindowOuterWidth]   = useState(
    //500
    isTouchScreenDevice ? window.innerWidth : window.innerWidth-pageScrollBarWidthSize// : is to takeout the px occupied by the scrollbar thumb of browser - whenever the page becomes length & scrollable
  )


  


  const [tfArray, setTfArray] = useState([
    /*'m1',*/ 'm5', 'm15', 'm30', 'h1', 'h4', 'd1', 'w1', 'mn'
  ])

  const [forexCurrIndexArray, setForexCurrIndexArray] = useState([
      'EUR', 'GBP', 'AUD', 'NZD', 'USD', 'CAD', 'CHF', 'JPY'
  ])

  const [cryptoCurrIndexArray, setCryptoCurrIndexArray] = useState([
    'FDUSD', 'USDC', 'TUSD', 'BNB', 'USDT', 'BTC', 'ETH', 'TRY'
  ])

  const [forexCurrPairArray, setForexCurrPairArray] = useState([
      "EURGBP", "EURAUD", "EURNZD", "EURUSD", "EURCAD", "EURCHF", "EURJPY", 
      "GBPAUD", "GBPNZD", "GBPUSD", "GBPCAD", "GBPCHF", "GBPJPY", 
      "AUDNZD", "AUDUSD", "AUDCAD", "AUDCHF", "AUDJPY", 
      "NZDUSD", "NZDCAD", "NZDCHF", "NZDJPY", 
      "USDCAD", "USDCHF", "USDJPY", 
      "CADCHF", "CADJPY", 
      "CHFJPY" 
  ])

  const [cryptoCurrPairArray, setCryptoCurrPairArray] = useState([
    'FDUSDUSDC', 'FDUSDTUSD', 'BNBFDUSD', 'FDUSDUSDT', 'BTCFDUSD', 'ETHFDUSD', 'FDUSDTRY',
    'USDCTUSD', 'BNBUSDC', 'USDCUSDT', 'BTCUSDC', 'ETHUSDC', 'USDCTRY', 'BNBTUSD',
    'TUSDUSDT', 'BTCTUSD', 'ETHTUSD', 'TUSDTRY', 'BNBUSDT', 'BNBBTC',
    'BNBETH', 'BNBTRY', 'BTCUSDT', 'ETHUSDT',
    'USDTTRY', 'BTCETH', 'BTCTRY', 'ETHTRY', 
  ])


  const [currIndexArray, setCurrIndexArray] = useState(
    forexORcrypto==='forex' ? forexCurrIndexArray : cryptoCurrIndexArray
  )

  const [currPairArray, setCurrPairArray] = useState(
    forexORcrypto==='forex' ? forexCurrPairArray : cryptoCurrPairArray
  )

  const chartNumArrays = [
    'chart_1', 'chart_2', 'chart_3', 'chart_4', 'chart_5',
    'chart_6', 'chart_7', 'chart_8', 'chart_9', 'chart_10'
  ]


  const defaultPREVInstruTFobjFunc = (array, defaultTF='h1')=>{
    const obj = {}

    array.forEach((el, elInd)=>{
      obj[el] = defaultTF
    })

    return(obj)

  }



  const [defaultChartInfoObj,   setDefaultChartInfoObj]   = useState({

    candleORrsi : 'candle',

    indexORpair  : 'pair',//'index'

    instruName   :  forexORcrypto==='forex' ? 'EURUSD' : 'BTCUSDT',
    
    tf           : 'h1',
    //lineTF           : 'h4',
    isdrawingToolOn : true,
    isLinedrawingToolOn : true,
    isLineCorrWindowOn : true,

    prevCandlePairInstruAndTF  : {instruName: forexORcrypto==='forex' ? 'EURUSD' : 'BTCUSDT', tf:'h1'},
    prevCandleIndexInstruAndTF : {instruName: forexORcrypto==='forex' ? 'USD' : 'BTC',    tf:'h1'},

    prevInstruTFbeforeLineChart : {instruName: forexORcrypto==='forex' ? 'EURUSD' : 'BTCUSDT', tf:'h1'},

    //lineCount : 10,

    prevRsiIndexTF             : {tf:'h1'},
    prevStochIndexTF           : {tf:'h1'},

    //prevTFlineCount: {m1:100, m5:100, m15:100, m30:100, h1:100, h4:100, d1:100, w1:100, mn:100},

    //to avoid opening diff tf than the last visited tf of a given pair or index
    //get func to generate default pair & index prevInstruTFobj set to a given default TF
    pairPrevTF  : defaultPREVInstruTFobjFunc(currPairArray),// {'EURUSD' : 'h1', 'AUDCAD' : 'h1'},
    indexPrevTF : defaultPREVInstruTFobjFunc(currIndexArray)//{'EUR' : 'h1', 'CAD' : 'h1'}
  })


  //chart1 will take care of all the navs from the main page header
  //Purposely put into this global scope, in order to edit which chart type/form to show when main page header nav clicked
  //later bring chart10 to global to serve chart10 instruTF changes for Alert Compo Signals
  const [chartInfoObj1,   setChartInfoObj1]   = useState({

    candleORrsi : 'candle',

    indexORpair  : 'index',//'index'

    instruName   : forexORcrypto==='forex' ? 'USD' : 'BTC',
    
    tf           : 'h1',
    //lineTF           : 'h4',
    isdrawingToolOn : true,
    isLinedrawingToolOn : true,
    isLineCorrWindowOn : true,

    prevCandlePairInstruAndTF  : {instruName: forexORcrypto==='forex' ? 'EURUSD' : 'BTCUSDT', tf:'h1'},
    prevCandleIndexInstruAndTF : {instruName:forexORcrypto==='forex' ? 'USD' : 'BTC',    tf:'h1'},

    prevInstruTFbeforeLineChart : {instruName: forexORcrypto==='forex' ? 'EURUSD' : 'BTCUSDT', tf:'h1'},

    //lineCount : 10,

    prevRsiIndexTF             : {tf:'h1'},
    prevStochIndexTF           : {tf:'h1'},

    //prevTFlineCount: {m1:100, m5:100, m15:100, m30:100, h1:100, h4:100, d1:100, w1:100, mn:100},

    //to avoid opening diff tf than the last visited tf of a given pair or index
    //get func to generate default pair & index prevInstruTFobj set to a given default TF
    pairPrevTF  : defaultPREVInstruTFobjFunc(currPairArray),// {'EURUSD' : 'h1', 'AUDCAD' : 'h1'},
    indexPrevTF : defaultPREVInstruTFobjFunc(currIndexArray)//{'EUR' : 'h1', 'CAD' : 'h1'}
  })


  const [chartInfoObj2,   setChartInfoObj2]   = useState({

    candleORrsi : 'candle',

    indexORpair  : 'index',//'index'

    instruName   : forexORcrypto==='forex' ? 'EUR' : 'USDT',
    
    tf           : 'h1',
    //lineTF           : 'h4',
    isdrawingToolOn : true,
    isLinedrawingToolOn : true,
    isLineCorrWindowOn : true,

    prevCandlePairInstruAndTF  : {instruName: forexORcrypto==='forex' ? 'EURUSD' : 'BTCUSDT', tf:'h1'},
    prevCandleIndexInstruAndTF : {instruName:forexORcrypto==='forex' ? 'USD' : 'BTC',    tf:'h1'},

    prevInstruTFbeforeLineChart : {instruName: forexORcrypto==='forex' ? 'EURUSD' : 'BTCUSDT', tf:'h1'},

    //lineCount : 10,

    prevRsiIndexTF             : {tf:'h1'},
    prevStochIndexTF           : {tf:'h1'},

    //prevTFlineCount: {m1:100, m5:100, m15:100, m30:100, h1:100, h4:100, d1:100, w1:100, mn:100},

    //to avoid opening diff tf than the last visited tf of a given pair or index
    //get func to generate default pair & index prevInstruTFobj set to a given default TF
    pairPrevTF  : defaultPREVInstruTFobjFunc(currPairArray),// {'EURUSD' : 'h1', 'AUDCAD' : 'h1'},
    indexPrevTF : defaultPREVInstruTFobjFunc(currIndexArray)//{'EUR' : 'h1', 'CAD' : 'h1'}
  })


  const [chartInfoObj3,   setChartInfoObj3]   = useState({

    candleORrsi : 'candle',

    indexORpair  : 'pair',//'index'

    instruName   : forexORcrypto==='forex' ? 'EURUSD' : 'BTCUSDT',
    
    tf           : 'h1',
    //lineTF           : 'h4',
    isdrawingToolOn : true,
    isLinedrawingToolOn : true,
    isLineCorrWindowOn : true,

    prevCandlePairInstruAndTF  : {instruName: forexORcrypto==='forex' ? 'EURUSD' : 'BTCUSDT', tf:'h1'},
    prevCandleIndexInstruAndTF : {instruName: forexORcrypto==='forex' ? 'USD' : 'BTC',    tf:'h1'},

    prevInstruTFbeforeLineChart : {instruName: forexORcrypto==='forex' ? 'EURUSD' : 'BTCUSDT', tf:'h1'},

    //lineCount : 10,

    prevRsiIndexTF             : {tf:'h1'},
    prevStochIndexTF           : {tf:'h1'},

    //prevTFlineCount: {m1:100, m5:100, m15:100, m30:100, h1:100, h4:100, d1:100, w1:100, mn:100},

    //to avoid opening diff tf than the last visited tf of a given pair or index
    //get func to generate default pair & index prevInstruTFobj set to a given default TF
    pairPrevTF  : defaultPREVInstruTFobjFunc(currPairArray),// {'EURUSD' : 'h1', 'AUDCAD' : 'h1'},
    indexPrevTF : defaultPREVInstruTFobjFunc(currIndexArray)//{'EUR' : 'h1', 'CAD' : 'h1'}
  })


  const defaultChartInstruTFsettingsObj = {
    XmagnificationPercRatio: 0,
    YmagnificationPercRatio: 0,
    beforeYmagCandleIndexInfoObj: {},
    bodyORwickPercPerfValToShow: "Body",
    candlePercStrengthMinMaxRangeVal: {min: 70, max: 100},
    chartXvelocity: 0,
    chartYvelocity: 0,
    dataCandleStartEndIndex: {dataCandleStartIndex: 0, dataCandleEndIndex: 0},//defaultCandlesticksNumOnChart,
    beforeYmagChartInfoTracker: {dataCandleStartIndex: 0, dataCandleEndIndex: 0},
    dayOfWeekHighlightVal: "Wednesday",
    isActivateDailyVerticalLineMarkUp: false,
    isHighlightMidWeekReversalCandles: false,
    isHighlightTimeOfDay: false,
    isHighlightTradingSessions: false,
    isShowCandlePercPerfRange: false,
    isYmagnificationActivated: false,
    openTimeIntervalVal: "Daily",
    timeOfDayHighlightVal: "08:00",
    tradingSessionVal: "All",
    //candleWidthSize : globalMaxWindowOuterWidth / (defaultCandlesticksNumOnChart.dataCandleEndIndex - defaultCandlesticksNumOnChart.dataCandleStartIndex)
  }




  const switchPairChartTOindexFUNC = (param, csmNAVobj={})=>{//param 'candle', 'rsi', 'stoch'
    //console.log('there is a change')
    if(
        true
    ){

        //'USD'/'BTC' remain static for LStorage naming sake for rsi/stoch tfs
        //as rsi/stoch comprise of multi currINDEX data but not specific curr
        //therefore: to avoid dynamic LStorage name/index to access sake, we choose a static CURRname for rsi/stoch index chart
        const multiCURRindexSTATICinstruName = forexORcrypto==='forex' ? 'USD' : 'BTC'

        /*
        candleORrsi : 'candle',//'rsi' 'stoch'
        indexORpair  : 'index',//'pair'
        instruName   : forexORcrypto==='forex' ? 'USD' : 'BTC',
        tf           : 'h1',
        */
        const paramObj = {
          rsi : {
            candleORrsi : 'rsi',
            indexORpair : 'index',
            instruName  : multiCURRindexSTATICinstruName,
            tf          : chartInfoObj1.tf//'h1'
          },
          stoch : {
            candleORrsi : 'stoch',
            indexORpair : 'index',
            instruName  : multiCURRindexSTATICinstruName,
            tf          : chartInfoObj1.tf//'h1'
          },
          candle : {
            candleORrsi : 'candle',
            indexORpair : 'index',
            instruName  : chartInfoObj1.prevCandleIndexInstruAndTF.instruName,//'USD',
            tf          : chartInfoObj1.tf//'h1'
          },
          csm : csmNAVobj
        }

        //prevCandleIndexInstruAndTF : {instruName: forexORcrypto==='forex' ? 'USD' : 'BTC',    tf:'h1'},
        //chartInfoObj1.prevCandleIndexInstruAndTF.instruName

        const chartInRowName= chartNumArrays[1-1]//id='chart_1', 'chart_2'

        const chartSettingsObjToSaveInLStorage = {
            ...chartInfoObj1,
            candleORrsi : paramObj[param].candleORrsi,
            indexORpair : paramObj[param].indexORpair,
            instruName  : paramObj[param].instruName,
            tf          : paramObj[param].tf
        }

        setChartInfoObj1(chartSettingsObjToSaveInLStorage)

        window.localStorage.setItem(`${chartInRowName}_Settings` + forexORcrypto, JSON.stringify({...chartSettingsObjToSaveInLStorage, isLoaded: false}))  

        //to fetch new data from db on useEffect trigger - when header rsi or stoch is clicked
        setNavToOnlyCHARTnum1instruTFuseEffectReRunCounter(Date.now())
        //----------------------------------------------------------------------------------------------------------------------------------------
    }

  }
  //------------------------------------------------------------------------------



  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [defaultDate, setdefaultDate] = useState("1970-01-01")
  const [pageStartTime, setPageStartTime] = useState(Date.now())
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  const [isPagesDropDownClicked, setIsPagesDropDownClicked] = useState(false)
  const [isAlertIconClicked, setIsAlertIconClicked] = useState(false)
  const [isTelegramIconClicked, setIsTelegramIconClicked] = useState(false)
  const [pageSessionType, setPageSessionType] = useState('charts')

  const [isShowDonationPage, setIsShowDonationPage] = useState(false)
  const [isShowMoreHeaderNAVS, setIsShowMoreHeaderNAVS] = useState(true)

  

  const [isShowFooterChartsLinks, setIsShowFooterChartsLinks] = useState(false)
  const [isShowFooterCurrPairAlertsLinks, setIsShowFooterCurrPairAlertsLinks] = useState(false)
  const [isShowFooterCurrIndexAlertsLinks, setIsShowFooterCurrIndexAlertsLinks] = useState(false)

  const [isSeeWhyBlocked, setIsSeeWhyBlocked] = useState(false)//victor
  const [whyBlockedMsg, setWhyBlockedMsg] = useState('click to see why your IP address is blacklisted')

  const [isShowServerDownAlertCompo, setIsShowServerDownAlertCompo] = useState(false)   

  const [useEffectUpdateDATArerunCOUNTER, setUseEffectUpdateDATArerunCOUNTER] = useState(Date.now())   
  const [liveUpdateSECcountdown, setLiveUpdateSECcountdown] = useState(10)

  const currAlertImages = [//image, imageName, alertRoute
        [ '3rd touch trendline alert', '/'],
        [ 'trendline break alerts', '/'],
        [ 'trendline break & retest alerts', '/'],
        [ 'double triple top & bottom alerts', '/'],
        [ '3rd touched shoulder & head alerts', '/'],
        
        [ 'head & shoulder alerts', '/'],
        [ '% retracement alerts', '/'],
        [ 'converging diverging triangle & channel alerts', '/'],
        [ 'falcon impulse & correction alerts', '/'],
        [`SMC - CHOCH (M's & W's) alerts`, '/'],

        [ 'SMC - BOS alerts', '/'],
        [ 'SMC - demand, supply & POI zones alerts', '/'],
        ['downtrend & uptrend (CHOCH + BOS) alerts', '/'],
        [ 'support & resistance level alerts', '/'],
        [ 'psychological level alerts', '/'],
        
        ['pivot level alerts', '/'],
        ['candlestick patterns alerts', '/'],
        [ 'candlestick reversal engulfing % alerts', '/'],
        ['All alerts', '/'],

        ['MULTI timeframes alert strength meter', '/'],
    ]


  const handleButtonClick = () => {
    // Log the custom event using the logEvent method
    //firebase.analytics().logEvent('button_clicked', { button_name: 'my_button' });
  };


  const reloadALLchartGlobalFUNC = () => {

    //------------------------------------------------------
    //update all charts
    setTimeout(() =>{//for 'live_forex_csm'
      setGlobalInstruTFuseEffectReRunCounter100(Date.now())
      //console.log(`chart - ${100} global Update reRun`)
    }, 0)

    setTimeout(() =>{//for 'chart#1'
      setGlobalInstruTFuseEffectReRunCounter1(Date.now())
      //console.log(`chart - ${1} global Update reRun`)
    }, 100)

    setTimeout(() =>{//for 'chart#2'
      setGlobalInstruTFuseEffectReRunCounter2(Date.now())
      //console.log(`chart - ${2} global Update reRun`)
    }, 200)

    setTimeout(() =>{//for 'chart#3'
      setGlobalInstruTFuseEffectReRunCounter3(Date.now())
      //console.log(`chart - ${3} global Update reRun`)
    }, 300)

    setTimeout(() =>{//for 'pattern_obj' alerts
      setGlobalInstruTFuseEffectReRunCounter11(Date.now())
      //console.log(`chart - ${11} global Update reRun`)
    }, 300)
    
  }

  
  useEffect(()=>{


    
    /*if(//Hide Page loading... form ID - when /page of react App Loads
      document.getElementById('pageLoadId') //&&
      //document.getElementById('pageLoadId').style.display - '' emptyStr prevent AuthPass
    ){//pageLoadFormID exists - pageLoadId
      //make it display : "none"
      document.getElementById('pageLoadId').style.display = 'none'
    }*/


    //all interval loops goes here
    const intervalId = setInterval(()=>{
      //console.log('i am interval')

      const areWeOnline = window.navigator.onLine
      //console.log('isOnline : ', areWeOnline)

      if(areWeOnline){
        const timeSpentSinceStart = Date.now() - pageStartTime
        //console.log('pageStartTime : ', timeSpentSinceStart)

        if(//Auth if Elements EXISTs - before fetching attributes from them - to avoid undefined access ERRORs 
          document.getElementById('areWeOnlineId') //&&
          //document.getElementById('areWeOnlineId').style.display
        ){
          document.getElementById('areWeOnlineId').style.display = 'none'
        }

        //console.log('hey tester : ', document.getElementById('tfCURRSdateId').innerText)

        if(
          (document.getElementById('tfCURRSdateId') &&
          document.getElementById('tfCURRSdateId').innerText===defaultDate) 
          && isShowServerDownAlertCompo===false
          && timeSpentSinceStart > 15000//15secs
        ){

          //best server-error-management HINT:----------------------------------------------------------------------------------------------------------------
          //----------
          //if we are not even getting a server.error then ip address is generally blocked from even having access to send a request, not to talk of getting an error response
          //but if we are able to have an access to the server, and the server successfully takes our request, but render as an error response, then atleast our ip address
          //   is not blocked but there is a server-down which needs to be addressed or restarted entirely, or might be a general code error that needs to be fixed,
          //in all if internet connected plus a successful access to the server with an expected data response but there is no change in data/datetime 
          //   then all servers are fine but the forex data extraction server is at fault or brake & needs a reffix, if only is not a weekend where there are no forex data/datetime changes 

          //serverDownId
          //console.log('hello Ghana : ', document.getElementById('serverDownId')===null)
          const if_not_server_error =  document.getElementById('serverDownId')===null

          if(if_not_server_error){
            document.getElementById('ipBlacklistId').style.display = 'block'
          }
          else{
            document.getElementById('ipBlacklistId').style.display = 'none'
          }

          if(document.getElementById('isLoadingId')){//if exists then deal with its attrs
            document.getElementById('isLoadingId').style.display = 'none'
          }
        }
        else if(
          document.getElementById('tfCURRSdateId') &&
          document.getElementById('tfCURRSdateId').innerText!==defaultDate
        ){
          document.getElementById('ipBlacklistId').style.display = 'none'
        }
      }
      else{
        //console.log('pageStartTime : ', Date.now() - pageStartTime)

        if(
          document.getElementById('areWeOnlineId') &&
          document.getElementById('isLoadingId') &&
          document.getElementById('ipBlacklistId')
        ){
        document.getElementById('areWeOnlineId').style.display = 'block'
        document.getElementById('isLoadingId').style.display = 'none'
        document.getElementById('ipBlacklistId').style.display = 'none'
        }

        if(
          document.getElementById('tfCURRSdateId') &&
          document.getElementById('tfCURRSdateId').innerText!==defaultDate
        ){
          document.getElementById('ipBlacklistId').style.display = 'none'
        }
        /*if(document.getElementById('tfCURRSdateId').innerText===defaultDate){
          document.getElementById('ipBlacklistId').style.display = 'block'
        }*/
      }
    }, 3000)



    document.onvisibilitychange = ()=>{

      //console.log('screen visibility on check')

      if(document.visibilityState === 'hidden'){
        //console.log('document hidden')
        const exit_time = Date.now()
        localStorage.setItem('exit_time', 
          JSON.stringify(exit_time)
        )
      }
      else{
        //console.log('document visible')

        if( 
          localStorage.getItem('exit_time') !== null || 
          localStorage.getItem('exit_time') !== undefined
        ){

          const min_per_millisec     = 60 * 1000
          const acceptable_away_mins = 15//5
          const away_for_x_mins      = acceptable_away_mins * min_per_millisec

          const time_start = JSON.parse(localStorage.getItem('exit_time'))
          const time_end   = Date.now()
          const time_diff  = time_end - time_start
          //console.log('time_diff : ', time_diff)

          const isUserAwayForLong =  time_diff >= away_for_x_mins

          if(isUserAwayForLong){
            window.location.reload()
          }
          else{
            //console.log('away time less')
          }
        }
      }
    }


    if(isFirstLoad){

      //-----------------------------------------------------------------------
      /*localStorage.setItem('isPhone', 
          JSON.stringify(true)
        )
        console.log('first load added')*/
        
        
      
      

      //-----------------------------------------------------------------------

      setIsFirstLoad(false)
    }
    
  }, [])

  const CHROME_MIN_INNER_WIDTH = 500



  //techiman
  useEffect(() => {
    /*
    - get all instruNames of various charts fromchartObj LStorage
    - after getting db data for instruName & storing it into setGlobalDataStorageObj state
      - strike a useEffect reRunnerCount to reload chart data in various respective chartObjNum Compos
    */

    //console.log('updating data...')


    /*
    instead of waiting for setInterval useEffect reRun trigger
    on our First page load or refresh & not waiting for setInterval but with the db req useEffect init
    with default instruTFglobalStorage = {isEmpty:true} then take instruTF from LStorage if exist or from default chartObj
    else depend on the setInterval to reach to access db to update data

    useEffect of various charts could be loaded in setTimeouts 
    instead of all charts useEffect to update data are loaded at ones
    to avoid slow page running as updating the whole page charts at once
    */

    
    const TESTindexToUPDATEdataATfromEND = 1//later make it ind=1, after the trial of ind=4 - use this trick for testing data update as we don't have live charts new data update yet


    //query names chartObj  data preparation
    //data taken from LStorage or chartObj state
    let instruTFsToUPDATEobj1 = {}
    let instruTFsToUPDATEobj = {
      'EURUSD_h1' : {datetimeToUpdateFrom : '2023-11-03 00:00:00', isUpdateForexData : true}, 
      'EUR_h1'    : {datetimeToUpdateFrom : '2023-11-03 00:00:00', isUpdateForexData : true}, 
      'USD_h1'    : {datetimeToUpdateFrom : '2023-11-03 00:00:00', isUpdateForexData : true},
      'currs_h1'  : {datetimeToUpdateFrom : '2023-11-03 00:00:00', isUpdateForexData : true}
    }

    instruTFsToUPDATEobj['live_forex_csm'] = {datetimeToUpdateFrom : '', isUpdateForexData : false}
    instruTFsToUPDATEobj['pattern_obj']    = {datetimeToUpdateFrom : '', isUpdateForexData : false}
    instruTFsToUPDATEobj['updates_obj']    = {datetimeToUpdateFrom : '', isUpdateForexData : false}



    //get all instruTFs of all charts to db req
    if(globalDataStorageObj.isObjEMPTY){
      //if empty get all chart# instruTFs from LStorage or from default instruTF of chartInfoObj# when dont exist in LStorage
      //reset it to false after successful db fetch & saved into globalDataStorageObj


      //loop chart# LStorage for instruTFs & save into instruTFsToUPDATEobj - for their db data req
      chartNumArrays.forEach((chartNumStr, elInd)=>{
        //Load Chart settings {
        //isCandle:true, isPair:true, pairTypeName:'EURUSD', candleIndexTypeName:'USD', 
        //lineIndexTypeName:'USD', pairTypeTF:'h4',  candleIndexTypeTF:'h4', lineIndexTypeTF:'h4'}
        //from LStorage if exists else use default
        //& update chart settings into LStorage, in the ChartProjectCompo.jsx 
        //after any update/click on the 4chartSettings Buttons(candle, pair, EURUSD, h4)

        /*settingsObj + forexORcrypto 

          chart1_settingsObj + forexORcrypto
          EURUSD_h4_candle_settingsObj + forexORcrypto
          USD_h4_candle_settingsObj + forexORcrypto
          USD_h4_line_settingsObj + forexORcrypto
        */
        

        if(elInd+1 === 1){//--------------------------------------------------------1---------------------------------------------------------------------------------
          //console.log('hey1')
          const dataObj = window.localStorage.getItem(`${chartNumStr}_Settings`  + forexORcrypto)
          const isDataExist = dataObj !== null

          if(isDataExist){
            //console.log('hey2')
            const data = JSON.parse(dataObj)
            //isLoaded: true - is to make chart loading wait until chartObj settings is fully checked & loaded
            setChartInfoObj1(data && data.instruName!==undefined ? {...data, isLoaded: true} : {...defaultChartInfoObj, isLoaded: true})//set to false on LStorage

            //-----------------------------------------------------------------------------------------------------------
            //get init & wholesome data from db by instruTF without updating from a given datetime
            //if instruTF data exist in LStorage, then use the instruName stored in LStorage under chartInfoObj#
            //chartNum used to find & access the globalInstruTFuseEffectReRunCounter# of a given chartInfoObj# 
            //after its data had been loaded/updated into globalDataStorageObj to load/update data from db

            //chart#-1 
            const chartNum1      = elInd+1
            const instruName1    = data && data.instruName!==undefined ? data.instruName : defaultChartInfoObj.instruName//data.instruName
            const timeframe1     = data && data.tf!==undefined ? data.tf : defaultChartInfoObj.tf//data.tf
            const candleORrsi1   = data && data.candleORrsi!==undefined ? data.candleORrsi : defaultChartInfoObj.candleORrsi//data.candleORrsi
            const isCandleChart1 = candleORrsi1 === 'candle'
            const isRSIchart1    = candleORrsi1 === 'rsi'
            const isSTOCHchart1  = candleORrsi1 === 'stoch'
            const isINDEXchart1  = (data && data.indexORpair!==undefined ? data.indexORpair : defaultChartInfoObj.indexORpair/*data.indexORpair*/) === 'index'
            const isPAIRrsiORstochLineChart1    = isCandleChart1===false && isINDEXchart1===false
            const isEitherRSIorSTOCHindexChart1 = (isRSIchart1 || isSTOCHchart1) && isINDEXchart1 && isCandleChart1===false

            const instru_TF_str1 = `${
              isCandleChart1 ? instruName1 : isPAIRrsiORstochLineChart1 ? instruName1  : 'currs'
            }_${timeframe1}`
            instruTFsToUPDATEobj1[instru_TF_str1] = {datetimeToUpdateFrom : '', isUpdateForexData : false, chartNum : chartNum1}
            //-----------------------------------------------------------------------------------------------------------
          }
          else{
            //console.log('data does not Exist - 2')
            //console.log('hey3')

            //if doesn't exist in LStorage, then store the init config
            //isLoaded: true - is to make chart loading wait until chartObj settings is fully checked & loaded
            window.localStorage.setItem(`${chartNumStr}_Settings`  + forexORcrypto, JSON.stringify({...chartInfoObj1, isLoaded: false}))

            //isLoaded: true - is to make chart loading wait until chartObj settings is fully checked & loaded
            setChartInfoObj1({...chartInfoObj1, isLoaded: true})

            //--------------------------------------------------------------------------------------------------------------------------
            //get init & wholesome data from db by instruTF without updating from a given datetime
            //if doesn't exist in LStorage, then use the default instruName in chartInfoObj#
            //chartNum used to find & access the globalInstruTFuseEffectReRunCounter# of a given chartInfoObj# 
            //after its data had been loaded/updated into globalDataStorageObj to load/update data from db

            //chart#-1 
            const chartNum1      = elInd+1
            const instruName1    = chartInfoObj1.instruName
            const timeframe1     = chartInfoObj1.tf
            const candleORrsi1   = chartInfoObj1.candleORrsi
            const isCandleChart1 = candleORrsi1 === 'candle'
            const isRSIchart1    = candleORrsi1 === 'rsi'
            const isSTOCHchart1  = candleORrsi1 === 'stoch'
            const isINDEXchart1  = chartInfoObj1.indexORpair === 'index'
            const isPAIRrsiORstochLineChart1    = isCandleChart1===false && isINDEXchart1===false
            const isEitherRSIorSTOCHindexChart1 = (isRSIchart1 || isSTOCHchart1) && isINDEXchart1 && isCandleChart1===false

            const instru_TF_str1 = `${
              isCandleChart1 ? instruName1 : isPAIRrsiORstochLineChart1 ? instruName1  : 'currs'
            }_${timeframe1}`
            instruTFsToUPDATEobj1[instru_TF_str1] = {datetimeToUpdateFrom : '', isUpdateForexData : false, chartNum : chartNum1}
          }
         
        }
        else if(elInd+1 === 2){//------------------------------------------------2-----------------------------------------------------------------------------------
          //console.log('hey1')
          const dataObj = window.localStorage.getItem(`${chartNumStr}_Settings`  + forexORcrypto)
          const isDataExist = dataObj !== null

          if(isDataExist){
            //console.log('hey2')
            const data = JSON.parse(dataObj)
            //isLoaded: true - is to make chart loading wait until chartObj settings is fully checked & loaded
            setChartInfoObj2(data && data.instruName!==undefined ? {...data, isLoaded: true} : {...defaultChartInfoObj, isLoaded: true})

            //----------------------------------------------------------------------------------------------------------
            //get init & wholesome data from db by instruTF without updating from a given datetime
            //if instruTF data exist in LStorage, then use the instruName stored in LStorage under chartInfoObj#
            //chartNum used to find & access the globalInstruTFuseEffectReRunCounter# of a given chartInfoObj# 
            //after its data had been loaded/updated into globalDataStorageObj to load/update data from db

            //chart#-2 
            const chartNum2      = elInd+1
            const instruName2    = data && data.instruName!==undefined ? data.instruName : defaultChartInfoObj.instruName//data.instruName
            const timeframe2     = data && data.tf!==undefined ? data.tf : defaultChartInfoObj.tf//data.tf
            const candleORrsi2   = data && data.candleORrsi!==undefined ? data.candleORrsi : defaultChartInfoObj.candleORrsi//data.candleORrsi
            const isCandleChart2 = candleORrsi2 === 'candle'
            const isRSIchart2    = candleORrsi2 === 'rsi'
            const isSTOCHchart2  = candleORrsi2 === 'stoch'
            const isINDEXchart2  = (data && data.indexORpair!==undefined ? data.indexORpair : defaultChartInfoObj.indexORpair/*data.indexORpair*/) === 'index'
            const isPAIRrsiORstochLineChart2    = isCandleChart2===false && isINDEXchart2===false
            const isEitherRSIorSTOCHindexChart2 = (isRSIchart2 || isSTOCHchart2) && isINDEXchart2 && isCandleChart2===false

            const instru_TF_str2 = `${
              isCandleChart2 ? instruName2 : isPAIRrsiORstochLineChart2 ? instruName2  : 'currs'
            }_${timeframe2}`

            instruTFsToUPDATEobj1[instru_TF_str2] = {datetimeToUpdateFrom : '', isUpdateForexData : false, chartNum : chartNum2}
            //----------------------------------------------------------------------------------------------------------

          }
          else{
            //console.log('data does not Exist - 2')
            //console.log('hey3')

            //if doesn't exist in LStorage, then store the init config
            //isLoaded: true - is to make chart loading wait until chartObj settings is fully checked & loaded
            window.localStorage.setItem(`${chartNumStr}_Settings`  + forexORcrypto, JSON.stringify({...chartInfoObj2, isLoaded: false}))

            //isLoaded: true - is to make chart loading wait until chartObj settings is fully checked & loaded
            setChartInfoObj2({...chartInfoObj2, isLoaded: true})

            //--------------------------------------------------------------------------------------------------------------------------
            //get init & wholesome data from db by instruTF without updating from a given datetime
            //if doesn't exist in LStorage, then use the default instruName in chartInfoObj#
            //chartNum used to find & access the globalInstruTFuseEffectReRunCounter# of a given chartInfoObj# 
            //after its data had been loaded/updated into globalDataStorageObj to load/update data from db

            //chart#-2 
            const chartNum2      = elInd+1
            const instruName2    = chartInfoObj2.instruName
            const timeframe2     = chartInfoObj2.tf
            const candleORrsi2   = chartInfoObj2.candleORrsi
            const isCandleChart2 = candleORrsi2 === 'candle'
            const isRSIchart2    = candleORrsi2 === 'rsi'
            const isSTOCHchart2  = candleORrsi2 === 'stoch'
            const isINDEXchart2  = chartInfoObj2.indexORpair === 'index'
            const isPAIRrsiORstochLineChart2    = isCandleChart2===false && isINDEXchart2===false
            const isEitherRSIorSTOCHindexChart2 = (isRSIchart2 || isSTOCHchart2) && isINDEXchart2 && isCandleChart2===false

            const instru_TF_str2 = `${
              isCandleChart2 ? instruName2 : isPAIRrsiORstochLineChart2 ? instruName2  : 'currs'
            }_${timeframe2}`
            instruTFsToUPDATEobj1[instru_TF_str2] = {datetimeToUpdateFrom : '', isUpdateForexData : false, chartNum : chartNum2}
          }

        }
        else if(elInd+1 === 3){//------------------------------------------------3-----------------------------------------------------------------------------------
          //console.log('hey1')
          const dataObj = window.localStorage.getItem(`${chartNumStr}_Settings`  + forexORcrypto)
          const isDataExist = dataObj !== null

          if(isDataExist){
            //console.log('hey2')
            const data = JSON.parse(dataObj)
            //isLoaded: true - is to make chart loading wait until chartObj settings is fully checked & loaded
            setChartInfoObj3(data && data.instruName!==undefined ? {...data, isLoaded: true} : {...defaultChartInfoObj, isLoaded: true})

            //----------------------------------------------------------------------------------------------------------
            //get init & wholesome data from db by instruTF without updating from a given datetime
            //if instruTF data exist in LStorage, then use the instruName stored in LStorage under chartInfoObj#
            //chartNum used to find & access the globalInstruTFuseEffectReRunCounter# of a given chartInfoObj# 
            //after its data had been loaded/updated into globalDataStorageObj to load/update data from db

            //chart#-3 
            const chartNum3      = elInd+1
            const instruName3    = data && data.instruName!==undefined ? data.instruName : defaultChartInfoObj.instruName//data.instruName
            const timeframe3     = data && data.tf!==undefined ? data.tf : defaultChartInfoObj.tf//data.tf
            const candleORrsi3   = data && data.candleORrsi!==undefined ? data.candleORrsi : defaultChartInfoObj.candleORrsi//data.candleORrsi
            const isCandleChart3 = candleORrsi3 === 'candle'
            const isRSIchart3    = candleORrsi3 === 'rsi'
            const isSTOCHchart3  = candleORrsi3 === 'stoch'
            const isINDEXchart3  = (data && data.indexORpair!==undefined ? data.indexORpair : defaultChartInfoObj.indexORpair/*data.indexORpair*/) === 'index'
            const isPAIRrsiORstochLineChart3    = isCandleChart3===false && isINDEXchart3===false
            const isEitherRSIorSTOCHindexChart3 = (isRSIchart3 || isSTOCHchart3) && isINDEXchart3 && isCandleChart3===false

            const instru_TF_str3 = `${
              isCandleChart3 ? instruName3 : isPAIRrsiORstochLineChart3 ? instruName3  : 'currs'
            }_${timeframe3}`

            instruTFsToUPDATEobj1[instru_TF_str3] = {datetimeToUpdateFrom : '', isUpdateForexData : false, chartNum : chartNum3}
            //----------------------------------------------------------------------------------------------------------

          }
          else{
            //console.log('data does not Exist - 3')
            //console.log('hey3')

            //if doesn't exist in LStorage, then store the init config
            //isLoaded: true - is to make chart loading wait until chartObj settings is fully checked & loaded
            window.localStorage.setItem(`${chartNumStr}_Settings`  + forexORcrypto, JSON.stringify({...chartInfoObj3, isLoaded: false}))

            //isLoaded: true - is to make chart loading wait until chartObj settings is fully checked & loaded
            setChartInfoObj3({...chartInfoObj3, isLoaded: true})

            //--------------------------------------------------------------------------------------------------------------------------
            //get init & wholesome data from db by instruTF without updating from a given datetime
            //if doesn't exist in LStorage, then use the default instruName in chartInfoObj#
            //chartNum used to find & access the globalInstruTFuseEffectReRunCounter# of a given chartInfoObj# 
            //after its data had been loaded/updated into globalDataStorageObj to load/update data from db

            //chart#-3 
            const chartNum3      = elInd+1
            const instruName3    = chartInfoObj3.instruName
            const timeframe3     = chartInfoObj3.tf
            const candleORrsi3   = chartInfoObj3.candleORrsi
            const isCandleChart3 = candleORrsi3 === 'candle'
            const isRSIchart3    = candleORrsi3 === 'rsi'
            const isSTOCHchart3  = candleORrsi3 === 'stoch'
            const isINDEXchart3  = chartInfoObj3.indexORpair === 'index'
            const isPAIRrsiORstochLineChart3    = isCandleChart3===false && isINDEXchart3===false
            const isEitherRSIorSTOCHindexChart3 = (isRSIchart3 || isSTOCHchart3) && isINDEXchart3 && isCandleChart3===false

            const instru_TF_str3 = `${
              isCandleChart3 ? instruName3 : isPAIRrsiORstochLineChart3 ? instruName3  : 'currs'
            }_${timeframe3}`
            instruTFsToUPDATEobj1[instru_TF_str3] = {datetimeToUpdateFrom : '', isUpdateForexData : false, chartNum : chartNum3}
          }

        }
      })

    }
    else{
      //if not empty after all the 1st instruTF db req was success & saved into globalDataStorageObj 
      //now get all chart# instruTFs from chartInfoObj# & not from LStorage which had already being accessed on init page/re-load

      //1st get all the chart# instruTFs names from chartInfoObj#
      //& then: loop for their data from globalDataStorageObj to Auth & get the most current datetimeToUpdateFrom @ id[data.length-1]
      //loop chartInfoObj# for instruTFs & save into instruTFsToUPDATEobj - for their db data req
      //'EURUSD_h1' : {datetimeToUpdateFrom : '2023-11-03 00:00:00', isUpdateForexData : true, chartNum : #}
      //'USD_h1'    : {datetimeToUpdateFrom : '2023-11-03 00:00:00', isUpdateForexData : true, chartNum : #}
      //'currs_h1'  : {datetimeToUpdateFrom : '2023-11-03 00:00:00', isUpdateForexData : true, chartNum : #}
      //globalDataStorageObj[chartInfoObj1.instruName].data[globalDataStorageObj[chartInfoObj1.instruName].data.length-1].datetime

      /*
      const isCandleChart = chartInfoObj.candleORrsi==='candle'
      const isRSIchart    = chartInfoObj.candleORrsi === 'rsi'
      const isSTOCHchart    = chartInfoObj.candleORrsi === 'stoch'
      const isINDEXchart  = chartInfoObj.indexORpair === 'index'
      const isPAIRrsiORstochLineChart = isCandleChart===false && isINDEXchart===false
      const isEitherRSIorSTOCHindexChart = (isRSIchart || isSTOCHchart) && isINDEXchart && isCandleChart===false
      const RSIperiod     = isRSIchart ? 14 : 6

      const isCurrIndex = currIndexArray.includes(chartInfoObj.instruName)//candleORrsi - if includes rsi or stoch split[0] b4 db req

      //do this same Auth for instruName of globalDataStorageObj.isObjEMPTY as well
      const instru_TF_str = `${
          isCandleChart ? chartInfoObj.instruName : isPAIRrsiORstochLineChart ? chartInfoObj.instruName  : 'currs'
        }_${chartInfoObj.tf}`
      */




      //--------------------------------------------------------------------------------------------------------------------------
      //chart#-1
      const chartNum1      = 1
      const instruName1    = chartInfoObj1.instruName
      const timeframe1     = chartInfoObj1.tf
      const candleORrsi1   = chartInfoObj1.candleORrsi
      const isCandleChart1 = candleORrsi1 === 'candle'
      const isRSIchart1    = candleORrsi1 === 'rsi'
      const isSTOCHchart1  = candleORrsi1 === 'stoch'
      const isINDEXchart1  = chartInfoObj1.indexORpair === 'index'
      const isPAIRrsiORstochLineChart1    = isCandleChart1===false && isINDEXchart1===false
      const isEitherRSIorSTOCHindexChart1 = (isRSIchart1 || isSTOCHchart1) && isINDEXchart1 && isCandleChart1===false

      const instru_TF_str1 = `${
        isCandleChart1 ? instruName1 : isPAIRrsiORstochLineChart1 ? instruName1  : 'currs'
      }_${timeframe1}`

      
      if(//Auth b4 assignment into instruTFsToUPDATEobj1, as: if all data to update exist in globalDATA, else the delay of local fetch defined in chart# will cause a crash 
         //if respond from db had not arrived yet to fill globalDATA to be access for update by data[len-1].datetime
        globalDataStorageObj[instru_TF_str1] &&
        globalDataStorageObj[instru_TF_str1].data && 
        globalDataStorageObj[instru_TF_str1].data.length > 0
      ){
        //globalDataStorageObj[instru_TF_str1].data[globalDataStorageObj[instru_TF_str1].data.length-1].datetime
        const data1       = globalDataStorageObj[instru_TF_str1].data
        const dataLength1 = data1 ? data1.length : 0//if data1 exists

        const datetimeToUpdateFrom1        = data1 && dataLength1 > 0 ? data1[dataLength1-TESTindexToUPDATEdataATfromEND].datetime : '' //'2023-11-03 00:00:00'
        const isDatetimeToUpdateFromValid1 = authDatetimeStrValidityBeforeDrawORedit(datetimeToUpdateFrom1)
        const isUpdateForexData1           = data1 && dataLength1 > 0 && isDatetimeToUpdateFromValid1 ? true : false//if data exist & >0

        instruTFsToUPDATEobj1[instru_TF_str1] = {
          datetimeToUpdateFrom : datetimeToUpdateFrom1, //'2023-11-03 00:00:00'
          isUpdateForexData    : isUpdateForexData1,    //true or false if datetime not accessible
          chartNum             : chartNum1              //#1,2,3,...
        }
        //'EURUSD_h1' : {datetimeToUpdateFrom : '2023-11-03 00:00:00', isUpdateForexData : true, chartNum : #}
      }
      //--------------------------------------------------------------------------------------------------------------------------*/



      //--------------------------------------------------------------------------------------------------------------------------
      //chart#-2 - highligh in stepwise to select all from top to edit the #
      const chartNum2      = 2
      const instruName2    = chartInfoObj2.instruName
      const timeframe2     = chartInfoObj2.tf
      const candleORrsi2   = chartInfoObj2.candleORrsi
      const isCandleChart2 = candleORrsi2 === 'candle'
      const isRSIchart2    = candleORrsi2 === 'rsi'
      const isSTOCHchart2  = candleORrsi2 === 'stoch'
      const isINDEXchart2  = chartInfoObj2.indexORpair === 'index'
      const isPAIRrsiORstochLineChart2    = isCandleChart2===false && isINDEXchart2===false
      const isEitherRSIorSTOCHindexChart2 = (isRSIchart2 || isSTOCHchart2) && isINDEXchart2 && isCandleChart2===false

      const instru_TF_str2 = `${
        isCandleChart2 ? instruName2 : isPAIRrsiORstochLineChart2 ? instruName2  : 'currs'
      }_${timeframe2}`


      if(//Auth b4 assignment into instruTFsToUPDATEobj1, as: if all data to update exist in globalDATA, else the delay of local fetch defined in chart# will cause a crash 
         //if respond from db had not arrived yet to fill globalDATA to be access for update by data[len-1].datetime
        globalDataStorageObj[instru_TF_str2] &&
        globalDataStorageObj[instru_TF_str2].data && 
        globalDataStorageObj[instru_TF_str2].data.length > 0
      ){
        //globalDataStorageObj[instru_TF_str2].data[globalDataStorageObj[instru_TF_str2].data.length-1].datetime
        const data2       = globalDataStorageObj[instru_TF_str2].data
        const dataLength2 = data2 ? data2.length : 0//if data2 exists

        const datetimeToUpdateFrom2        = data2 && dataLength2 > 0 ? data2[dataLength2-TESTindexToUPDATEdataATfromEND].datetime : '' //'2023-11-03 00:00:00'
        const isDatetimeToUpdateFromValid2 = authDatetimeStrValidityBeforeDrawORedit(datetimeToUpdateFrom2)
        const isUpdateForexData2           = data2 && dataLength2 > 0 && isDatetimeToUpdateFromValid2 ? true : false//if data exist & >0

        instruTFsToUPDATEobj1[instru_TF_str2] = {
          datetimeToUpdateFrom : datetimeToUpdateFrom2, //'2023-11-03 00:00:00'
          isUpdateForexData    : isUpdateForexData2,    //true or false if datetime not accessible
          chartNum             : chartNum2              //#1,2,3,...
        }
        //'EURUSD_h1' : {datetimeToUpdateFrom : '2023-11-03 00:00:00', isUpdateForexData : true, chartNum : #}
      }
      //--------------------------------------------------------------------------------------------------------------------------


      

      //--------------------------------------------------------------------------------------------------------------------------
      //chart#-3 - highligh in stepwise to select all from top to edit the #
      const chartNum3      = 3
      const instruName3    = chartInfoObj3.instruName
      const timeframe3     = chartInfoObj3.tf
      const candleORrsi3   = chartInfoObj3.candleORrsi
      const isCandleChart3 = candleORrsi3 === 'candle'
      const isRSIchart3    = candleORrsi3 === 'rsi'
      const isSTOCHchart3  = candleORrsi3 === 'stoch'
      const isINDEXchart3  = chartInfoObj3.indexORpair === 'index'
      const isPAIRrsiORstochLineChart3    = isCandleChart3===false && isINDEXchart3===false
      const isEitherRSIorSTOCHindexChart3 = (isRSIchart3 || isSTOCHchart3) && isINDEXchart3 && isCandleChart3===false

      const instru_TF_str3 = `${
        isCandleChart3 ? instruName3 : isPAIRrsiORstochLineChart3 ? instruName3  : 'currs'
      }_${timeframe3}`


      if(//Auth b4 assignment into instruTFsToUPDATEobj1, as: if all data to update exist in globalDATA, else the delay of local fetch defined in chart# will cause a crash 
         //if respond from db had not arrived yet to fill globalDATA to be access for update by data[len-1].datetime
        globalDataStorageObj[instru_TF_str3] &&
        globalDataStorageObj[instru_TF_str3].data && 
        globalDataStorageObj[instru_TF_str3].data.length > 0
      ){
        //globalDataStorageObj[instru_TF_str3].data[globalDataStorageObj[instru_TF_str3].data.length-1].datetime
        const data3       = globalDataStorageObj[instru_TF_str3].data
        const dataLength3 = data3 ? data3.length : 0//if data3 exists

        const datetimeToUpdateFrom3        = data3 && dataLength3 > 0 ? data3[dataLength3-TESTindexToUPDATEdataATfromEND].datetime : '' //'2023-11-03 00:00:00'
        const isDatetimeToUpdateFromValid3 = authDatetimeStrValidityBeforeDrawORedit(datetimeToUpdateFrom3)
        const isUpdateForexData3           = data3 && dataLength3 > 0 && isDatetimeToUpdateFromValid3 ? true : false//if data exist & >0

        instruTFsToUPDATEobj1[instru_TF_str3] = {
          datetimeToUpdateFrom : datetimeToUpdateFrom3, //'2023-11-03 00:00:00'
          isUpdateForexData    : isUpdateForexData3,    //true or false if datetime not accessible
          chartNum             : chartNum3              //#1,2,3,...
        }
        //'EURUSD_h1' : {datetimeToUpdateFrom : '2023-11-03 00:00:00', isUpdateForexData : true, chartNum : #}
      }
      //--------------------------------------------------------------------------------------------------------------------------


    }



    //always include or get ('live_forex_csm', 'pattern_obj', 'updates_obj') together with other chartNum instruTFs from db 
    instruTFsToUPDATEobj1['live_forex_csm'] = {datetimeToUpdateFrom : '', isUpdateForexData : false, chartNum : 100}//100 & 200
    instruTFsToUPDATEobj1['updates_obj']    = {datetimeToUpdateFrom : '', isUpdateForexData : false, chartNum : 12}


    //truthy Auth taken from login pass stored in LStorage login pass tracker
    //login pass verified on backend server using db stored password/subscription-pass
    const isADMINloggedORpaidSUBSCRIPTION = true
    if(isADMINloggedORpaidSUBSCRIPTION){
      instruTFsToUPDATEobj1['pattern_obj']    = {datetimeToUpdateFrom : '', isUpdateForexData : false, chartNum : 11}//pattern Alert Compo
    }
    

    //console.log({instruTFsToUPDATEobj1})
    


    //-----------------------------------------------------------------------------------------
    //data req
    if(
      true
      //globalDataStorageObj.isObjEMPTY===false
    ){

      fetch(
        //window.location.hostname==='localhost' && false
        //?
        //`http://${ window.innerWidth < 400 ? '192.168.43.98' : 'localhost'}:5500/db`,
        'https://serverapi.currencystrengthzone.com/db',
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
              forexORcrypto,
              keyNAMES  : instruTFsToUPDATEobj1,
              isPhone   : localStorage.getItem('isPhone') !== null ? true : false,
              phoneInfo : localStorage.getItem('isPhone') !== null ? localStorage.getItem('isPhone') : ''//'{"isPhone":true,"isAndroid":true,"appVersion":1.0.0,"key1":value1}'
            }),
        }
      )
      .then((res) => {
          return res.json()
      })
      .then((dbRESdataObj)=>{
          
          if(dbRESdataObj.error){//alert error, if: resObj.error_key exists in the response object, if image does not exist
              //alert('if error feed state with default data to display page & alert no internet or server under maintainance')
              setIsShowServerDownAlertCompo(true)
              //alert('hello')
          }
          else{//without an resObj.error_key: return the name of the image deleted & an image deletion success msg
              
              if(isShowServerDownAlertCompo){
                  setIsShowServerDownAlertCompo(false)
              }

              if(globalDataStorageObj.isObjEMPTY===true){//first page onload with globalDataStorageObj empty with no instruTF data req from db

                //update other instruTF into newGlobalObj
                //eg: newGlobalObj['EURUSD_h1'] = instruTFdbDATA
              const newGlobalObj = {...globalDataStorageObj}

                //console.log(dbRESdataObj)


                const updatedInstruTFslist = []
                //loop instruTF keys to work with or update from dbRESdataObj
                for(let instruTFkey in dbRESdataObj){

                  const instruTFkeyDBdata = dbRESdataObj[instruTFkey]
                  //console.log({instruTFkey, instruTFkeyDBdata})

                  if(instruTFsToUPDATEobj1[instruTFkey] && instruTFkeyDBdata.data){//if among what we req for from db && has data stored in it
                    

                    let isDATAtruthy = false

                    if(['live_forex_csm', 'pattern_obj', 'updates_obj'].includes(instruTFkey)){
                      isDATAtruthy = (instruTFkey==='live_forex_csm' && instruTFkeyDBdata.data.h1)////atleast contain h1 data
                      || (instruTFkey!=='live_forex_csm' && typeof(instruTFkeyDBdata.data)==='object')
                    }else{//instruTFs
                      isDATAtruthy = instruTFkeyDBdata.data.length > 0//&& instruTFkeyDBdata.data[0].datetime
                    }

                    

                    if(isDATAtruthy){//data Auth is qualified for update
                      //console.log('badu', isDATAtruthy)
                      if(true/*newGlobalObj[instruTFkey]*/){//check if already existing before updating it - use when updating but not when globalDATAobj empty
                        newGlobalObj[instruTFkey] = instruTFkeyDBdata
                        updatedInstruTFslist.push(instruTFkey)
                        
                      }
                    }

                  }

                } 


                
                if(updatedInstruTFslist.length > 0){//if any instruTF updated by count
                  //finally update globalDATA by newObj
                  newGlobalObj.isObjEMPTY = false//once db req successfull for instruTF data & had passed all data validity Auths - set isObjEMPTY = false
                  setGlobalDataStorageObj(newGlobalObj)


                  //------------------------------------------------------
                  //update all charts
                  setTimeout(() =>{//for 'live_forex_csm'
                    setGlobalInstruTFuseEffectReRunCounter100(Date.now())
                    //console.log(`chart - ${100} global Update reRun`)
                  }, 0)

                  setTimeout(() =>{//for 'chart#1'
                    setGlobalInstruTFuseEffectReRunCounter1(Date.now())
                    //console.log(`chart - ${1} global Update reRun`)
                  }, 100)

                  setTimeout(() =>{//for 'chart#2'
                    setGlobalInstruTFuseEffectReRunCounter2(Date.now())
                    //console.log(`chart - ${2} global Update reRun`)
                  }, 200)

                  setTimeout(() =>{//for 'chart#3'
                    setGlobalInstruTFuseEffectReRunCounter3(Date.now())
                    //console.log(`chart - ${3} global Update reRun`)
                  }, 300)

                  setTimeout(() =>{//for 'pattern_obj' alerts
                    setGlobalInstruTFuseEffectReRunCounter11(Date.now())
                    //console.log(`chart - ${11} global Update reRun`)
                  }, 300)


                  //now loop updatedInstruTFslist for the chart# of various updated instruTF to update its charts by triggering its useEffectRerunner
                  /*updatedInstruTFslist.forEach((instruTFname, instruTFelindex)=>{
                    
                    if(instruTFsToUPDATEobj1[instruTFname] && newGlobalObj[instruTFname]){//is it truly updated & exists in the globalDATAobj before we reRun its gloabluseEffect to update its chart#
                      const instruTFchartNum = Number(instruTFsToUPDATEobj1[instruTFname].chartNum)
                      const isValnum         = typeof(instruTFchartNum) === 'number'

                      if(isValnum){
                        if(instruTFchartNum===100){//for 'live_forex_csm'
                          setGlobalInstruTFuseEffectReRunCounter100(Date.now())
                          console.log(`chart - ${instruTFchartNum} global Update reRun`)
                        }
                        else if(instruTFchartNum===11){//for 'pattern_obj' alerts
                          setGlobalInstruTFuseEffectReRunCounter11(Date.now())
                          console.log(`chart - ${instruTFchartNum} global Update reRun`)
                        }//----------------------------------------------------------------------------------------------------------------
                        else if(instruTFchartNum===1){
                          setGlobalInstruTFuseEffectReRunCounter1(Date.now())
                          console.log(`chart - ${instruTFchartNum} global Update reRun`)
                        }
                        else if(instruTFchartNum===2){
                          setGlobalInstruTFuseEffectReRunCounter2(Date.now())
                          console.log(`chart - ${instruTFchartNum} global Update reRun`)
                        }
                        
                      }
                    }

                  })*/
                }

              }
              else{//page has already loaded with some instruTF data already in globalDataStorageObj
                   //now update existing instruTF data in globalDataStorageObj with new db req data

                //update other instruTF into newGlobalObj
                //eg: newGlobalObj['EURUSD_h1'] = instruTFdbDATA
                const newGlobalObj = {...globalDataStorageObj}

                //console.log(dbRESdataObj)


                const updatedInstruTFslist = []
                //loop instruTF keys to work with or update from dbRESdataObj
                for(let instruTFkey in dbRESdataObj){

                  const instruTFkeyDBdata = dbRESdataObj[instruTFkey]
                  //console.log({instruTFkey, instruTFkeyDBdata})

                  if(instruTFsToUPDATEobj1[instruTFkey] && instruTFkeyDBdata.data){//if among what we req for from db && has data stored in it
                    

                    let isDATAtruthy = false
                    let isNotCSMdata = false

                    if(['live_forex_csm', 'pattern_obj', 'updates_obj'].includes(instruTFkey)){
                      isDATAtruthy = (instruTFkey==='live_forex_csm' && instruTFkeyDBdata.data.h1) || (instruTFkey!=='live_forex_csm' && typeof(instruTFkeyDBdata.data)==='object')
                    }else{//instruTFs
                      isDATAtruthy = instruTFkeyDBdata.data.length > 0
                      isNotCSMdata = isDATAtruthy
                    }

                    

                    if(isDATAtruthy){//data Auth is qualified for update
                      //console.log('badu', isDATAtruthy)
                      if(newGlobalObj[instruTFkey]){//check if already existing before updating it - use when updating but not when globalDATAobj empty

                        if(isNotCSMdata){
                          //--------------------------------------------------------------------------------------------------------------------
                          //data slice update goes here & stored into instruTFkeyDBdata for update
                          const isCurrIndex = currIndexArray.includes(instruTFkey.split('_')[0])

                          //console.log('db : ', instruTFkeyDBdata.data)
                          const dbFirstIndexDatetime  = instruTFkeyDBdata.data[0].datetime
                          const isDBfirstIndexDTvalid = authDatetimeStrValidityBeforeDrawORedit(dbFirstIndexDatetime)

                          if(isDBfirstIndexDTvalid){

                              const instruTFglobalDataObj = newGlobalObj[instruTFkey]

                              const feDataLastEntryDatetime = instruTFglobalDataObj.data[instruTFglobalDataObj.data.length-TESTindexToUPDATEdataATfromEND].datetime

                              const isDTsameForUNION = dbFirstIndexDatetime === feDataLastEntryDatetime //=== datetimeToUpdateFrom

                              if(isDTsameForUNION){

                                  //slice data but leave out the last index, spread the dbDATA that starts with that last index details/DT
                                  const numOfEntriesToSliceFromEnd = TESTindexToUPDATEdataATfromEND//1
                                  let newDATA = instruTFglobalDataObj.data.slice(0, instruTFglobalDataObj.data.length - numOfEntriesToSliceFromEnd)
                                  newDATA = [...newDATA, ...instruTFkeyDBdata.data]

                                  const minPriceVal = Math.min(instruTFkeyDBdata.minPriceVal, instruTFglobalDataObj.minPriceVal)
                                  const maxPriceVal = Math.max(instruTFkeyDBdata.maxPriceVal, instruTFglobalDataObj.maxPriceVal)

                                  
                                  const newInstruTFobj = {
                                      data : newDATA,
                                      minPriceVal,
                                      maxPriceVal
                                  }//instruTFglobalDataObj
                                  
                                  if(isCurrIndex){//newInstruTFobj
                                      newInstruTFobj['indexAndPairPsychoConvDataObj'] = instruTFkeyDBdata.indexAndPairPsychoConvDataObj
                                  }

                                  
                                  //updateGlobal data
                                  newGlobalObj[instruTFkey] = newInstruTFobj//instruTFglobalDataObj
                                  updatedInstruTFslist.push(instruTFkey)
                                  
                                  /*console.log({
                                      dbFirstIndexDatetime,
                                      isDBfirstIndexDTvalid, 
                                      isDTsameForUNION,
                                      dbFirstIndexDatetime,
                                      feDataLastEntryDatetime , datetimeToUpdateFrom
                                  })*/
                              }
                          }
                          //--------------------------------------------------------------------------------------------------------------------
                        }
                        else{
                          newGlobalObj[instruTFkey] = instruTFkeyDBdata//newGlobalObj[instruTFkey]//instruTFkeyDBdata
                          updatedInstruTFslist.push(instruTFkey)
                        }
                        
                      }
                    }

                  }

                } 
                
                if(updatedInstruTFslist.length > 0){//if any instruTF updated by count
                  //finally update globalDATA by newObj
                  newGlobalObj.isObjEMPTY = false//once db req successfull for instruTF data & had passed all data validity Auths - set isObjEMPTY = false
                  setGlobalDataStorageObj(newGlobalObj)

                  //------------------------------------------------------
                  //update all charts
                  //setTimeout(() =>{//for 'live_forex_csm'
                    setGlobalInstruTFuseEffectReRunCounter100(Date.now())
                    //console.log(`chart - ${100} global Update reRun`)
                  //}, 0)

                  //setTimeout(() =>{//for 'chart#1'
                    setGlobalInstruTFuseEffectReRunCounter1(Date.now())
                    //console.log(`chart - ${1} global Update reRun`)
                  //}, 100)

                  //setTimeout(() =>{//for 'chart#2'
                    setGlobalInstruTFuseEffectReRunCounter2(Date.now())
                    //console.log(`chart - ${2} global Update reRun`)
                  //}, 200)

                  //setTimeout(() =>{//for 'chart#3'
                    setGlobalInstruTFuseEffectReRunCounter3(Date.now())
                    //console.log(`chart - ${3} global Update reRun`)
                  //}, 300)

                  //setTimeout(() =>{//for 'pattern_obj' alerts
                    setGlobalInstruTFuseEffectReRunCounter11(Date.now())
                    //console.log(`chart - ${11} global Update reRun`)
                  //}, 300)

                  //now loop updatedInstruTFslist for the chart# of various updated instruTF to update its charts by triggering its useEffectRerunner
                  /*updatedInstruTFslist.forEach((instruTFname, instruTFelindex)=>{
                    
                    if(instruTFsToUPDATEobj1[instruTFname] && newGlobalObj[instruTFname]){//is it truly updated & exists in the globalDATAobj before we reRun its gloabluseEffect to update its chart#
                      const instruTFchartNum = Number(instruTFsToUPDATEobj1[instruTFname].chartNum)
                      const isValnum         = typeof(instruTFchartNum) === 'number'

                      if(isValnum){
                        if(instruTFchartNum===100){//for 'live_forex_csm'
                          setGlobalInstruTFuseEffectReRunCounter100(Date.now())
                          console.log(`chart - ${instruTFchartNum} global Update reRun`)
                        }
                        else if(instruTFchartNum===11){//for 'pattern_obj' alerts
                          setGlobalInstruTFuseEffectReRunCounter11(Date.now())
                          console.log(`chart - ${instruTFchartNum} global Update reRun`)
                        }//----------------------------------------------------------------------------------------------------------------
                        else if(instruTFchartNum===1){
                          setGlobalInstruTFuseEffectReRunCounter1(Date.now())
                          console.log(`chart - ${instruTFchartNum} global Update reRun`)
                        }
                        else if(instruTFchartNum===2){
                          setGlobalInstruTFuseEffectReRunCounter2(Date.now())
                          console.log(`chart - ${instruTFchartNum} global Update reRun`)
                        }
                        
                      }
                    }

                  })*/
                }
              }

              
              /*
              EURUSD_h1: {data: Array(24), minPriceVal: 1.04818, maxPriceVal: 1.07462}
              EUR_h1: {data: Array(24), minPriceVal: 51.99945388172752, maxPriceVal: 67.73050067190363, indexAndPairPsychoConvDataObj: {…}}
              USD_h1: {data: Array(24), minPriceVal: -0.362870952373874, maxPriceVal: 14.69274793391717, indexAndPairPsychoConvDataObj: {…}}
              currs_h1: {data: Array(24), minPriceVal: 0, maxPriceVal: 100}
              live_forex_csm: {data: {…}, minPriceVal: 0, maxPriceVal: 0}
              pattern_obj: {data: {…}, minPriceVal: 0, maxPriceVal: 0}
              updates_obj: {data: {…}, minPriceVal: 0, maxPriceVal: 0}
              */

              /*
              NOTICE:
              before: passing individual instruTF data into their respective states for usage
              Auth: individual instruFT data, 
              if had the right ingredients/data to feed various charts, to avoid breaks
              Auth data array has certain length & with 1st obj entry of data array having certain key[OHLC] data entries
              to the charts to avoid crashes
              */


              //-------------------------------------------------------------------------------------
              //Close Loading Compo, if data successfully LOADED
              if(//Hide Page loading... form ID - when /page of react App Loads
                document.getElementById('pageLoadId') 
                //document.getElementById('pageLoadId').style.display - '' emptyStr prevent AuthPass
              ){//pageLoadFormID exists - pageLoadId
                //make it display : "none"
                //console.log('Loading... - not CLOSED')

                //to hide element from doc & made visible if needed
                //document.getElementById('pageLoadId').style.display = 'none'//.remove()

                //to completely remove an element
                document.getElementById('pageLoadId').remove()
              }
              //-------------------------------------------------------------------------------------
            
      
          }

      })
    }


  }, [useEffectUpdateDATArerunCOUNTER])



  
  useEffect(()=>{
    //have a setInterval runned to just update useEffectUpdateDATArerunCOUNTER state
    //to have forex data updated in various sets of interval anytime useEffectUpdateDATArerunCOUNTER is updated
    
    //useLocation - useParams - window.location.pathname - window.location.href
    setInterval(()=>{//NOTE: code still run in the background of Other Pages when NAV from this page to Other pages

      /*if(isChartDATAloadedAtleastOnce===false){
        setIsChartDATAloadedAtleastOnce(true)
      }*/

      //-------------GET Page urlPATH---------------
      const urlPATHname = window.location.pathname
      const isRootPATH  = urlPATHname === '/';
      //console.log({urlPATHname,isRootPATH})
      //--------------------------------------------

      //if(globalDataStorageObj.isObjEMPTY===false){

      //AUTHENTICATION: eventhough setInterval func will be still running on loop while on other /pageROUTE than the /page it was defined in  
      //                - yet the code/task inside it won't run/executed when on other /pageROUTEs
      //ONLY needed in REACT fake /pathROUTing that still stand to harbour various operations of prev /ROUTE, but not real <a href="/pathROUTING">
      if(isRootPATH){//HINT: to avoid code from still running in the background of Other /Pages when NAV from this /ROOTpage to Other /pages, eh: /privacy
        //console.log('lets db request')
        setUseEffectUpdateDATArerunCOUNTER(Date.now())
      }
      //}
    }, 10000)//10sec


    setInterval(()=>{


      const resNUM = (Date.now() - useEffectUpdateDATArerunCOUNTER) / 1000
      
      const dbREQtimeInterval = 10
      const timeSTR    =  String(Math.floor(Number(resNUM)))
      const timeSTRlen = timeSTR.length
      const lastDIGIT  = Number(timeSTR[timeSTRlen - 1])
      let finalSEC     = dbREQtimeInterval - lastDIGIT
      finalSEC         = finalSEC > 10 ? 10 : finalSEC < 0 ? 0 : finalSEC

      //console.log(finalSEC)

      setLiveUpdateSECcountdown(finalSEC)



    }, 1000)//1sec

  }, [])

  

return (
<
>


    {true &&
      <div id={'maintainanceId'}
      style={{backgroundColor:'black', color:'white', position:'sticky', 
      top:'0px', zIndex:'100', display:'none', padding:'5px'
      }}>
      Dear valued users, 
      <span style={{color:'yellow', marginLeft:'3px'}}>our forex database server for updates is under maintanance,</span>
       
      We apologize for any inconvenience.
      <span style={{color:'lightblue', marginLeft:'3px'}}>Rest assured, we are making every effort to work things out.</span>
        
      </div>
    }


    {false &&
      <div style={{backgroundColor:'black', color:'white', position:'sticky', top:'0px', zIndex:'100',
      padding:'7px'
      }}>
      <span 
      style={{color:'yellow', marginRight:'5px'}}
      >Our forex updates are back</span>
      <span style={{color:'lightblue'}}>#enjoy</span> . Also, currently we are hosting our forex server <u style={{color:'yellow'}}>from home until</u> our virtual service provider
      resolve this:
      <button
      style={{
        backgroundColor:'gold',
        color:'black',
        padding:'3px',
        borderRadius:'5px',
        marginLeft:'7px',
        textAlign:'center',
        cursor:'pointer',
      }}

      onClick={() => {
        window.open('https://clients.databasemart.com/index.php?rp=/announcements/14/Network-and-Power-Issue-in-Kansas-Data-Center.html', '_blank');
      }}
      >
        click to see
      </button>
      </div>
    }


    {false &&
      <div style={{backgroundColor:'black', color:'white',
      }}>
      <span 
      style={{color:'yellow', marginRight:'5px'}}
      >Our forex updates are back from maintenance</span>
      <span style={{color:'lightblue'}}>#enjoy...</span> 
      </div>
    }


    {true &&//alert if no internet connection
      <div id={'areWeOnlineId'}
      style={{backgroundColor:'black', color:'white',
      display:'none', justifyContent:'center', alignItems:'center',
      position:'sticky', top:'0px', zIndex:'100',
      }}>
        <div
        style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center',}}
        >
          <span
            style={{marginRight:'5px'}}
            ><BsWifiOff/></span>
            No internet connection
        </div>
      </div>
    }


    {true &&//alert if no internet connection
      <div 
      id={'ipBlacklistId'}
      style={{backgroundColor:'black', color:'white',
      display:'none', 
      justifyContent:'center', alignItems:'center',
      position:'sticky', top:'0px', zIndex:'100', //marginBottom:'10px'
      }}>
        <div
        style={{fontSize:'13px',
          width:'97%', margin:'0px auto',
          //marginBottom:'10px' 
          /*display:'flex', justifyContent:'center', alignItems:'center'*/
      }}
        >
            

            <u style={{color:'yellow'}}> Attention!!!</u>
            <ul style={{}}>
              <li>if your internet is connected,</li>
              <li>yet still the 
              <span style={{color:'lightblue', marginLeft:'6px', marginRight:'6px'}}>
              forex data is not loading on the page,
              </span></li>
              <li>Or Check to see if
              <span style={{color:'lightblue', marginLeft:'6px', marginRight:'6px'}}>
               you have enough internet data, Or restart your internet network,
              </span></li>
              <li>Or email us on : <u style={{color:'lightblue', marginLeft:'6px', marginRight:'6px'}}>
              support@currencystrengthzone.com {false && <button style={{marginLeft:'5px'}}>{true ? 'Copy' : 'Copied'}</button>}
              </u></li>
            </ul>

            {/*<u style={{color:'yellow'}}>Solution :</u>
            <ul style={{}}>
              <li>
                  Go to your browser history and make sure you <span style={{color:'lightblue'}}>clear all browsing data</span> of our website (https://www.currencystrengthzone.com) and refresh, 
                  OR <button
                style={{
                  cursor:'pointer',
                  borderWidth:'0px',
                  borderRadius:'5px',
                  //transform:'scale(1.05)'
                }}
                onClick={()=>{
                  //console.log('btn clicked')
                  window.open('https://www.currencystrengthzone.com/', '_self');
                }}
                > click to re-visit our website</button>
              </li>
              <li>
                  OR, Download and use a <span style={{color:'yellow'}}>free VPN app to change your ip address & refresh</span>  as may be your ip address might have been blacklisted from accessing data from our servers
              </li>
              <li>
                OR, for the meantime, click to download & to use our <span style={{color:'yellow'}} > Android Mobile App </span>  <button
                style={{
                  cursor:'pointer',
                  borderWidth:'0px',
                  borderRadius:'5px',
                  transform:'scale(1.05)'
                }}
                onClick={()=>{
                  //console.log('btn clicked')
                  window.open('https://play.google.com/store/apps/details?id=com.victorboadum.currency_strength_meter', '_blank');
                }}
                > download </button>
              </li>
            </ul>*/}

            {/*<u style={{color:'yellow'}}>Moreover :</u>
            <ul style={{}}>
              <li>
              <span> if after doing all the above Solutions and yet still forex data doesn't load, then 
            report the issue to us through our email on : </span> <u style={{color:'lightblue', marginLeft:'6px', marginRight:'6px'}}>
              support@currencystrengthzone.com
            </u>
              </li>
            </ul>*/}

            <div style={{height:'1px'}}></div>

        </div>

        

      </div>
    }







    {//Header
      <header className="csmBodyWidthResizer"
      style={{
        height:'40px',
        display: 'flex',
        borderBottom:'0.1px solid gray',
        borderRight:'0.1px solid gray',
          borderLeft:'0.1px solid gray',
        margin:'0 auto'
      }}
      >

        {false && <div
        style={{//GiHamburgerMenu
          borderRight:'1px solid black',
          height:'100%',
          width:'40px',
          display:'flex',justifyContent:'center', alignItems: 'center',
          cursor:'pointer'
        }}
        >
          <GiHamburgerMenu //RxHamburgerMenu
          style={{height:'20px', width:'20px', marginLeft:'10px', marginRight:'10px'}}
          />
        </div>}

        
        {//truthy after crypto data parsed & ready for use
          false &&

          <div
          style={{
            borderRight:'1px solid black',
            height:'100%',
            width:`${window.innerWidth < 500 ? '100%' : ''}`,
            display:'flex',justifyContent:'center', alignItems: 'center',
            cursor:'pointer'
          }}

          onClick={()=>{
            //may be reload the whole chart afresh for switched crypto or forex being switched to
          }}
          >
            <select //id="" 
            value={
                forexORcrypto//''
            } 
            onChange={(e)=>{
              setForexORcrypto(e.target.value)
            }}
            //style={{cursor:'pointer', marginLeft:'2px', height:'27px'}}
            >
                {//
                    ['forex', 'crypto'].map((el, ind)=>{
                        return(
                            <option value={el} key={ind} style={{cursor:'pointer'}}>{
                                el
                            }</option>
                        )
                    })
                }
                
            </select>
            
          </div>
        }

        {isShowMoreHeaderNAVS===false &&
          <div
          style={{
            borderRight:'1px solid black',
            height:'100%',
            width:`${window.innerWidth < 500 ? '100%' : ''}`,
            display:'flex',justifyContent:'center', alignItems: 'center',
            cursor:'pointer'
          }}

          onClick={()=>{
            setIsShowMoreHeaderNAVS(!isShowMoreHeaderNAVS)
          }}
          >
            {
              /*
              HINT:
              make a similar Compo for Babypip Trading sessions & Volumes timeframes 
              */
            }
            <div
            style={{height:'100%', display:'flex',justifyContent:'center', alignItems: 'center',
            marginLeft:'12px', marginRight:'4px'
            }}
            >
              <MdArrowBackIos
              
              />
            
            </div>
            
          </div>
        }


        {isShowMoreHeaderNAVS &&
          <div
          style={{
            borderRight:'0.1px solid gray',
            //borderLeft:'0.1px solid gray',
            height:'100%',
            width:`${window.innerWidth < 500 ? '100%' : ''}`,
            display:'flex',justifyContent:'center', alignItems: 'center',
            cursor:'pointer',
            userSelect:'none',
            //marginLeft:'10px'
          }}

          onClick={()=>{

            switchPairChartTOindexFUNC('candle')//param 'candle', 'rsi', 'stoch'

            //
            const targetDiv = document.getElementById(`adCompoParentId-${1}`);
            targetDiv.scrollIntoView({
                behavior: 'smooth' // You can change this to 'auto' for immediate scrolling without smooth animation.
            })
          }}
          >
            <div
            style={{height:'100%', display:'flex',justifyContent:'center', alignItems: 'center',
            marginLeft:'4px'
            }}
            >Chart</div>
            
            <div>
            <MdKeyboardArrowDown
            style={{
              marginRight:'4px'
            }}
            />
            </div>
            
          </div>
        }

        {isShowMoreHeaderNAVS &&
          <div
          style={{
            borderRight:'0.1px solid gray',
            height:'100%',
            width:`${window.innerWidth < 500 ? '100%' : ''}`,
            display:'flex',justifyContent:'center', alignItems: 'center',
            cursor:'pointer',
            userSelect:'none'
          }}

          onClick={()=>{

            switchPairChartTOindexFUNC('rsi')//param 'candle', 'rsi', 'stoch'

            //1st edit chartObj to show rsi & reRun useEffect of chart & then nav to it 
            const targetDiv = document.getElementById(`adCompoParentId-${1}`);
            targetDiv.scrollIntoView({
                behavior: 'smooth' // You can change this to 'auto' for immediate scrolling without smooth animation.
            })
          }}
          >
            <div
            style={{height:'100%', display:'flex',justifyContent:'center', alignItems: 'center',
            marginLeft:'4px'
            }}
            >rsi</div>
            
            <div>
            <MdKeyboardArrowDown
            style={{
              marginRight:'4px'
            }}
            />
            </div>
            
          </div>
        }

        {
          <div
          style={{
            borderRight:'0.1px solid gray',
            height:'100%',
            width:`${window.innerWidth < 500 ? '100%' : ''}`,
            display:'flex',justifyContent:'center', alignItems: 'center',
            cursor:'pointer',
            userSelect:'none'
          }}

          onClick={()=>{

            switchPairChartTOindexFUNC('stoch')//param 'candle', 'rsi', 'stoch'

            //1st edit chartObj to show stochastic & reRun useEffect of chart & then nav to it 
            const targetDiv = document.getElementById(`adCompoParentId-${1}`);
            targetDiv.scrollIntoView({
                behavior: 'smooth' // You can change this to 'auto' for immediate scrolling without smooth animation.
            })
          }}
          >
            <div
            style={{height:'100%', display:'flex',justifyContent:'center', alignItems: 'center',
            marginLeft:'4px'
            }}
            >stoch.</div>
            
            <div>
            <MdKeyboardArrowDown
            style={{
              marginRight:'4px'
            }}
            />
            </div>
            
          </div>
        }

        {
          <div
          style={{
            borderRight:'0.1px solid gray',
            height:'100%',
            width:`${window.innerWidth < 500 ? '100%' : ''}`,
            display:'flex',justifyContent:'center', alignItems: 'center',
            cursor:'pointer',
            backgroundColor:'rgba(0,0,255,0.08)',
            userSelect:'none'
          }}

          onClick={()=>{
            //
            const targetDiv = document.getElementById(`usageCompo-id`);
            targetDiv.scrollIntoView({
                behavior: 'smooth' // You can change this to 'auto' for immediate scrolling without smooth animation.
            })

            setUseEffectUpdateDATArerunCOUNTER(Date.now())
          }}
          >
            <div
            style={{height:'100%', display:'flex',justifyContent:'center', alignItems: 'center',
            marginLeft:'4px'
            }}
            >Usage</div>
            
            <div>
            <MdKeyboardArrowDown
            style={{
              marginRight:'4px'
            }}
            />
            </div>
            
          </div>
        }


        {
          <div
          style={{
            borderRight:'0.1px solid gray',
            height:'100%',
            width:`${window.innerWidth < 500 ? '100%' : ''}`,
            display:'flex',justifyContent:'center', alignItems: 'center',
            cursor:'pointer'
          }}

          onClick={()=>{
            //1st edit chartObj to show rsi & reRun useEffect of chart & then nav to it 
            const targetDiv = document.getElementById('selectTimeZone-id');
            targetDiv.scrollIntoView({
                behavior: 'smooth' // You can change this to 'auto' for immediate scrolling without smooth animation.
            })
          }}
          >
            {/*<div
            style={{height:'100%', display:'flex',justifyContent:'center', alignItems: 'center',
            marginLeft:'4px'
            }}
            >get it on playStore</div>*/}

            {
              <TfiAlarmClock
              style={{
                marginLeft:'4px', 
                marginRight:'2px',
                color:'black'
              }}
              />
            }


            <div>
            <MdKeyboardArrowDown
            style={{
              marginRight:'2px'
            }}
            />
            </div>
            
          </div>
        }



        {false &&isShowMoreHeaderNAVS &&
          <div
          style={{
            borderRight:'1px solid black',
            height:'100%',
            width:`${window.innerWidth < 500 ? '100%' : ''}`,
            display:'flex',justifyContent:'center', alignItems: 'center',
            cursor:'pointer'
          }}

          onClick={()=>{
            setIsShowMoreHeaderNAVS(!isShowMoreHeaderNAVS)
          }}
          >
            {
              /*
              HINT:
              make a similar Compo for Babypip Trading sessions & Volumes timeframes 
              */
            }
            <div
            style={{height:'100%', display:'flex',justifyContent:'center', alignItems: 'center',
            marginLeft:'10px', marginRight:'8px'
            }}
            >
              <MdArrowForwardIos
              
              />
            
            </div>
            
          </div>
        }



        {isShowMoreHeaderNAVS===false &&
          <div
          style={{
            borderRight:'1px solid black',
            height:'100%',
            width:`${window.innerWidth < 500 ? '100%' : ''}`,
            display:'flex',justifyContent:'center', alignItems: 'center',
            cursor:'pointer'
          }}

          onClick={()=>{
            //1st edit chartObj to show rsi & reRun useEffect of chart & then nav to it 
            /*const targetDiv = document.getElementById(`adCompoParentId-${1}`);
            targetDiv.scrollIntoView({
                behavior: 'smooth' // You can change this to 'auto' for immediate scrolling without smooth animation.
            })*/
            setIsShowChartPatternsOnScreen(!isShowChartPatternsOnScreen)
          }}
          >
            Chart Patterns
            
          </div>
        }


        {isShowMoreHeaderNAVS===false &&
          <div
          style={{
            borderRight:'1px solid black',
            height:'100%',
            width:`${window.innerWidth < 500 ? '100%' : ''}`,
            display:'flex',justifyContent:'center', alignItems: 'center',
            cursor:'pointer'
          }}

          onClick={()=>{
            //1st edit chartObj to show rsi & reRun useEffect of chart & then nav to it 
            const targetDiv = document.getElementById(`adCompoParentId-${1}`);
            targetDiv.scrollIntoView({
                behavior: 'smooth' // You can change this to 'auto' for immediate scrolling without smooth animation.
            })
          }}
          >
            {/*<div
            style={{height:'100%', display:'flex',justifyContent:'center', alignItems: 'center',
            marginLeft:'4px'
            }}
            >get it on playStore</div>*/}

            {
              <FaGooglePlay
              style={{
                marginLeft:'10px', 
                marginRight:'10px',
                color:'black'
              }}
              />
            }
            
          </div>
        }

        {isShowMoreHeaderNAVS===false &&
          <div
          style={{
            borderRight:'1px solid black',
            height:'100%',
            width:`${window.innerWidth < 500 ? '100%' : ''}`,
            display:'flex',justifyContent:'center', alignItems: 'center',
            cursor:'pointer'
          }}

          onClick={()=>{
            //1st edit chartObj to show rsi & reRun useEffect of chart & then nav to it 
            const targetDiv = document.getElementById(`adCompoParentId-${1}`);
            targetDiv.scrollIntoView({
                behavior: 'smooth' // You can change this to 'auto' for immediate scrolling without smooth animation.
            })
          }}
          >
            {/*<div
            style={{height:'100%', display:'flex',justifyContent:'center', alignItems: 'center',
            marginLeft:'4px'
            }}
            >Telegram</div>*/}

            {
              <FaTelegram
              style={{
                marginLeft:'10px',  
                marginRight:'10px',
                color:'blue'
              }}
              />
            }
            
            
          </div>
        }

        {isShowMoreHeaderNAVS===false &&
          <div
          style={{
            borderRight:'1px solid black',
            height:'100%',
            width:`${window.innerWidth < 500 ? '100%' : ''}`,
            display:'flex',justifyContent:'center', alignItems: 'center',
            cursor:'pointer'
          }}

          onClick={()=>{
            //1st edit chartObj to show rsi & reRun useEffect of chart & then nav to it 
            const targetDiv = document.getElementById(`adCompoParentId-${1}`);
            targetDiv.scrollIntoView({
                behavior: 'smooth' // You can change this to 'auto' for immediate scrolling without smooth animation.
            })
          }}
          >
            {/*<div
            style={{height:'100%', display:'flex',justifyContent:'center', alignItems: 'center',
            marginLeft:'4px'
            }}
            >Telegram</div>*/}

            {
              <IoLogoYoutube
              style={{
                marginLeft:'10px',  
                marginRight:'10px',
                color:'red'
              }}
              />
            }
            
            
          </div>
        }
      
      </header>
    }



    {//msg - annoucement
      false &&
      <div
      style={{
        fontSize:'12px',
        backgroundColor:'black',
        color:'white',
        textAlign:'center',
        userSelect:'none'
      }}
      >
         more Updates, fixes & how to use it - coming soon...
      </div>
    }


    {//essential in App dev state, but comment out for Production
      //essential to clear LStorage to start/test App afresh to avoid LStorage keyName change crashes 
      false &&
      <button
      style={{cursor:'pointer', backgroundColor:'pink'}}
      onClick={()=>{
          localStorage.clear()
          window.document.location.reload()
      }}
      >clear All LStorage states Objs to Allow a fresh Page Reload</button>//reloadALLchartGlobalFUNC

    }


    {//essential in App dev state, but comment out for Production
      //essential to clear LStorage to start/test App afresh to avoid LStorage keyName change crashes 
      false &&
      <>
      <br />
      <button
      style={{cursor:'pointer', backgroundColor:'lightgreen'}}
      onClick={()=>{
          //localStorage.clear()
          //window.document.location.reload()
          reloadALLchartGlobalFUNC()
      }}
      >Reload All Charts - Global useEffect Rerun</button>
      </>
    }
    


    {true&&
      <ForexHomeBody 
      liveUpdateSECcountdown={liveUpdateSECcountdown}
      currPairArray={currPairArray}
      isShowServerDownAlertCompo = {isShowServerDownAlertCompo}
      setIsShowServerDownAlertCompo = {setIsShowServerDownAlertCompo}
      globalDataStorageObj={globalDataStorageObj}
      setGlobalDataStorageObj={setGlobalDataStorageObj}
      globalInstruTFuseEffectReRunCounter={globalInstruTFuseEffectReRunCounter100}
      reloadALLchartGlobalFUNC={reloadALLchartGlobalFUNC}
      forexORcrypto={forexORcrypto}
      switchPairChartTOindexFUNC={switchPairChartTOindexFUNC}
      liveMT4datetimeSTR={liveMT4datetimeSTR}
      setLiveMT4datetimeSTR={setLiveMT4datetimeSTR}
      setLiveMT4dayOfWeekNUM={setLiveMT4dayOfWeekNUM}
      />
    }


    {//techiman
      <CandlestickChart
      liveUpdateSECcountdown={liveUpdateSECcountdown}
      reloadALLchartGlobalFUNC={reloadALLchartGlobalFUNC}
      liveMT4datetimeSTR={liveMT4datetimeSTR}
      liveMT4dayOfWeekNUM={liveMT4dayOfWeekNUM}
      pageScrollBarWidthSize={pageScrollBarWidthSize}
      globalDataStorageObj={globalDataStorageObj}
      setGlobalDataStorageObj={setGlobalDataStorageObj}
      forexORcrypto={forexORcrypto}
      setForexORcrypto={setForexORcrypto}
      isShowServerDownAlertCompo={isShowServerDownAlertCompo}
      setIsShowServerDownAlertCompo={setIsShowServerDownAlertCompo}
      reloadAllChartsUseEffectCounter={reloadAllChartsUseEffectCounter}
      setReloadAllChartsUseEffectCounter={setReloadAllChartsUseEffectCounter}
      isDontTouchChart={isDontTouchChart}
      setIsDontTouchChart={setIsDontTouchChart}
      isTouchScreenDevice={isTouchScreenDevice}
      setIsTouchScreenDevice={setIsTouchScreenDevice}
      globalMaxWindowOuterHeight={globalMaxWindowOuterHeight}
      setGlobalMaxWindowOuterHeight={setGlobalMaxWindowOuterHeight}
      globalMaxWindowOuterWidth={globalMaxWindowOuterWidth}
      setGlobalMaxWindowOuterWidth={setGlobalMaxWindowOuterWidth}
      tfArray={tfArray}
      setTfArray={setTfArray}
      currIndexArray={currIndexArray}
      setCurrIndexArray={setCurrIndexArray}
      currPairArray={currPairArray}
      setCurrPairArray={setCurrPairArray}
      cryptoCurrIndexArray={cryptoCurrIndexArray}
      setCryptoCurrIndexArray={setCryptoCurrIndexArray}
      cryptoCurrPairArray={cryptoCurrPairArray}
      setCryptoCurrPairArray={setCryptoCurrPairArray}
      defaultPREVInstruTFobjFunc={defaultPREVInstruTFobjFunc}
      defaultChartInstruTFsettingsObj={defaultChartInstruTFsettingsObj}
      chartNumArrays={chartNumArrays}

      //------------------------------------------------------------------------------
      defaultChartInfoObj={defaultChartInfoObj}
      setDefaultChartInfoObj={setDefaultChartInfoObj}
      chartInfoObj1={chartInfoObj1}
      setChartInfoObj1={setChartInfoObj1}
      chartInfoObj2={chartInfoObj2}
      setChartInfoObj2={setChartInfoObj2}
      chartInfoObj3={chartInfoObj3}
      setChartInfoObj3={setChartInfoObj3}


      //------------------------------------------------------------------------------
      globalInstruTFuseEffectReRunCounter1={globalInstruTFuseEffectReRunCounter1}
      globalInstruTFuseEffectReRunCounter2={globalInstruTFuseEffectReRunCounter2}
      globalInstruTFuseEffectReRunCounter3={globalInstruTFuseEffectReRunCounter3}

      navToOnlyCHARTnum1instruTFuseEffectReRunCounter={navToOnlyCHARTnum1instruTFuseEffectReRunCounter}
      
      isShowChartPatternsOnScreen={isShowChartPatternsOnScreen}
      setIsShowChartPatternsOnScreen={setIsShowChartPatternsOnScreen}
      />
    }


    {false&&
      <div
      style={{
        border:'1px solid black',
        height:'10vh'
      }}
      >
       <div>view chart in table - click to show table</div>
       <div>minimize table chart</div>
      </div>
    }


    {//also view rsi line chart button when on currency strength line chart page and vice vers
      false && <div
      style={{// use algo expert footer, and use display:none for <ul> for <1000px until clicked & activated by ternary operator ?
        width:'100%',
        border:'1px solid black',
        display:'flex', justifyContent: 'center', alignItems: 'center'
      }}
      >

        <div id='footerLinks'
        style={{
          width:'80%',
          border:'1px solid white',
          //display:'flex', 
          justifyContent: 'center', //alignItems: 'center',
        }}
        >

          <div style={{width:'100%',}}>
            <div style={{cursor:'pointer',width:'100%', display:'flex', justifyContent: 'center', alignItems: 'center'}}
            onClick={()=>{
              setIsShowFooterChartsLinks(!isShowFooterChartsLinks)
            }}
            >
                <div style={{width:'80%',border:'1px solid black', height:'40px', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <div style={{margin:'0 auto', border:'1px solid white', //width:'98%',
                  fontWeight:'bold'
                  }}>View more... charts</div>
                </div>
                <div style={{width:'20%',border:'1px solid white', height:'40px',
                display:'flex', justifyContent: 'center', alignItems: 'center'
                }}>
                  {isShowFooterChartsLinks ? 
                    <AiOutlineUp style={{width:'20px', height:'20px'}}/> :
                    <AiOutlineDown style={{width:'20px', height:'20px'}}/>
                  }
                </div>
            </div>

            {isShowFooterChartsLinks &&
              <div>
              <ul style={{listStyleType:'none'}}>
                {
                  [
                    ['currency strength meter', '/'],
                    ['currency strength line chart', '/'],
                    ['currency index strength charts', '/'],
                    ['currency pair candlestick strength charts', '/'],
                  ].map((el, el_ind)=>{

                    return(
                      <li style={{cursor:'pointer', borderBottom:'0.1px solid white', minWidth:'10%'}}>
                        {el[0]}
                      </li>
                    )
                  })
                }
              </ul>
            </div>
            }

          </div>

          

        </div> 

      </div>}
      
    
</>
)
}



function getDayOfWeek(datetimeString) {
  try{
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const date = new Date(datetimeString);
      const dayOfWeek = daysOfWeek[date.getUTCDay()];
      return dayOfWeek;
  }
  catch(e){
      return "error"
  }
}



function authDatetimeStrValidityBeforeDrawORedit(datetime){


  try{

  const datetimeStr = datetime
  const dayOfWeek = getDayOfWeek(datetimeStr);
  //console.log({datetimeStr})

  const isAdayOfWeek               = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].includes(dayOfWeek)
  const isDatetimeStrHasWhiteSpace = datetimeStr.includes(" ")
  const isDatetimeStrHasHyphen     = datetimeStr.includes("-")
  const isDatetimeStrHasColon      = datetimeStr.includes(":")

  const datetimeStrSplit = datetimeStr.split(" ")//[ '2024-01-03', '00:00:00' ]
  const date             = datetimeStrSplit[0]//'2024-01-03'
  const time             = datetimeStrSplit[1]//'00:00:00'
  const dateSplit        = date.split("-")//[ '2024', '01', '03' ]
  const timeSplit        = time.split(":")//[ '00', '00', '00' ]
  
  const year  = dateSplit[0]//'2024'
  const month = dateSplit[1]//'01'
  const day   = dateSplit[2]//'03'

  const hour   = timeSplit[0]//'00'
  const minute = timeSplit[1]//'00'
  const second = timeSplit[2]//'00'

  const isDateSplitLen3 = dateSplit.length === 3
  const isTimeSplitLen3 = timeSplit.length === 3

  const isYearLen4  = year.length  === 4//'1970'
  const isMonthLen2 = month.length === 2//'02'
  const isDayLen2   = day.length   === 2//'01'

  const isHourLen2   = hour.length   === 2//'00'
  const isMinuteLen2 = minute.length === 2//'00'
  const isSecondLen2 = second.length === 2//'00'

  const yearNumValue   = Number(year)//1970-9999
  const monthNumValue  = Number(month)//1-12
  const dayNumValue    = Number(day)//1-31

  const hourNumValue   = Number(hour)//0-23
  const minuteNumValue = Number(minute)//0-59
  const secondNumValue = Number(second)//0-59


  const isYearNumValueValid   = yearNumValue  >= 1900//1900-9999
  const isMonthNumValueValid  = monthNumValue >= 1 && monthNumValue <= 12//1-12
  const isDayNumValueValid    = dayNumValue   >= 1 && dayNumValue   <= 31//1-31

  const isHourNumValueValid   = hourNumValue   >= 0 && hourNumValue   <= 23//0-23
  const isMinuteNumValueValid = minuteNumValue >= 0 && minuteNumValue <= 59//0-59
  const isSecondNumValueValid = secondNumValue >= 0 && secondNumValue <= 59//0-59

  const isAllAuthTruthy = (//12 Auths
      isAdayOfWeek &&
      isDatetimeStrHasWhiteSpace &&
      isDatetimeStrHasHyphen &&
      isDatetimeStrHasColon  &&

      isDateSplitLen3 &&
      isTimeSplitLen3 &&

      isYearLen4  &&
      isMonthLen2 &&
      isDayLen2   &&

      isHourLen2   &&
      isMinuteLen2 &&
      isSecondLen2 &&

      isYearNumValueValid   &&
      isMonthNumValueValid  &&
      isDayNumValueValid    &&

      isHourNumValueValid   &&
      isMinuteNumValueValid &&
      isSecondNumValueValid 
  )


  return(isAllAuthTruthy)

  /*return {
      isAdayOfWeek,
      isDatetimeStrHasWhiteSpace,
      isDatetimeStrHasHyphen,
      isDatetimeStrHasColon,

      isYearNumValueValid,isMonthNumValueValid,isDayNumValueValid,

      isHourNumValueValid,isMinuteNumValueValid,isSecondNumValueValid,

      datetimeStrSplit,
      date,
      time,
      dateSplit,
      timeSplit,
      isDateSplitLen3,
      isTimeSplitLen3,

      year,month,day,

      hour,minute,second,

      isAllAuthTruthy
  }
  return true*/
  }
  catch(e) {
      return false
  }
}




export default ForexHome
