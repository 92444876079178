
//NOTE: we had disable App - <React.StrictMode> to prevent useEffect Hook from running twice - in order to prevent the chart from printing twice


import './App.css';

import {HTMLcopyAndPastePages, 
  ForexHome, TFmatrix, TFCurrsHistogram, CurrTFsHistogram, TFcurrsChart, CURRtfsChart,
   CandlestickChart} from './Pages/export_Pages'

import { useState, useEffect } from 'react'

import {BrowserRouter as Router, Routes, Route, } from 'react-router-dom'



function App() { 


  /*
    -------
    TODO's:
    -------
    - determine the number of tfs to show or add starting from 
      the order adding or removing various tfs from htf to ltf
    - determine, for: multiHistMatrix the number of multi hist candle_ids to show
    - determine whether to calculate the total perf of all tfs or a given range number of selected tfs
    - determine whether to add & embed inside col_rows with data, of: wick withstanding perf
      or: create a separate table or histogram for it
  */


  return ( 
  <div className="App">


    {true && <ForexHome/>}

    {false && <HTMLcopyAndPastePages/>}
     

    {/*false && <Router>
      
      <Routes>

        <Route path='/' element={
          <ForexHome/>
        }/>

        <Route path='/charts' element={//ChartSignals
          <CandlestickChart />
        }/>

        <Route path='/privacy' element={
          <PrivacyPolicy/>
        }/>

        <Route path='/page/:page_type' element={
          <ForexHome/>
        }/>

      </Routes>
    
    </Router>*/}


    



  </div>
  );

}

export default App;
