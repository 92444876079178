

const ChartPatterns = ({
    isShowChartPatternsOnScreen, setIsShowChartPatternsOnScreen
}) => {



return (
<div
style={{
    backgroundColor:'red',
    position: 'fixed',
    top:'30px',
    bottom:'0px',
    right:'0px',
    left:'0px',
    zIndex:'20',
    overflowY:'scroll',
    overscrollBehavior:'contain'
}}
>

    <div
    style={{
        backgroundColor:'lightblue',
        position:'sticky',
        top:'0px',
    }}
    >

        <h2>Financial Market Chart Patterns</h2>

        <div>
            <button
            style={{
                backgroundColor:'blue',
                color:'white',
                cursor:'pointer'
            }}
            >SELL patterns</button>
            <button style={{cursor:'pointer'}}>BUY patterns</button>
            <button
            style={{cursor:'pointer'}}
            onClick={() =>{
                setIsShowChartPatternsOnScreen(!isShowChartPatternsOnScreen)
            }}
            >CLOSE X</button>
        </div>
        
    </div>


    <div
    style={{
        backgroundColor:'white'
    }}
    >
        FOUR(4) Main Patterns <br />
        - 3rd Touched Trendline <br />
        - Break & Retest Trendline @ 3rd Touch <br />
        - Head & Shoulder <br />
        - Double & Triple Top/Bottom
        
    </div>


    {//SELL Compo nav scroll ID 
        <div
        style={{
            //backgroundColor:'green',
            //height:'500px'
        }}
        >

            {
                [1, 2, 3, 4, 5, 6].map((el, elInd)=>{

                    return(<div
                    style={{
                        backgroundColor:'green',
                        height:'100px',
                        border:'5px solid white'
                    }}
                    >

                    </div>)

                })
            }

        </div>
    }


    {//BUY Compo nav scroll ID 
        <div
        style={{
            //backgroundColor:'green',
            //height:'500px'
        }}
        >

            {
                [1, 2, 3, 4, 5, 6].map((el, elInd)=>{

                    return(<div
                    style={{
                        backgroundColor:'yellow',
                        height:'100px',
                        border:'5px solid white'
                    }}
                    >

                    </div>)

                })
            }

        </div>
    }
    
</div>
)
}

export default ChartPatterns
