

import '../TFCurrsHistogram_style/TFCurrsHistogram.css';

import {AUD_img,CAD_img,CHF_img,EUR_img,GBP_img,JPY_img,NZD_img,USD_img} from '../../../Assets/export_Images'


import { useState } from 'react' 
import {orderCurrPerf} from '../../../Helpers/orderCurrPerf'
import TFheader from '../TFCurrsHistogram_child_component/TFheader'
import PlayerHeader from '../TFCurrsHistogram_child_component/PlayerHeader'
import Footer from '../TFCurrsHistogram_child_component/Footer'
import Body from '../TFCurrsHistogram_child_component/Body'
import VisibleCurrCompo from '../TFCurrsHistogram_child_component/VisibleCurrCompo'
import PlayBackCompo from '../TFCurrsHistogram_child_component/PlayBackCompo'

import { LuCandlestickChart } from "react-icons/lu";
import { GrClose } from "react-icons/gr";
import { IoEyeSharp } from "react-icons/io5";
import { FaRegHandPointDown } from "react-icons/fa";
import { FaHandPointDown } from "react-icons/fa";
import { FaDownload } from "react-icons/fa6";
import { FaPlay } from "react-icons/fa";

import {TfiControlBackward, TfiControlForward, TfiControlPlay, TfiClose} from 'react-icons/tfi'
import {FiFastForward, FiPlay, FiPause} from 'react-icons/fi'
import { IoMdPause } from "react-icons/io";
import { IoPlayBackSharp, IoPlayForwardSharp } from "react-icons/io5";
import {VscRefresh, VscClose} from 'react-icons/vsc'

import {get_rgb_color_val_by_perc_val} from '../../../Helpers/get_rgb_color_val_by_perc_val'
import { useEffect } from 'react';


const TFCurrsHistogram = ({
  /*
  const csmDATAtypeIDstrObj = {//csmDATAtypeIDstrObj, csmDATAtypeIDstr
    'time ago_current'    : 0,
    'candle ago_current'  : 1,
    'candle ago_previous' : 2
  }
  */

  liveUpdateSECcountdown,

  //----------playBACK-----FUNCs-------------------------------
  isPlaybackDATAloadedAndVALID,
  isINDEXatEndsFUNC,
  playBACKuseEffectNULLIFier,
  playBackMSGstr,
  setPlayBackMSGstr,
  playBackINDEXval,
  setPlayBackINDEXval,
  playBackMAXval,
  setPlayBackMAXval,
  isOnPLAYmode, 
  setIsOnPLAYmode,
  isPlayBackBtnClicked,
  setIsPlayBackBtnClicked,
  playbackArrayPRINTtester,
  updatePlayBackLoopMSGstr,
  onCLOSEplaybackDataArrayRESET,
  onPAUSEdataArrayRESET,
  updateCSMchartByPLAYBACKid,
  getPlayBACKdataFromDB,


  switchPairChartTOindexFUNC,
  currPairArray,
  reloadALLchartGlobalFUNC,
  forexORcrypto,
  csmDATAtypeIDstrObj, csmDATAtypeIDstr,//access data - csmDATAtypeIDstrObj[csmDATAtypeIDstr = 'time ago_current', 'candle ago_current', 'candle ago_previous'] = [0, 1, 2] 
  
  setLocalInstruTFuseEffectReRunCounter,
  isChartDATArefreshedAtleastOnce, setIsChartDATArefreshedAtleastOnce,
  isChartHeaderOptionsSwitchedAtleastOnce,setIsChartHeaderOptionsSwitchedAtleastOnce,

  forexTFdataArray, 
  forexTFdataArray2, 
  get_live_forex_data,
  currentORhistoric,
  setCurrentORhistoric,
  forex_data_length,
  forex_data_index,
  remove_hist_data,
  setIsAllCURRsSelected,
  currArray,
  setCurrArray,
  defaultCurrArray,
  extra_curr,
  switchOnAndInsertCURRbyOrder,
  headerName,

  tf,
  setTF,
  
  isDateGetterClicked
}) => { 


  const [isFirstAlert, setIsFirstAlert] = useState(true)

  const [isViewChartBtnClicked, setIsViewChartBtnClicked] = useState(false)
  const [currsTOviewArray, setCurrsTOviewArray] = useState([])

  //const [isPlayBackBtnClicked, setIsPlayBackBtnClicked] = useState(false)

  //const [playBackINDEXval, setPlayBackINDEXval] = useState(0)
  const [playBackSTEPval, setPlayBackSTEPval] = useState(1)
  

  const [isShowPAUSEforwardICON, setIsShowPAUSEforwardICON]   = useState(false)
  const [isShowPAUSEbackwardICON, setIsShowPAUSEbackwardICON] = useState(false)

  const [isPlayIndexEQUALbeforePLAYmode, setIsPlayIndexEQUALbeforePLAYmode] = useState(false)
  /*useEffect(() => {

   
    

    setInterval(()=>{
      if(localStorage.getItem('current_historic' + forexORcrypto) !== null){
        //console.log('not empty')
        const current_historic_res = localStorage.getItem('current_historic' + forexORcrypto)
        //console.log('current_historic_res : ', current_historic_res)
        if(current_historic_res==='current'){
          get_live_forex_data()
          //console.log('am current')
        }
      }


      //localStorage.getItem('current_historic' + forexORcrypto)
      //get_live_forex_data()
      //console.log('data Updated')
  }, 10000)//update chart every 10sec
  }, [])*/  


  const chartNum = 1

  const [defaultORchartBtnClicked, setDefaultORchartBtnClicked] = useState(false)

  const [isPlayBtnSet, setIsPlayBtnSet] = useState(false)
  const [isPlayBtnClicked, setIsPlayBtnClicked] = useState(false)

  const [playSpeedBtnClicked, setPlaySpeedBtnClicked] = useState(false)
  const [defaultPlaySpeedVal, setDefaultPlaySpeedVal] = useState(1)

  const [timeStepBtnClicked, setTimeStepBtnClicked] = useState(false)

  const [timeBtnClicked, setTimeBtnClicked] = useState(false)

  const [loadDataBtnClicked, setLoadDataBtnClicked] = useState(false)

  const [prevPlayEpoch, setPrevPlayEpoch] = useState(Date.now())



  const curr_image_loc = [EUR_img, GBP_img, AUD_img, NZD_img, USD_img, CAD_img, CHF_img, JPY_img]


  const curr_image_index_array = ["EUR","GBP","AUD","NZD","USD","CAD","CHF","JPY"]


  const curr_name_str_array = ["EUR","GBP","AUD","NZD","USD","CAD","CHF","JPY"]

  const default_tf = ["m5","m15","m30","h1","h4","d1","w1","mn"]

  const extra_tf = ["m45","h2","h6","h8","h12"]

  const tf_array = (
      ["m5","m15","m30","m45","h1","h2","h4","h6","h8","h12","d1","w1","mn"]
    )
  const tf_array_def = (
    ["5 minutes","15 minutes","30 minutes","45 minutes","1 hour","2 hours","4 hours","6 hours","8 hours","12 hours","1 day","1 week","a month"]
  )

  const tf_def = tf_array_def[tf_array.indexOf(tf)]



  //-----------------------------------------------------------------------------
  const [currIconBtnClicked, setCurrIconBtnClicked] = useState(false)
  /*const [isAllCURRsSelected, setIsAllCURRsSelected] = useState(false)


  const [currArray, setCurrArray] = useState(
      ["EUR","GBP","AUD","NZD","USD","CAD","CHF","JPY"]
  )


  const [defaultCurrArray, setDefaultCurrArray] = useState(["EUR","GBP","AUD","NZD"])
  const extra_curr = ["USD","CAD","CHF","JPY"]


  const switchOnAndInsertCURRbyOrder = (curr_to_insert)=>{
      const currIndexArray = ["EUR","GBP","AUD","NZD","USD","CAD","CHF","JPY"]
      const insertedCURRarray = [...currArray, curr_to_insert]

      const orderedCURRarray = currIndexArray.filter((curr_str, curr_str_index)=>{
          return insertedCURRarray.includes(curr_str) === true
      })  

      setCurrArray(orderedCURRarray)
  }
  //------------------------------------------------------------------------------*/


  //alert(window.innerWidth)
  const MAX_PHONE_WIDTH = 500

  const [playBACKuseEffectTimeout, setPlayBACKuseEffectTimeout] = useState(Date.now())


  useEffect(() => {

    if(
      isPlayBackBtnClicked &&
      isOnPLAYmode &&
      isPlaybackDATAloadedAndVALID(chartNum) &&
      (isShowPAUSEforwardICON || isShowPAUSEbackwardICON)
    ){

      const timeoutSEC = 1000 * 1.3

      const isPLAYforward  = isShowPAUSEforwardICON
      const isPLAYbackward = isShowPAUSEbackwardICON

      //-------------------------------------------------
      /*console.log({
        isOnPLAYmode, timeoutSEC, isPLAYforward, isPLAYbackward, step:playBackSTEPval, index:playBackINDEXval
      })*/
      //playbackArrayPRINTtester(1)

      //updateCSMchartByPLAYBACKid = (chartNum=1, step=1, isForward=true)
      updateCSMchartByPLAYBACKid(
        playBackINDEXval,
        chartNum,
        playBackSTEPval,
        isPLAYforward,
        isPlayIndexEQUALbeforePLAYmode
      )
      //-------------------------------------------------

      if(isPlayIndexEQUALbeforePLAYmode){
        setIsPlayIndexEQUALbeforePLAYmode(false)
      }

      setTimeout(() => {
        setPlayBACKuseEffectTimeout(Date.now())
      }, timeoutSEC)
      
    }

  }, [playBACKuseEffectTimeout])




  useEffect(() => {

    if(
      isPlayBackBtnClicked &&
      isOnPLAYmode 
    ){
      //--------------------------------
      //Halt all plays to LOAD data from db
      setIsOnPLAYmode(false)
      setIsShowPAUSEforwardICON(false) 
      setIsShowPAUSEbackwardICON(false)
      //-------------------------------*/
    }

  }, [playBACKuseEffectNULLIFier])


  

  return (
    <div id={`${headerName}`} //className="csmBodyWidthResizer"
    className="csmBodyWidthResizer homeBodyMarginTop"
      style={{
        margin:'0 auto',
        /*backgroundColor:'white',
        borderRadius : '15px', 
        //width: `${window.innerWidth > 470 ? '42vh' : '85%'}`,
        height:'65vh',
        //flexShrink:'0',
        marginTop:'30px',
        //marginRight:`${forex_data_index===forex_data_length-1 ? '2vh' : ''}`,
        //marginLeft:`${forex_data_index===0 ? '1vh' : '1vh'}`,
        marginBottom:'1vh',
        position:'relative'*/

        backgroundColor:'white',
        borderRadius : '15px', 
        //width: `${window.innerWidth > 470 ? '90%' : '90%'}`,
        /*width: `${
          (currentORhistoric === 'current') ||  (forex_data_length===1)
          ? '100%' : 
          (window.innerWidth > (window.innerHeight + (window.innerWidth*0.3))&& forex_data_length===2) ? '48.5%' :  
          window.innerWidth > MAX_PHONE_WIDTH ? '45vh' : '90%'
        }`,*/
        height:'70vh',
        flexShrink:'0',
        //marginTop:'30px',
        /*marginRight:`${
          forex_data_index===forex_data_length-1 && forex_data_length > 1
          && currentORhistoric === 'historic'
          ? '2vh' : ''}`,
        marginLeft:`${
          forex_data_length > 1 && currentORhistoric === 'historic'
          ? '1vh' : ''}`,*/
        marginBottom:'30px',
        position:'relative',
        boxShadow:`rgba(1, 100, 111, 0.3) 0px 7px 29px 0px`,
      }}
    >



      <div style={{height:'1%'}}></div>


      <TFheader 
        forexORcrypto={forexORcrypto}
        csmDATAtypeIDstr={csmDATAtypeIDstr}
        isChartHeaderOptionsSwitchedAtleastOnce={isChartHeaderOptionsSwitchedAtleastOnce}
        setIsChartHeaderOptionsSwitchedAtleastOnce={setIsChartHeaderOptionsSwitchedAtleastOnce}

        setTF={setTF} 
        tf={tf}
        default_tf={default_tf}
        extra_tf={extra_tf}
      />  


      {isPlayBackBtnClicked &&
        <VisibleCurrCompo
          isPlayBackBtnClicked={isPlayBackBtnClicked}
          currIconBtnClicked={currIconBtnClicked}
          setCurrIconBtnClicked={setCurrIconBtnClicked}
          forex_data_index={forex_data_index}
          setCurrArray={setCurrArray}
          currArray={currArray}
          setIsAllCURRsSelected={setIsAllCURRsSelected}
          isDateGetterClicked={isDateGetterClicked}
          defaultCurrArray={defaultCurrArray}
          switchOnAndInsertCURRbyOrder={switchOnAndInsertCURRbyOrder}
          extra_curr={extra_curr}
          forexORcrypto={forexORcrypto}
        />
      }
 
 
      {
          isViewChartBtnClicked===false && isPlayBackBtnClicked===false

          ?
          
          <PlayerHeader 
          playBackMSGstr={playBackMSGstr}
          setPlayBackMSGstr={setPlayBackMSGstr}
          getPlayBACKdataFromDB={getPlayBACKdataFromDB}
          setIsViewChartBtnClicked={setIsViewChartBtnClicked}
          isPlayBackBtnClicked={isPlayBackBtnClicked}
          setIsPlayBackBtnClicked={setIsPlayBackBtnClicked}

          forexORcrypto={forexORcrypto}
          setLocalInstruTFuseEffectReRunCounter={setLocalInstruTFuseEffectReRunCounter}
          isChartDATArefreshedAtleastOnce={isChartDATArefreshedAtleastOnce}
          setIsChartDATArefreshedAtleastOnce={setIsChartDATArefreshedAtleastOnce}
          
          setDefaultORchartBtnClicked={setDefaultORchartBtnClicked}
          isPlayBtnSet={isPlayBtnSet} 
          isPlayBtnClicked={isPlayBtnClicked}
          setIsPlayBtnClicked={setIsPlayBtnClicked}
          setPlaySpeedBtnClicked={setPlaySpeedBtnClicked}
          playSpeedBtnClicked={playSpeedBtnClicked}
          defaultPlaySpeedVal={defaultPlaySpeedVal}
          setDefaultPlaySpeedVal={setDefaultPlaySpeedVal}
          setIsPlayBtnSet={setIsPlayBtnSet}
          setTimeBtnClicked={setTimeBtnClicked}
          setTimeStepBtnClicked={setTimeStepBtnClicked}
          setLoadDataBtnClicked={setLoadDataBtnClicked}
          get_live_forex_data={get_live_forex_data}

          forex_data_length={forex_data_length}
          forex_data_index={forex_data_index}
          remove_hist_data={remove_hist_data}

          setCurrentORhistoric={setCurrentORhistoric}
          setIsAllCURRsSelected={setIsAllCURRsSelected}
          setCurrArray={setCurrArray}
          currArray={currArray}
          switchOnAndInsertCURRbyOrder={switchOnAndInsertCURRbyOrder}
          extra_curr={extra_curr}
          defaultCurrArray={defaultCurrArray}

          headerName={headerName}
          
          isDateGetterClicked={isDateGetterClicked}
        />

        :

        isPlayBackBtnClicked && isViewChartBtnClicked===false

        ?

        <PlayBackCompo
          
          setCurrIconBtnClicked={setCurrIconBtnClicked}
          setIsViewChartBtnClicked={setIsViewChartBtnClicked}

          chartNum={chartNum}
          isPlayBackBtnClicked={isPlayBackBtnClicked}
          setIsPlayBackBtnClicked={setIsPlayBackBtnClicked}
          setPlayBackSTEPval={setPlayBackSTEPval}
          setPlayBackINDEXval={setPlayBackINDEXval}
          setIsShowPAUSEbackwardICON={setIsShowPAUSEbackwardICON}
          setIsShowPAUSEforwardICON={setIsShowPAUSEforwardICON}
          setIsOnPLAYmode={setIsOnPLAYmode}
          onCLOSEplaybackDataArrayRESET={onCLOSEplaybackDataArrayRESET}
          playBackMSGstr={playBackMSGstr}
          updateCSMchartByPLAYBACKid={updateCSMchartByPLAYBACKid}
          isPlaybackDATAloadedAndVALID={isPlaybackDATAloadedAndVALID}
          playBackINDEXval={playBackINDEXval}
          playBackMAXval={playBackMAXval}
          playBackSTEPval={playBackSTEPval}
          isShowPAUSEforwardICON={isShowPAUSEforwardICON}
          setPlayBACKuseEffectTimeout={setPlayBACKuseEffectTimeout}
          isShowPAUSEbackwardICON={isShowPAUSEbackwardICON}
          setIsPlayIndexEQUALbeforePLAYmode={setIsPlayIndexEQUALbeforePLAYmode}
          isINDEXatEndsFUNC={isINDEXatEndsFUNC}
          isOnPLAYmode={isOnPLAYmode}
          prevPlayEpoch={prevPlayEpoch}
          setPrevPlayEpoch={setPrevPlayEpoch}
        />

        :

        <div
        style={{
          margin:'0 auto',
          border:'0.5px solid gray',
          borderRadius: '5px',
          height:'4vh',
          //paddingTop: '7px',
          //paddingBottom: '7px',
          width: `95%`,
          //marginLeft:'7%',
          display:'flex', //
          justifyContent:'space-around', 
          alignItems:'center',
          position:'relative',
          //boxShadow:`${isPlayBtnSet=== true ? 'rgba(1,100,111,0.1) 0px 7px 29px 0px' : ''}`,
          zIndex: '6'
        }}
        >
          


          <div
          style={{
            width:'100%',
            fontSize:'12px',
            display:'flex', //
            justifyContent:'center', 
            alignItems:'center',
            textAlign:'center',
            //padding:'5px'
          }}
          >

            {
              currsTOviewArray.length===0
              ?
              <div>
                <b style={{color:'gray'}}>Select</b>
                {` 1 or 2 currency/s  `} <b style={{color:'green'}}>below</b> <span>
                    <FaRegHandPointDown style={{color:''}}/>
                  </span> to <b style={{color:'gray'}}>View on candlestick chart</b>
              </div>
              :
              <div>
                View - <b style={{color:''}}> {`${currsTOviewArray[0]} + ${currsTOviewArray.length<2 ? '____' : currsTOviewArray[1]
              } = ${currsTOviewArray.length<2 ? currsTOviewArray[0] : currsTOviewArray[0] + currsTOviewArray[1]
              } ${currsTOviewArray.length<2 ? 'index' : 'pair'}`} </b> - candlestick Chart
              </div>
            }

          </div>


          {currsTOviewArray.length > 0 &&
            <div
            style={{
              height:'100%',
              //borderLeft:'0.5px solid gray',
              width:'30%',
              display:'flex', //
              justifyContent:'center', 
              alignItems:'center',
              //backgroundColor:'rgba(0,0,255,0.5)',
              //color:'white',
              cursor:'pointer',

              backgroundColor: currsTOviewArray.length===2 ? 'lightgreen' : 'rgba(0,0,255,0.5)',
              color: currsTOviewArray.length===2 ? 'black' : 'white',
              fontWeight: currsTOviewArray.length===2 ? 'bold' : 'bold',

              position: 'relative',
            }}

            onClick={()=>{

              const csmNAVobj = {
                candleORrsi : 'candle',
                indexORpair : currsTOviewArray.length===1 ? 'index' : 'pair',
                instruName  : currsTOviewArray.length===1 ? currsTOviewArray[0] : currsTOviewArray[0] + currsTOviewArray[1],//'USD',
                //tf          : tf//'h1'
                tf : tf==='m45' ? 'm30' : tf==='h2' ? 'h1' : ['h6', 'h8', 'h12'].includes(tf) ? 'h4' : tf
              }

              switchPairChartTOindexFUNC(
                'csm',
                csmNAVobj
              )

              setIsViewChartBtnClicked(!isViewChartBtnClicked)
              setCurrsTOviewArray([])

              //--------------------------------
              //Halt all plays to LOAD data from db
              setIsOnPLAYmode(false)
              setIsShowPAUSEforwardICON(false) 
              setIsShowPAUSEbackwardICON(false)
              //-------------------------------*/

              //nav to 1st chart 1
              const targetDiv = document.getElementById(`adCompoParentId-${1}`);
              targetDiv.scrollIntoView({
                  behavior: 'smooth' // You can change this to 'auto' for immediate scrolling without smooth animation.
              })
            }}
            >
              View

              {
                <IoEyeSharp className="coin"
                style={{
                  //color:'white',
                  color: currsTOviewArray.length===2 ? 'black' : 'white',
                  cursor: 'pointer',
                  marginLeft:'5px'
                }}
                />
              }

              {true && 
              <span style={{
                  color:'gray',
                  fontWeight:'bold',
                  fontSize:'10px', 
                  marginLeft:'5px',
                  position:'absolute',
                  top:'110%',
                  textAlign:'center'
              }}>{`Click to View Chart`}</span>
              }
            </div>
          }


          {
            <div
            style={{
              borderLeft:'0.5px solid gray',
              width:'10%',
              height:'100%',
              display:'flex', //
              justifyContent:'center', 
              alignItems:'center',
              cursor:'pointer',
              backgroundColor:'rgba(255, 0,0,0.8)',
            }}

            onClick={()=>{
              setIsViewChartBtnClicked(!isViewChartBtnClicked)
              setCurrsTOviewArray([])
            }}
            >
              {
                <GrClose
                style={{
                    height:'100%',
                    //borderRadius:'50%',
                    //padding:'5px',
                    color:'white',
                    //marginRight:'5px',
                    //position:'absolute',
                    //top:'-10%'
                }}

                
                />
              }
            </div>
          }

        </div>
      }




      {//chart-icon
        <div
        style={{
          position:'absolute',
          right:'5.7%',
          top:'40%',
          zIndex:'5'
        }}

        onClick={()=>{
          setIsViewChartBtnClicked(!isViewChartBtnClicked)
          //setIsPlayBackBtnClicked(false)
          setCurrsTOviewArray([])
          setCurrIconBtnClicked(false)
        }}
        >

          {
            <LuCandlestickChart
            style={{
              width:'2vh',
              height:'2vh',
              //transform: 'rotate(90deg)',
              backgroundColor: 'white',
              borderRadius: '5px',
              padding: '0.5vh',
              boxShadow:`${ `rgba(1, 100, 111, 0.5) 0px 7px 29px 0px` }`,
              color:'rgba(128,128,128,1)',
              position:'relative',
              zIndex: '5',
              cursor: 'pointer'
            }}
            />
          }
          
          <div
          style={{
              fontSize:'0.7em',
              color: 'gray',
              position: 'absolute',
              bottom:`-1.2em`,
              //right:'0.01em'
              right:'-1px'
          }}
          >
              {'Chart'}
          </div>
        </div>
      }



      {//chart-icon
      false &&
        //isPlayBackBtnClicked &&

        <div
        style={{
          position:'absolute',
          right:'5.7%',
          top:'26%',
          zIndex:'5'
        }}

        onClick={()=>{
          //alert('hey its me')

          //--------------------------------
          //Halt all plays to LOAD data from db
          setIsOnPLAYmode(false)
          setIsShowPAUSEforwardICON(false) 
          setIsShowPAUSEbackwardICON(false)
          //-------------------------------*/

          getPlayBACKdataFromDB(chartNum)
          setCurrIconBtnClicked(false)
        }}
        >

          {
            <VscRefresh
            style={{
              width:'2vh',
              height:'2vh',
              transform: 'rotate(90deg)',
              backgroundColor: 'white',
              borderRadius: '50%',
              padding: '0.5vh',
              boxShadow:`${ `rgba(1, 100, 111, 0.5) 0px 7px 29px 0px` }`,
              color:'rgba(128,128,128,1)',
              position:'relative',
              zIndex: '5',
              cursor: 'pointer'
            }}
            />
          }
          
          <div
            style={{
                fontSize:'0.7em',
                color: 'gray',
                position: 'absolute',
                bottom:`-1.2em`,
                right:'-0.5em'
            }} 
            >
                Refresh
            </div>
            
        </div>
      }


      <Body 
        currPairArray={currPairArray}
        isViewChartBtnClicked={isViewChartBtnClicked}
        currsTOviewArray={currsTOviewArray}
        setCurrsTOviewArray={setCurrsTOviewArray}

        forexORcrypto={forexORcrypto}
        tf={tf}
        isPlayBtnSet={isPlayBtnSet}
        curr_name_str_array={curr_name_str_array}
        forexTFdataArray={forexTFdataArray}
        forexTFdataArray2={forexTFdataArray2}
        orderCurrPerf={orderCurrPerf}
        get_rgb_color_val_by_perc_val={get_rgb_color_val_by_perc_val}
        curr_image_loc={curr_image_loc}
        curr_image_index_array={curr_image_index_array}

        currArray={currArray}
        setCurrArray={setCurrArray}

        csmDATAtypeIDstrObj={csmDATAtypeIDstrObj}
        csmDATAtypeIDstr={csmDATAtypeIDstr}
      />
      




      

      <Footer 
        liveUpdateSECcountdown={liveUpdateSECcountdown}
        reloadALLchartGlobalFUNC={reloadALLchartGlobalFUNC}
        csmDATAtypeIDstrObj={csmDATAtypeIDstrObj}
        csmDATAtypeIDstr={csmDATAtypeIDstr}

        forexORcrypto={forexORcrypto}
        tf_def={tf_def}
        currentORhistoric={currentORhistoric}
        datetime={csmDATAtypeIDstr.includes('previous') ? forexTFdataArray2.all_datetime[tf][ csmDATAtypeIDstrObj[csmDATAtypeIDstr] - 1] : forexTFdataArray2.str_datetime}
        //datetime={forexTFdataArray2.str_datetime}//'1970-01-01 00:00:00' = forexTFdataArray2.all_datetime[tf][csmDATAtypeIDstrObj[csmDATAtypeIDstr]]
        day_of_week={forexTFdataArray2.day_of_week}
        forex_data_length={forex_data_length}
        forex_data_index={forex_data_index}
      />


        
    </div>
    )
}

export default TFCurrsHistogram
