




const Body = ({

    /*
    const csmDATAtypeIDstrObj = {//csmDATAtypeIDstrObj, csmDATAtypeIDstr
        'time ago_current'    : 0,
        'candle ago_current'  : 1,
        'candle ago_previous' : 2
    }
    */
    currPairArray,
    csmDATAtypeIDstrObj, csmDATAtypeIDstr,//access data - csmDATAtypeIDstrObj[csmDATAtypeIDstr = 'time ago_current', 'candle ago_current', 'candle ago_previous'] = [0, 1, 2]  

    isViewChartBtnClicked,
    currsTOviewArray, setCurrsTOviewArray,

    tf,
    isPlayBtnSet,
    curr_name_str_array,
    forexTFdataArray,
    forexTFdataArray2,
    orderCurrPerf,
    get_rgb_color_val_by_perc_val,
    curr_image_loc,
    curr_image_index_array,

    currArray,
    setCurrArray,
}) => {


    ///console.log('curr_array : ', currArray)

return (
<div className="mainContainer "//HistogramBarClass
style={{
    marginBottom: '10%',
    //border: `1px solid black`,
    width: "90%",
    height: `${"62%"}`,
    margin:'0px auto',
    display:``,
    padding: '1%',
}}
>



    <div className="barParentContainer"
        style={{
        //border: '1px solid black',
        backgroundColor:`white`,
        height:`80%` ,
        width:`` ,
        position:`relative`,
        //borderRadius : '50%', 
        }}
    >

        <div
        style={{
            position:`absolute` ,
            width:`100%`,
            height:`87%`,
            bottom:`1%` 
        }}
        >

        <div className="barContainer"
            style={{
            //border: `1px solid black`,
            display: `flex` ,
            //position: `relative',
            height:`100%` ,
            width: `100%` ,
            justifyContent:'space-around',
            //alignItems:'end' ,//this does not work in all browsers
            }}
        >
            {
            curr_name_str_array.map((curr_name, index)=>{

                let currs_perc_perf_obj;

                //const tf_obj = forexTFdataArray[tf]
                //const tf_obj = JSON.parse(forexTFdataArray2[tf])
                const tf_obj = forexTFdataArray2[tf]
                //console.log('tf_obj2 : ', tf_obj2)

                //console.log(`${tf}_live_data : `, tf_obj)
                //const tf_candle_id_obj = tf_obj[candleId]
                //console.log(`tf_candle_id_obj : `, tf_candle_id_obj)

                //obj key to grab: candle_body_perc_perf_scaled_curr_obj
                currs_perc_perf_obj = tf_obj.curr_perf_scaled
                //console.log(`${tf}_currs_perc_perf_obj : `, currs_perc_perf_obj)

                const dataTypeID = csmDATAtypeIDstrObj[csmDATAtypeIDstr]//0, 1, 2
                const [key_array, val_array] = orderCurrPerf(currs_perc_perf_obj, dataTypeID)//[csmDATAtypeIDstrObj[csmDATAtypeIDstr]]

                const curr_name_str_array=key_array
                const curr_val_array=val_array
                //console.log(`curr_name_str_array : `, curr_name_str_array)


                return(
                <div key={index}
                style={{height:'100%', width:'100%'}}
                >

                    <div className="HistogramBarClass" 
                    style={{
                        //border: `1px solid red`,
                        height:`${100-(curr_val_array[index])}%`,
                        width:'100%',
                        position: `relative` ,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    ></div>

                    <div className="HistogramBarClass" 
                        
                        style={{
                        //height:`${curr_val_array[index] + 1}%`,
                        height:`${curr_val_array[index] +1}%`,
                        width:`100%`,
                        //border: `1px solid black`,
                        position: `relative` ,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        }}
                    >

                        <div
                        style={{
                            color: `${currArray.includes(curr_name_str_array[index]) ? 'rgba(128,128,128,1)' : 'rgba(128,128,128,0.5)'}`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            //border: `1px solid black`,
                            fontSize:'70%',
                            borderRadius:'50%',
                            padding: '2%',
                            position:`absolute` ,
                            width:`20px` ,
                            height:`20px`,
                            top:`-28px` ,
                            right:``,
                            boxShadow:`${
                                currArray.includes(curr_name_str_array[index]) ?
                                get_rgb_color_val_by_perc_val(curr_val_array[index]).replace('rgb', 'rgba').replace(')', ', 0.5)') + ' 0px 2px 10px 0px'
                                : 'rgba(128, 128, 128, 0.6) 0px 7px 29px 0px'
                            }`,
                            //boxShadow:`${'rgba(128, 128, 128, 0.2)'} 0px 7px 29px 0px`,
                            //boxShadow:'rgba(100, 100, 111, 0.5) 0px 7px 29px 0px',
                        }}
                        >
                        {curr_val_array[index]}
                        </div>


                        <div className="currBar" 
                        style={{
                            backgroundColor: `${currArray.includes(curr_name_str_array[index]) ? get_rgb_color_val_by_perc_val(curr_val_array[index]) : 'rgba(128, 128, 128, 0.2)'}`,
                            height:'100%',
                            width: '30%',
                            //border: `1px solid black`,
                            borderTopRightRadius:'15px',
                            borderTopLeftRadius:'15px',
                            borderBottomRightRadius:'15px',
                            borderBottomLeftRadius:'15px',
                            textAlign: 'center',
                            boxShadow:`rgba(1, 100, 111, 0.3) 0px 7px 29px 0px`,
                            //width: '10%',
                        }}
                        >
                        
                        </div>

                    </div>

                </div>
                )
            })
            }
        </div>

        </div>

    </div>
    



    
    <div className="currNameContainer"
        style={{
        //border:'1px solid black',
        marginTop: "12px",
        backgroundColor:`white`,
        height:'23%',
        display:'flex',
        justifyContent:'space-around',
        }}
    >

    {
    curr_name_str_array.map((curr_name, index)=>{


    let currs_perc_perf_obj;

    //const tf_obj = forexTFdataArray[tf]
    //const tf_obj = JSON.parse(forexTFdataArray2[tf])
    const tf_obj = forexTFdataArray2[tf]
    //console.log('tf_obj2 : ', tf_obj2)

    //console.log(`${tf}_live_data : `, tf_obj)
    //const tf_candle_id_obj = tf_obj[candleId]
    //console.log(`tf_candle_id_obj : `, tf_candle_id_obj)

    //obj key to grab: candle_body_perc_perf_scaled_curr_obj
    currs_perc_perf_obj = tf_obj.curr_perf_scaled
    //console.log(`${tf}_currs_perc_perf_obj : `, currs_perc_perf_obj)

    const dataTypeID = csmDATAtypeIDstrObj[csmDATAtypeIDstr]//0, 1, 2
    const [key_array, val_array] = orderCurrPerf(currs_perc_perf_obj, dataTypeID)//[csmDATAtypeIDstrObj[csmDATAtypeIDstr]]

    const curr_name_str_array=key_array
    const curr_val_array=val_array
    //console.log(`curr_name_str_array : `, curr_name_str_array)

    return(
        <div className={`currName ${
            (isViewChartBtnClicked && currsTOviewArray.length === 0) ||
            (isViewChartBtnClicked && currsTOviewArray[0]===curr_name_str_array[index]) ||
            (isViewChartBtnClicked && currsTOviewArray[1]===curr_name_str_array[index]) 
            ? 'coin2' /*'coin2'*/ : ''}`} key={index}
        style={{
            backgroundColor:isViewChartBtnClicked ? 'white' : '',
            border: (isViewChartBtnClicked && currsTOviewArray.length === 0) ||
            (isViewChartBtnClicked && currsTOviewArray[0]===curr_name_str_array[index]) ||
            (isViewChartBtnClicked && currsTOviewArray[1]===curr_name_str_array[index]) 
            ? `0.5px solid gray` : '',
            width:'12%',
            height:'100%',

            display:'flex' ,
            flexDirection:'column',
            flexWrap:'no-wrap',
            justifyContent:'space-around',
            alignItems: 'space-between',

            boxShadow: //(isViewChartBtnClicked && currsTOviewArray.length === 0) ||
            (isViewChartBtnClicked && currsTOviewArray[0]===curr_name_str_array[index]) ||
            (isViewChartBtnClicked && currsTOviewArray[1]===curr_name_str_array[index]) 
            ? `rgba(1, 0, 255, 0.4) 0px 7px 29px 0px` : `rgba(1, 100, 111, 0.2) 0px 7px 29px 0px`,

            borderRadius : '15px',

            position: 'relative',
            fontWeight: 'bold',
            cursor: isViewChartBtnClicked ? 'pointer' : '',
            zIndex : //(isViewChartBtnClicked && currsTOviewArray.length === 0) ||
            (isViewChartBtnClicked && currsTOviewArray[0]===curr_name_str_array[index]) ||
            (isViewChartBtnClicked && currsTOviewArray[1]===curr_name_str_array[index]) 
            ? '2' : ''
        }}

        onClick={()=>{

            if(isViewChartBtnClicked){
                //currsTOviewArray
                if(currsTOviewArray.includes(curr_name_str_array[index])){
                setCurrsTOviewArray([
                    ...(currsTOviewArray.filter((el, elInd)=>{
                        return el !== curr_name_str_array[index]
                    }))
                ])
                }
                else if(currsTOviewArray.length === 0){

                    setCurrsTOviewArray([
                        curr_name_str_array[index]
                    ])
                }
                else if(currsTOviewArray.length === 1){

                    //currPairArray
                    //console.log(currPairArray)
                    /*
                    [
                        'EURGBP', 'EURAUD', 'EURNZD', 'EURUSD', 'EURCAD', 'EURCHF', 'EURJPY', 
                        'GBPAUD', 'GBPNZD', 'GBPUSD', 'GBPCAD', 'GBPCHF', 'GBPJPY', 
                        'AUDNZD', 'AUDUSD', 'AUDCAD', 'AUDCHF', 'AUDJPY', 
                        'NZDUSD', 'NZDCAD', 'NZDCHF', 'NZDJPY', 
                        'USDCAD', 'USDCHF', 'USDJPY', 
                        'CADCHF', 'CADJPY', 
                        'CHFJPY'
                    ]
                    */

                    let curr1 = currsTOviewArray[0]
                    let curr2 = curr_name_str_array[index]

                    const curr = (currPairArray.filter((el, elInd)=>{
                        return(el.includes(curr1) && el.includes(curr2))
                    }))[0]

                    curr1 = curr.slice(0,3)
                    curr2 = curr.slice(3)

                    setCurrsTOviewArray([
                        curr1, curr2
                    ])
                }
                else if(currsTOviewArray.length === 2){

                    //currPairArray
                    //console.log(currPairArray)
                    /*
                    [
                        'EURGBP', 'EURAUD', 'EURNZD', 'EURUSD', 'EURCAD', 'EURCHF', 'EURJPY', 
                        'GBPAUD', 'GBPNZD', 'GBPUSD', 'GBPCAD', 'GBPCHF', 'GBPJPY', 
                        'AUDNZD', 'AUDUSD', 'AUDCAD', 'AUDCHF', 'AUDJPY', 
                        'NZDUSD', 'NZDCAD', 'NZDCHF', 'NZDJPY', 
                        'USDCAD', 'USDCHF', 'USDJPY', 
                        'CADCHF', 'CADJPY', 
                        'CHFJPY'
                    ]
                    */

                    let curr1 = currsTOviewArray[0]
                    let curr2 = curr_name_str_array[index]

                    const curr = (currPairArray.filter((el, elInd)=>{
                        return(el.includes(curr1) && el.includes(curr2))
                    }))[0]

                    curr1 = curr.slice(0,3)
                    curr2 = curr.slice(3)

                    setCurrsTOviewArray([
                        curr1, curr2
                    ])
                }
            }

        }}

        >
        

        <div
        style={{
            //border: `1px solid black`,
            position: 'relative',
            display:'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
        >

        <div className=""
            style={{
            height:'20px',
            width:'20px',
            backgroundImage: `url(${curr_image_loc[curr_image_index_array.indexOf(curr_name_str_array[index])]})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            borderRadius: '50%',
            margin: '0px auto',
            //border: '1px solid black',
            //position: 'absolute',
            //bottom: '100%',
            boxShadow:`rgba(1, 100, 111, 0.35) 0px 7px 29px 0px`,
            position: 'relative',
            //backgroundColor:'black',
            }}
        >
        </div>

        
        {
                currArray.includes(curr_name_str_array[index]) === false &&

        <div className=""
            style={{
            height:'20px',
            width:'20px',
            backgroundColor:'rgba(128, 128, 128, 0.8)',
            //backgroundImage: `url(${curr_image_loc[curr_image_index_array.indexOf(curr_name_str_array[index])]})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            borderRadius: '50%',
            margin: '0px auto',
            //border: '1px solid black',
            //position: 'absolute',
            //bottom: '100%',
            boxShadow:`rgba(1, 100, 111, 0.35) 0px 7px 29px 0px`,
            position: 'absolute',
            //backgroundColor:'black',
            top:'0px'
            }}
        >

        </div>

        }


        </div>


        <div
            style={{
            //margin: '0px auto',
            textAlign: 'center',
            fontSize: '12px',
            //border: '1px solid black',
            color: `${currArray.includes(curr_name_str_array[index]) ? '' : 'rgba(128, 128, 128, 0.3)'}`
            }}
        >
            {curr_name_str_array[index]}
        </div>
        

        </div>
    )
    })
    }
    
    </div>

</div>
)
}

export default Body
