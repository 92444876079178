
/*
candle_body_perc_perf_curr_curr_perf_res_obj :  {
  EUR: 0.6350688335067258,
  GBP: -0.8806635891058958,
  AUD: 2.7670591304129775,
  NZD: 0.19263094393502475,
  USD: -0.8559197172329653,
  CAD: 0.6285576115620296,
  CHF: -0.030794698721225033,
  JPY: -2.4559385143566717
}
candle_wick_perc_perf_curr_curr_perf_res_obj :  {
  EUR: -0.8821871411069431,
  GBP: -0.886082446489391,
  AUD: -0.37709069554434343,
  NZD: -1.4652251637188016,
  USD: -0.9904063465738413,
  CAD: -0.16736475150525498,
  CHF: -0.3436695486080059,
  JPY: -0.3248157908398746
}
candle_body_pip_moved_curr_curr_perf_res_obj :  {
  EUR: 79.89999999999876,
  GBP: -162.50000000000315,
  AUD: 276.8999999999993,
  NZD: 44.49999999999932,
  USD: -66.30000000000292,
  CAD: 94.50000000000159,
  CHF: 21.700000000002937,
  JPY: -288.6999999999958
}
candle_wick_pip_moved_curr_curr_perf_res_obj :  {
  EUR: -105.80000000000038,
  GBP: -142.600000000001,
  AUD: -37.90000000000049,
  NZD: -121.59999999999803,
  USD: -107.20000000000076,
  CAD: -18.299999999999805,
  CHF: -27.300000000001788,
  JPY: -43.29999999999927
}
*/ 
/*
const curr_perf_res_object =  {
    EUR: -105.80000000000038,
    GBP: -142.600000000001,
    AUD: -37.90000000000049,
    NZD: -121.59999999999803,
    USD: -107.20000000000076,
    CAD: -18.299999999999805,
    CHF: -27.300000000001788,
    JPY: -43.29999999999927
}

const totalTFcurrPerfSumObj = {
      AUD:251,
      CAD:528,
      CHF:298,
      EUR:395,
      GBP:541,
      JPY:210,
      NZD:253,
      USD:582
    }


console.log('curr_perf_result_scaled : ', curr_perf_result_scaled(curr_perf_res_object))*/

//console.log('curr_perf_result_scaled : ', curr_perf_result_scaled())


function curr_perf_result_scaled(curr_perf_res_obj){

    //let curr_perf_res_obj = curr_perf_res_object

    /*const curr_perf_res_obj = {
        AUD:251,
        CAD:528,
        CHF:298,
        EUR:395,
        GBP:541,
        JPY:210,
        NZD:253,
        USD:582
    }*/

    let val_arr = []
    let min_val;
    let max_val;

    for(let key in curr_perf_res_obj) {
        //console.log(key, ':', curr_perf_res_obj[key])
        val_arr.push(curr_perf_res_obj[key])
    }

    min_val = Math.min(...val_arr)
    max_val = Math.max(...val_arr)

    /*console.log('val_arr : ', val_arr)
    console.log('min_val : ', min_val)
    console.log('max_val : ', max_val)*/

    const abs_min_val = Math.abs(min_val)//to have 0.0 for min
    //console.log('abs_min_val : ', abs_min_val)


    for(let key in curr_perf_res_obj) {

        const curr_val = curr_perf_res_obj[key]

        //curr_perf_res_obj[key] = Math.round( ( (curr_val + abs_min_val) / (max_val + abs_min_val) ) * 100 )
        curr_perf_res_obj[key] = Math.round( ( (curr_val - abs_min_val) / (max_val - abs_min_val) ) * 100 )
    }


    //console.log('curr_perf_res_obj : ', curr_perf_res_obj)

    return(
        curr_perf_res_obj
    )

}


export {
    curr_perf_result_scaled 
}
  
  
  
  
  /*
     int min_ind = ArrayMinimum(tf_curr_perf_array);
     int max_ind = ArrayMaximum(tf_curr_perf_array);  
     double min_val = tf_curr_perf_array[min_ind];
     double max_val = tf_curr_perf_array[max_ind];
     
     //Alert(max_ind, " : max : ", max_val); 
     max_val = max_val + ( -(min_val) );
     
     //Alert(min_ind, " : min : ", min_val);
     //Alert(max_ind, " : max : ", max_val);
     
     //////////////////////////////////////////////////////////////////////
     min_val =  -(min_val); //to have 0.0 for min
     
     tf_curr_perf_array[0] = MathRound( ( (EUR + min_val) / max_val ) * 100 ); 
     tf_curr_perf_array[1] = MathRound( ( (GBP + min_val) / max_val ) * 100 ); 
     tf_curr_perf_array[2] = MathRound( ( (AUD + min_val) / max_val ) * 100 ); 
     tf_curr_perf_array[3] = MathRound( ( (NZD + min_val) / max_val ) * 100 ); 
     tf_curr_perf_array[4] = MathRound( ( (USD + min_val) / max_val ) * 100 ); 
     tf_curr_perf_array[5] = MathRound( ( (CAD + min_val) / max_val ) * 100 ); 
     tf_curr_perf_array[6] = MathRound( ( (CHF + min_val) / max_val ) * 100 ); 
     tf_curr_perf_array[7] = MathRound( ( (JPY + min_val) / max_val ) * 100 );  
  */
  