

import React, { useEffect, useRef, useState } from 'react';

import {BarChart} from '../chartHelpers/chartFuncClassObj'
import { BsLock, BsUnlock } from "react-icons/bs";
import { MdZoomOutMap, MdZoomInMap   } from "react-icons/md";
import { TfiControlForward } from "react-icons/tfi";
import { MdOutlineArrowBackIosNew } from "react-icons/md"
import { MdOutlineDraw } from "react-icons/md"
import { FiCheck } from "react-icons/fi";
import { GrClose } from "react-icons/gr";
import { IoSettingsOutline } from "react-icons/io5";
import { SlCamera } from "react-icons/sl";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { LiaHandPointer } from "react-icons/lia";
import { MdDoNotTouch, MdOutlineDoNotTouch } from "react-icons/md";
import { MdTouchApp } from "react-icons/md";
import { IoIosRefresh } from "react-icons/io";
import { MdOutlineCandlestickChart } from "react-icons/md";
import { MdCandlestickChart } from "react-icons/md";
import { GoTools } from "react-icons/go";



const ChartHeader = ({

    isDATAloaded, setIsDATAloaded,

    isScrollAllChartsTogether, updateAllSameChartScrollToSwitchedInstruTFfunc,
    
    isHideAllDrawings, setIsHideAllDrawings,

    setRsiDATETIMEnumCountObj, 
    setCorrCalcDATETIME,
    
    isShowCandlePercPerfRange,
    isShowHighlightCandleCompo, setIsShowHighlightCandleCompo,

    isShowChartToolsAlertCompo,
    setIsShowChartToolsAlertCompo,

    canvasWidth,
    canvasHeight,
    chartInfoObj,
    setChartInfoObj,
    currIndexArray,
    currPairArray,
    tfArray,
    forexORcrypto,
    canvasCompoId,

    chartInRowName,
    setIsInstruTFloaded,
    setLocalInstruTFuseEffectReRunCounter,
    defaultChartInstruTFsettingsObj,

    nullifyAllDrawingActivitiesFUNC,
    
    isTouchScreenDevice,
    isScreenScrollLocked,
    isShowChartSettingsScreenCover,
    setWasChartLockedBeforeOnClickSettingsDisplay,
    wasChartLockedBeforeOnClickSettingsDisplay,
    setIsShowChartSettingsScreenCover,
    isDontTouchChart,
    setIsShowLockedChartMovementsBtn,
    setIsDontTouchChart,

    isChartHeaderOptionsSwitchedAtleastOnce, setIsChartHeaderOptionsSwitchedAtleastOnce
}) => {




return (
<div
style={{
    //width:'100%',
    //height:`${canvasWidth<=500 ? canvasHeight*0.065 : canvasHeight*0.04}px`,
    height:'28px',
    display:'flex',
    justifyContent:'space-between',
    //alignItems:'center'
    //border:'1px solid black'
    //isScreenScrollLocked
    //border:'2px solid red'
}}

onClick={()=>{
    //console.log('unSelect All Drawings')
    nullifyAllDrawingActivitiesFUNC()
}}
>
    
    {
        //display:none
        //used to hide & take element out of doc completely
        //whiles having its id event listeneer accessible to avoid error 
    }
    {
        //<button style={{display: `${isScreenScrollLocked ? 'none' : 'flex'}`}} id={`lockButton-${canvasCompoId}`}>{'Lock Chart Scroll'}</button>
        //<button style={{display: `${isScreenScrollLocked ? 'flex' : 'none'}`}} id={`unlockButton-${canvasCompoId}`}>{'Unlock Chart Scroll'}</button>
    }

    {/*<button>candle</button>
    <button>pair</button>
    <button>EURUSD</button>
    <button>h4</button>
    <button>settings</button>
    <button>delete</button>
    <button>camera</button>
    
    const [chartTypeOptionVal, setChartTypeOptionVal] = useState('candle')
    const [currPairCurrIndexTypeVal, setCurrPairCurrIndexTypeVal] = useState('pair')
    const [currPairCurrIndexName, setCurrPairCurrIndexName] = useState("EURUSD")
    const [tf, setTf] = useState("h4")
    */
    }


    <div
    style={{
        display:'flex',
        //width:'100%'
    }}
    >




    {//candle/rsi/stoch
        <select //id="pet-select" 
        value={
            //currPairCurrIndexTypeVal
            chartInfoObj.candleORrsi//indexORpair//candleORrsiORstoch
        } 
        onChange={(e)=>{//if able to change or pass the change test
            //console.log('there is a change')
            if(
                //e.target.value==='pair'
                true
            ){

                //'USD'/'BTC' remain static for LStorage naming sake for rsi/stoch tfs
                //as rsi/stoch comprise of multi currINDEX data but not specific curr
                //therefore: to avoid dynamic LStorage name/index to access sake, we choose a static CURRname for rsi/stoch index chart
                const multiCURRindexSTATICinstruName = forexORcrypto==='forex' ? 'USD' : 'BTC'

                //setChartTypeOptionVal('candle')
                const chartSettingsObjToSaveInLStorage = {
                    ...chartInfoObj,

                    candleORrsi : e.target.value,

                    instruName : e.target.value!=='candle' && chartInfoObj.indexORpair==='index' ? multiCURRindexSTATICinstruName 
                    : chartInfoObj.indexORpair==='pair' ? chartInfoObj.prevCandlePairInstruAndTF.instruName :
                    chartInfoObj.indexORpair==='index' ? chartInfoObj.prevCandleIndexInstruAndTF.instruName : chartInfoObj.instruName/*chartInfoObj.prevInstruTFbeforeLineChart.instruName*/ ,

                    tf : chartInfoObj.tf/*e.target.value==='candle' ? chartInfoObj.prevInstruTFbeforeLineChart.tf : e.target.value==='rsi' ? chartInfoObj.prevRsiIndexTF.tf : chartInfoObj.prevStochIndexTF.tf*/,

                    /*indexORpair : e.target.value==='candle' ? (
                        currIndexArray.includes(chartInfoObj.prevInstruTFbeforeLineChart.instruName) ? 'index' : 'pair') :
                    chartInfoObj.candleORrsi==='candle' ? 'index' : chartInfoObj.indexORpair,*/


                    /*prevInstruTFbeforeLineChart : e.target.value!=='candle' && chartInfoObj.candleORrsi==='candle' ? 
                    {instruName:chartInfoObj.instruName, tf:chartInfoObj.tf} : chartInfoObj.prevInstruTFbeforeLineChart,*/

                    //lineCount : e.target.value==='candle' ? chartInfoObj.lineCount : chartInfoObj.prevTFlineCount[chartInfoObj.prevRsiIndexTF.tf]
                    
                }
                setChartInfoObj(chartSettingsObjToSaveInLStorage)

                window.localStorage.setItem(`${chartInRowName}_Settings` + forexORcrypto, JSON.stringify({...chartSettingsObjToSaveInLStorage, isLoaded: false}))  

                //----------------------------------------------------------------------------------------------------------------------------------------
                //to bypass first or onPageLoad trigger of local db data fetch request, until useEffect reRunner is triggered by choice
                //call setIsChartHeaderOptionsSwitchedAtleastOnce(true) first before loading db fetch request by setLocalInstruTFuseEffectReRunCounter(Date.now())
                //to avoid truthy delay before the useEffect reRunner is triggered for a db fetch request
                if(isChartHeaderOptionsSwitchedAtleastOnce===false){
                    setIsChartHeaderOptionsSwitchedAtleastOnce(true)
                }


                //calc Corr afresh when Auth of corrCalcDATETIME.datetimeStart===''
                setCorrCalcDATETIME({
                    datetimeStart:'', datetimeEnd:'' 
                })

                setRsiDATETIMEnumCountObj({
                    datetimeStart :1,
                    datetimeEnd   :1
                })


                if(isHideAllDrawings){//Operation to make All drawing re-visible when we make a new switch to it - or might have forgotten to switch it on
                    setIsHideAllDrawings(false)
                    //setUseEffectReRunCounter(Date.now())
                }

                //----------------------------------------------------------------------------------------------------------------------------------------
                if(isScrollAllChartsTogether){//update the instruTF settings of the new instruTF we are switching to

                    const LStorageNAMEstr = `${chartSettingsObjToSaveInLStorage.candleORrsi}_${chartSettingsObjToSaveInLStorage.indexORpair}_${
                        chartSettingsObjToSaveInLStorage.candleORrsi==='candle' ? chartSettingsObjToSaveInLStorage.instruName : 'USD'//'USD' remain static for LStorage naming sake for rsi/stoch tfs
                    }_${chartSettingsObjToSaveInLStorage.tf}`

                    updateAllSameChartScrollToSwitchedInstruTFfunc(LStorageNAMEstr)
                }
                //----------------------------------------------------------------------------------------------------------------------------------------


                setIsInstruTFloaded(false)
                setLocalInstruTFuseEffectReRunCounter(Date.now())

                setIsDATAloaded(false)
            }

        }}
        style={{cursor:'pointer', marginLeft:'2px'}}
        >
            {//const [optionsNamesArray, setOptionsNamesArray] = useState([])
                ['candle', 'rsi', 'stoch'].map((currPairCurrIndexTypeName, ind)=>{
                    return(
                        <option value={currPairCurrIndexTypeName} key={ind} style={{cursor:'pointer'}}>{
                            currPairCurrIndexTypeName
                        }</option>
                    )
                })
            }
            
        </select>
    }





    {//pair/index
        <select //id="pet-select" 
        value={
            //currPairCurrIndexTypeVal
            chartInfoObj.indexORpair
        } 
        onChange={(e)=>{
            //console.log('there is a change')
            if(
                //e.target.value==='pair'
                true
            ){

                //'USD'/'BTC' remain static for LStorage naming sake for rsi/stoch tfs
                //as rsi/stoch comprise of multi currINDEX data but not specific curr
                //therefore: to avoid dynamic LStorage name/index to access sake, we choose a static CURRname for rsi/stoch index chart
                const multiCURRindexSTATICinstruName = forexORcrypto==='forex' ? 'USD' : 'BTC'


                //setChartTypeOptionVal('candle')
                const chartSettingsObjToSaveInLStorage = {
                    ...chartInfoObj,
                    indexORpair : e.target.value,

                    //candleORrsi : e.target.value==='pair' ? 'candle' : chartInfoObj.candleORrsi,

                    /*instruName   : e.target.value==='pair' ? chartInfoObj.prevCandlePairInstruAndTF.instruName :
                    e.target.value==='index' ? chartInfoObj.prevCandleIndexInstruAndTF.instruName : 
                    chartInfoObj.instruName,*/

                    instruName : chartInfoObj.candleORrsi !== 'candle' && e.target.value==='index' ? multiCURRindexSTATICinstruName 
                    : e.target.value==='pair' ? chartInfoObj.prevCandlePairInstruAndTF.instruName :
                    e.target.value==='index' ? chartInfoObj.prevCandleIndexInstruAndTF.instruName : chartInfoObj.instruName,

                    tf           : chartInfoObj.tf/*e.target.value==='pair' ? chartInfoObj.prevCandlePairInstruAndTF.tf :
                    e.target.value==='index' ? chartInfoObj.prevCandleIndexInstruAndTF.tf : 
                    chartInfoObj.tf*/,

                    candleORrsi : /*e.target.value==='pair' && (chartInfoObj.candleORrsi==='rsi' || chartInfoObj.candleORrsi==='stoch') 
                    ? 'candle' : */chartInfoObj.candleORrsi,

                    //tf:
                }
                setChartInfoObj(chartSettingsObjToSaveInLStorage)

                window.localStorage.setItem(`${chartInRowName}_Settings` + forexORcrypto, JSON.stringify({...chartSettingsObjToSaveInLStorage, isLoaded: false}))  

                //----------------------------------------------------------------------------------------------------------------------------------------
                //to bypass first or onPageLoad trigger of local db data fetch request, until useEffect reRunner is triggered by choice
                //call setIsChartHeaderOptionsSwitchedAtleastOnce(true) first before loading db fetch request by setLocalInstruTFuseEffectReRunCounter(Date.now())
                //to avoid truthy delay before the useEffect reRunner is triggered for a db fetch request
                if(isChartHeaderOptionsSwitchedAtleastOnce===false){
                    setIsChartHeaderOptionsSwitchedAtleastOnce(true)
                }

                //calc Corr afresh when Auth of corrCalcDATETIME.datetimeStart===''
                setCorrCalcDATETIME({
                    datetimeStart:'', datetimeEnd:'' 
                })

                setRsiDATETIMEnumCountObj({
                    datetimeStart :1,
                    datetimeEnd   :1
                })

                if(isHideAllDrawings){//Operation to make All drawing re-visible when we make a new switch to it - or might have forgotten to switch it on
                    setIsHideAllDrawings(false)
                    //setUseEffectReRunCounter(Date.now())
                }

                //----------------------------------------------------------------------------------------------------------------------------------------
                if(isScrollAllChartsTogether){//update the instruTF settings of the new instruTF we are switching to

                    const LStorageNAMEstr = `${chartSettingsObjToSaveInLStorage.candleORrsi}_${chartSettingsObjToSaveInLStorage.indexORpair}_${
                        chartSettingsObjToSaveInLStorage.candleORrsi==='candle' ? chartSettingsObjToSaveInLStorage.instruName : 'USD'//'USD' remain static for LStorage naming sake for rsi/stoch tfs
                    }_${chartSettingsObjToSaveInLStorage.tf}`

                    updateAllSameChartScrollToSwitchedInstruTFfunc(LStorageNAMEstr)
                }
                //----------------------------------------------------------------------------------------------------------------------------------------


                setIsInstruTFloaded(false)
                setLocalInstruTFuseEffectReRunCounter(Date.now())

                setIsDATAloaded(false)
            }

        }}
        style={{cursor:'pointer', marginLeft:'2px'}}
        >
            {//const [optionsNamesArray, setOptionsNamesArray] = useState([])
                ['pair', 'index', /*'index(all)'*/].map((currPairCurrIndexTypeName, ind)=>{
                    return(
                        <option value={currPairCurrIndexTypeName} key={ind} style={{cursor:'pointer'}}>{
                            currPairCurrIndexTypeName
                        }</option>
                    )
                })
            }
            
        </select>
    }



    {//instru
        (
            chartInfoObj.candleORrsi==='candle'
            || (chartInfoObj.candleORrsi!=='candle' && chartInfoObj.indexORpair==='pair')
        ) 
        
        &&

        <select //id="pet-select" 
        value={
            //currPairCurrIndexName
            chartInfoObj.instruName
        } 
        onChange={(e)=>{
            //setCurrPairCurrIndexName(e.target.value)

            //indexORpair  : 'pair',//'index'
            //currIndexArray : ['EUR', 'GBP', 'AUD', 'NZD', 'USD', 'CAD', 'CHF', 'JPY']
            //currPairArray : ["EURGBP", "EURAUD", "EURNZD", "EURUSD", "EURCAD", "EURCHF", "EURJPY", ]
            const chartSettingsObjToSaveInLStorage = {
                ...chartInfoObj,
                instruName : e.target.value,

                tf : chartInfoObj.tf/*chartInfoObj.indexORpair==='pair' ? chartInfoObj.pairPrevTF[e.target.value] : 
                chartInfoObj.indexPrevTF[e.target.value]*/,

                prevCandlePairInstruAndTF  : chartInfoObj.indexORpair==='pair' ? {
                    instruName:e.target.value, 
                    tf:chartInfoObj.tf
                } : chartInfoObj.prevCandlePairInstruAndTF,

                prevCandleIndexInstruAndTF : chartInfoObj.indexORpair==='index' ? {
                    instruName:e.target.value,    
                    tf:chartInfoObj.tf
                } : chartInfoObj.prevCandleIndexInstruAndTF,
            }

            setChartInfoObj(chartSettingsObjToSaveInLStorage)

            window.localStorage.setItem(`${chartInRowName}_Settings` + forexORcrypto, JSON.stringify({...chartSettingsObjToSaveInLStorage, isLoaded: false}))  

            //----------------------------------------------------------------------------------------------------------------------------------------
            //to bypass first or onPageLoad trigger of local db data fetch request, until useEffect reRunner is triggered by choice
            //call setIsChartHeaderOptionsSwitchedAtleastOnce(true) first before loading db fetch request by setLocalInstruTFuseEffectReRunCounter(Date.now())
            //to avoid truthy delay before the useEffect reRunner is triggered for a db fetch request
            if(isChartHeaderOptionsSwitchedAtleastOnce===false){
                setIsChartHeaderOptionsSwitchedAtleastOnce(true)
            }
            
            //calc Corr afresh when Auth of corrCalcDATETIME.datetimeStart===''
            setCorrCalcDATETIME({
                datetimeStart:'', datetimeEnd:'' 
            })

            setRsiDATETIMEnumCountObj({
                datetimeStart :1,
                datetimeEnd   :1
            })

            if(isHideAllDrawings){//Operation to make All drawing re-visible when we make a new switch to it - or might have forgotten to switch it on
                setIsHideAllDrawings(false)
                //setUseEffectReRunCounter(Date.now())
            }

            //----------------------------------------------------------------------------------------------------------------------------------------
            if(isScrollAllChartsTogether){//update the instruTF settings of the new instruTF we are switching to

                const LStorageNAMEstr = `${chartSettingsObjToSaveInLStorage.candleORrsi}_${chartSettingsObjToSaveInLStorage.indexORpair}_${
                    chartSettingsObjToSaveInLStorage.candleORrsi==='candle' ? chartSettingsObjToSaveInLStorage.instruName : 'USD'//'USD' remain static for LStorage naming sake for rsi/stoch tfs
                }_${chartSettingsObjToSaveInLStorage.tf}`

                updateAllSameChartScrollToSwitchedInstruTFfunc(LStorageNAMEstr)
            }
            //----------------------------------------------------------------------------------------------------------------------------------------


            setIsInstruTFloaded(false)
            setLocalInstruTFuseEffectReRunCounter(Date.now())

            setIsDATAloaded(false)
        }}
        style={{cursor:'pointer', marginLeft:'2px'}}
        >
            {//const [optionsNamesArray, setOptionsNamesArray] = useState([])
                (chartInfoObj.indexORpair/*currPairCurrIndexTypeVal*/==='index' ? currIndexArray : currPairArray).map((currPairCurrIndexNameEL, ind)=>{
                    return(
                        <option value={currPairCurrIndexNameEL} key={ind} style={{cursor:'pointer'}}>{
                            currPairCurrIndexNameEL
                        }</option>
                    )
                })
            }
            
        </select>
    }

    {//tf
        <select //id="pet-select" 
        value={
            //tf
            //chartInfoObj.lineTF
            chartInfoObj.tf
        } 
        onChange={(e)=>{
            //if(isShowChart){
            //setTf(e.target.value)

            const obj = {...chartInfoObj}
            if(chartInfoObj.indexORpair==='pair'){
                obj.pairPrevTF[chartInfoObj.instruName] = e.target.value
            }
            else if(chartInfoObj.indexORpair==='index'){
                obj.indexPrevTF[chartInfoObj.instruName] = e.target.value
            }

            const chartSettingsObjToSaveInLStorage = {
                ...chartInfoObj,
                tf : e.target.value,

                /*prevRsiIndexTF     : chartInfoObj.candleORrsi==='rsi' ? {tf:e.target.value} : chartInfoObj.prevRsiIndexTF,
                prevStochIndexTF   : chartInfoObj.candleORrsi==='stoch' ? {tf:e.target.value} : chartInfoObj.prevStochIndexTF,

                prevCandlePairInstruAndTF  : chartInfoObj.indexORpair==='pair' ? {
                    instruName:chartInfoObj.instruName, 
                    tf:e.target.value
                } : chartInfoObj.prevCandlePairInstruAndTF,

                prevCandleIndexInstruAndTF : chartInfoObj.indexORpair==='index' ? {
                    instruName:chartInfoObj.instruName,    
                    tf:e.target.value
                } : chartInfoObj.prevCandleIndexInstruAndTF,

                //pairPrevTF  : defaultPREVInstruTFobjFunc(currPairArray),// {'EURUSD' : 'h1', 'AUDCAD' : 'h1'},
                //indexPrevTF : defaultPREVInstruTFobjFunc(currIndexArray)//{'EUR' : 'h1', 'CAD' : 'h1'}
                pairPrevTF  : obj.pairPrevTF ,
                indexPrevTF : obj.indexPrevTF,*/

                //lineCount : chartInfoObj.candleORrsi==='candle' ? chartInfoObj.lineCount : chartInfoObj.prevTFlineCount[e.target.value]
            }
            setChartInfoObj(chartSettingsObjToSaveInLStorage)

            window.localStorage.setItem(`${chartInRowName}_Settings` + forexORcrypto, JSON.stringify({...chartSettingsObjToSaveInLStorage, isLoaded: false}))  

            //----------------------------------------------------------------------------------------------------------------------------------------
            //to bypass first or onPageLoad trigger of local db data fetch request, until useEffect reRunner is triggered by choice
            //call setIsChartHeaderOptionsSwitchedAtleastOnce(true) first before loading db fetch request by setLocalInstruTFuseEffectReRunCounter(Date.now())
            //to avoid truthy delay before the useEffect reRunner is triggered for a db fetch request
            if(isChartHeaderOptionsSwitchedAtleastOnce===false){
                setIsChartHeaderOptionsSwitchedAtleastOnce(true)
            }
            
            //calc Corr afresh when Auth of corrCalcDATETIME.datetimeStart===''
            setCorrCalcDATETIME({
                datetimeStart:'', datetimeEnd:'' 
            })

            setRsiDATETIMEnumCountObj({
                datetimeStart :1,
                datetimeEnd   :1
            })

            if(isHideAllDrawings){//Operation to make All drawing re-visible when we make a new switch to it - or might have forgotten to switch it on
                setIsHideAllDrawings(false)
                //setUseEffectReRunCounter(Date.now())
            }

            //----------------------------------------------------------------------------------------------------------------------------------------
            if(isScrollAllChartsTogether){//update the instruTF settings of the new instruTF we are switching to

                const LStorageNAMEstr = `${chartSettingsObjToSaveInLStorage.candleORrsi}_${chartSettingsObjToSaveInLStorage.indexORpair}_${
                    chartSettingsObjToSaveInLStorage.candleORrsi==='candle' ? chartSettingsObjToSaveInLStorage.instruName : 'USD'//'USD' remain static for LStorage naming sake for rsi/stoch tfs
                }_${chartSettingsObjToSaveInLStorage.tf}`

                updateAllSameChartScrollToSwitchedInstruTFfunc(LStorageNAMEstr)
            }
            //----------------------------------------------------------------------------------------------------------------------------------------

            
            setIsInstruTFloaded(false)
            setLocalInstruTFuseEffectReRunCounter(Date.now())

            setIsDATAloaded(false)
            
        }}
        style={{cursor:'pointer', marginLeft:'2px'}}
        >
            {//const [optionsNamesArray, setOptionsNamesArray] = useState([])
                tfArray.map((tfEL, ind)=>{
                    return(
                        <option value={tfEL} key={ind} style={{cursor:'pointer'}}>{
                            tfEL
                        }</option>
                    )
                })
            }
            
        </select>
    }







    </div>


    <div
    style={{
        display:'flex',
        justifyContent:'end',
        width:'100%',
        //border:'1px solid black'
    }}
    >

        {//Settings
            false &&
            <div className='settingsBtnTool'
            style={{
                position:'relative',
                width:`${canvasWidth < 600 ? '100%' : '80px'}`,
                border:'1px solid black',
                marginLeft:'2px',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                cursor:'pointer',
                zIndex:'5'
            }}

            onClick={()=>{
                if(isTouchScreenDevice && isScreenScrollLocked && isShowChartSettingsScreenCover===false){
                    document.getElementById(`unlockButton-${canvasCompoId}`).click()
                    setWasChartLockedBeforeOnClickSettingsDisplay(true)
                }
                else if(isTouchScreenDevice && wasChartLockedBeforeOnClickSettingsDisplay && isScreenScrollLocked===false && isShowChartSettingsScreenCover){
                    document.getElementById(`lockButton-${canvasCompoId}`).click()
                    setWasChartLockedBeforeOnClickSettingsDisplay(false)
                }

                setIsShowChartSettingsScreenCover(!isShowChartSettingsScreenCover)
                //console.log('settings Clicked')
            }}
            >
                <IoSettingsOutline 
                    style={{
                        //cursor:'pointer', 
                        //transform:'scale(1.5)',
                    }}
                    
                />
            </div>
        }


        {//UnToucheable Chart
            <div className={isDontTouchChart ? 'touchableChartBtnTool' : 'unTouchableChartBtnTool'}
            style={{
                position:'relative',
                width:`${canvasWidth < 600 ? '100%' : '80px'}`,
                border:'1px solid black',
                marginLeft:'2px',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                cursor:'pointer',
                zIndex:'5'
            }}

            onClick={()=>{
                
                setIsShowLockedChartMovementsBtn(!isDontTouchChart)
                setIsDontTouchChart(!isDontTouchChart)

                if(isScreenScrollLocked){
                    document.getElementById(`unlockButton-${canvasCompoId}`).click()
                }
                //console.log('settings Clicked')
            }}
            >
                {
                isDontTouchChart===false && 
                    <MdDoNotTouch//BsLock 
                        style={{
                            //cursor:'pointer', 
                            //transform:'scale(1.5)',
                            //color:'blue',
                            marginLeft:'4px'
                        }}
                        
                    />
                }


                {isDontTouchChart&& 
                    <MdTouchApp//BsUnlock 
                    className='coin'
                        style={{
                            //cursor:'pointer', 
                            //transform:'scale(1.5)',
                            color:'blue',
                            marginLeft:'4px'
                        }}
                    
                />}

                {
                    <MdKeyboardArrowDown  
                    style={{
                        transform:'scale(1.0)',
                        //border:'1px solid black'
                        
                    }}

                    
                />
                }


            </div>
        }



        {//Highlight candlesticks strength into blue color
            false &&
            <div className={(isShowCandlePercPerfRange ? 'unhighlightCandleCSMBtnTool' : 'highlightCandleCSMBtnTool') /*+ ' ' + `${isShowCandlePercPerfRange ? 'coin' : ''}`*/}
            style={{
                position:'relative',
                width:`${canvasWidth < 600 ? '100%' : '80px'}`,
                border:'1px solid black',
                marginLeft:'2px',
                marginRight:'2px',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                cursor:'pointer',
                zIndex:'5'
            }}

            onClick={()=>{
                setIsShowHighlightCandleCompo(!isShowHighlightCandleCompo)
            }}
            >
                <MdCandlestickChart className={isShowCandlePercPerfRange ? 'coin' : ''}
                    style={{
                        //cursor:'pointer', 
                        transform:'scale(1.6)',
                        color:`${isShowCandlePercPerfRange ? 'blue' : 'rgba(0,0,0,0.7)'}`
                    }}
                    
                />

                {
                    <MdKeyboardArrowDown  
                    style={{
                        transform:'scale(1.0)',
                    }}
                />
                }

            </div>
        }



        {//Tools Usage
            true
            &&
            <div className='chartToolsUsageBtnTool'
            style={{
                position:'relative',
                width:`${canvasWidth < 600 ? '100%' : '80px'}`,
                border:'1px solid black',
                marginLeft:'2px',
                marginRight:'2px',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                cursor:'pointer',
                zIndex:'5'
            }}

            onClick={()=>{
                setIsShowChartToolsAlertCompo(!isShowChartToolsAlertCompo)

                
            }}
            >
                <GoTools 
                    style={{
                        //cursor:'pointer', 
                        //transform:'scale(1.5)',
                    }}
                    
                />
            </div>
        }


        
        {//Camera
            false && canvasWidth >= 450//only show when device width >= 450
            &&
            <div className='snapShotBtnTool'
            style={{
                position:'relative',
                width:`${canvasWidth < 600 ? '100%' : '80px'}`,
                border:'1px solid black',
                marginLeft:'2px',
                marginRight:'2px',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                cursor:'pointer',
                zIndex:'5'
            }}

            onClick={()=>{
                //setIsDeleteAllChartDrawings(!isDeleteAllChartDrawings)
            }}
            >
                <SlCamera 
                    style={{
                        //cursor:'pointer', 
                        //transform:'scale(1.5)',
                    }}
                    
                />
            </div>
        }


        {//timezone
            false &&
            <div className='timeZoneBtnTool'
            style={{
                position:'relative',
                width:`${canvasWidth < 600 ? '100%' : '80px'}`,
                border:'1px solid black',
                marginLeft:'2px',
                marginRight:'2px',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                cursor:'pointer',
                //zIndex:'5'
            }}

            onClick={()=>{
                //setIsDeleteAllChartDrawings(!isDeleteAllChartDrawings)
            }}
            >
                

                {//pair/index
                    <select //id="pet-select" 
                    value={
                        //currPairCurrIndexTypeVal
                        //chartInfoObj.indexORpair
                        'gmt+/-'
                    } 
                    onChange={(e)=>{
                        //console.log('there is a change')
                        if(
                            //e.target.value==='pair'
                            true
                        ){

                            setLocalInstruTFuseEffectReRunCounter(Date.now())
                        }

                    }}
                    style={{
                        cursor:'pointer', //marginLeft:'2px',
                        width:'100%',height:'100%',
                    }}
                    >
                        {//const [optionsNamesArray, setOptionsNamesArray] = useState([])
                            ['gmt+/-'].map((currPairCurrIndexTypeName, ind)=>{
                                return(
                                    <option value={currPairCurrIndexTypeName} key={ind} style={{cursor:'pointer'}}>{
                                        currPairCurrIndexTypeName
                                    }</option>
                                )
                            })
                        }
                        
                    </select>
                }
            </div>
        }

    </div>


</div>


)
}

export default ChartHeader
