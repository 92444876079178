



import { GrClose } from "react-icons/gr";
import { FaPlay } from "react-icons/fa";
import { IoMdPause } from "react-icons/io";
import { IoPlayBackSharp, IoPlayForwardSharp } from "react-icons/io5";



const PlayBackCompo = ({
    chartNum,
    setCurrIconBtnClicked,
    setIsViewChartBtnClicked,
    isPlayBackBtnClicked,
    setIsPlayBackBtnClicked,
    setPlayBackSTEPval,
    setPlayBackINDEXval,
    setIsShowPAUSEbackwardICON,
    setIsShowPAUSEforwardICON,
    setIsOnPLAYmode,
    onCLOSEplaybackDataArrayRESET,
    playBackMSGstr,
    updateCSMchartByPLAYBACKid,
    isPlaybackDATAloadedAndVALID,
    playBackINDEXval,
    playBackMAXval,
    playBackSTEPval,
    isShowPAUSEforwardICON,
    setPlayBACKuseEffectTimeout,
    isShowPAUSEbackwardICON,
    setIsPlayIndexEQUALbeforePLAYmode,
    isINDEXatEndsFUNC,
    isOnPLAYmode,
    prevPlayEpoch,
    setPrevPlayEpoch
}) => {





return (
<div
style={{
    margin:'0 auto',
    border:'0.5px solid gray',
    borderRadius: '5px',
    height:'4vh',
    //paddingTop: '7px',
    //paddingBottom: '7px',
    width: `95%`,
    //marginLeft:'7%',
    display:'flex', //
    justifyContent:'space-around', 
    alignItems:'center',
    position:'relative',
    marginTop: chartNum===2 ? '25px' : '',
    //boxShadow:`${isPlayBtnSet=== true ? 'rgba(1,100,111,0.1) 0px 7px 29px 0px' : ''}`,
    zIndex: '6'
}}
>


    {true &&
    <div
    style={{
        height:'100%',
        borderRight:'0.5px solid gray',
        width:'7.5%',
        display:'flex', //
        justifyContent:'center', 
        alignItems:'center',
        //backgroundColor:'rgba(0,0,255,0.5)',
        color:'white',
        cursor:'pointer'
    }}



    onClick={()=>{
        //if id=0, then id=len-1

        if(isPlaybackDATAloadedAndVALID(chartNum)){
        
        const prevPlayInterval = Date.now() - prevPlayEpoch
        if(
            isOnPLAYmode ||//to allow instant PAUSEs
            prevPlayInterval > 2000//to avoid DOUBLE-Click loop malfunction
        ){
            const playMODEauth = isShowPAUSEforwardICON===false ? !isOnPLAYmode : false/*isOnPLAYmode*/

            if(isINDEXatEndsFUNC(chartNum)){
            setIsPlayIndexEQUALbeforePLAYmode(true)
            }

            setIsOnPLAYmode(playMODEauth)
            setIsShowPAUSEbackwardICON(isShowPAUSEforwardICON===false ? !isShowPAUSEbackwardICON : false)

            //start PLAY loop
            if(isShowPAUSEforwardICON===false && playMODEauth){
            setPlayBACKuseEffectTimeout(Date.now())
            setPrevPlayEpoch(Date.now())
            }
            else{
            setIsShowPAUSEforwardICON(false)
            }
        }

        setCurrIconBtnClicked(false)
        }

    }}
    >

        {
        isShowPAUSEbackwardICON
        ?
        <IoMdPause
            style={{
            color:'rgba(0,0,255,0.5)',
            color:'black',
            transform:'scale(1.3)'
            }}
        />
        :
        <IoPlayBackSharp
            style={{
            color:'rgba(0,0,255,0.5)',
            color:'rgba(0,0,255,0.5)',
            color:'black',
            transform:'scale(1.5)'
            }}
        />
        }

    </div>
    }


    {true &&
    <div
    style={{
        height:'100%',
        borderRight:'0.5px solid gray',
        width:'7.5%',
        display:'flex', //
        justifyContent:'center', 
        alignItems:'center',
        //backgroundColor:'rgba(0,0,255,0.5)',
        color:'white',
        cursor:'pointer'
    }}

    onClick={()=>{
        //if id=len-1, then id=0
        //reset data of live tracked data before step
        //playbackArrayPRINTtester(1)

        if(isPlaybackDATAloadedAndVALID(chartNum)){

            const prevPlayInterval = Date.now() - prevPlayEpoch
            if(
                (isOnPLAYmode ||//to allow instant PAUSEs
                prevPlayInterval > 2000)//to avoid DOUBLE-Click loop malfunction
            ){
                const playMODEauth = isShowPAUSEbackwardICON===false ? !isOnPLAYmode : false/*isOnPLAYmode*/

                if(isINDEXatEndsFUNC(chartNum)){
                setIsPlayIndexEQUALbeforePLAYmode(true)
                }

                setIsOnPLAYmode(playMODEauth)
                setIsShowPAUSEforwardICON(isShowPAUSEbackwardICON===false ? !isShowPAUSEforwardICON : false)

                //start PLAY loop
                if(isShowPAUSEbackwardICON===false && playMODEauth){
                setPlayBACKuseEffectTimeout(Date.now())
                }
                else{
                setIsShowPAUSEbackwardICON(false)
                }
            }

            setCurrIconBtnClicked(false)

        }
    }}
    >
        {
        isShowPAUSEforwardICON
        ?
        <IoMdPause
            style={{
            color:'rgba(0,0,255,0.5)',
            color:'black',
            transform:'scale(1.3)'
            }}
        />
        :
        <FaPlay
            style={{
            color:'rgba(0,0,255,0.5)',
            color:'black',
            transform:'scale(1.3)'
            }}
        />
        }

    </div>
    }


    {true &&
    <div
    style={{
        height:'100%',
        borderRight:'0.5px solid gray',
        width:'10%',
        display:'flex', //
        justifyContent:'center', 
        alignItems:'center',
        //backgroundColor:'rgba(0,0,255,0.5)',
        //color:'white',
        cursor:'pointer'
    }}

    onClick={()=>{

        
    }}
    >
        {
        <select //id="pet-select" 
        style={{
            height:'100%',
            width: window.innerWidth > 400 ? '80%' : '100%',
            cursor:'pointer',
            border:'0px'
        }}
        value={
            playBackSTEPval
        } 
        onChange={(e)=>{
            if(isPlaybackDATAloadedAndVALID(chartNum)){
            let val = e.target.value
            val = typeof(val) === 'string' ? Number(val) : val
            setPlayBackSTEPval(val)
            }
        }}

        onClick={()=>{
            setCurrIconBtnClicked(false)
        }}
        >
            {//const [optionsNamesArray, setOptionsNamesArray] = useState([])
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((numVal, ind)=>{
                    return(
                        <option value={numVal} key={ind} style={{cursor:'pointer'}}>{
                            'x' + String(numVal)
                        }</option>
                    )
                })
            }
            
        </select>
        }
    </div>
    }



    {true &&
    <div
    style={{
        height:'100%',
        //borderLeft:'0.5px solid gray',
        width:'45%',
        //width:'100%',
        display:'flex', //
        justifyContent:'center', 
        alignItems:'center',
        //backgroundColor:'rgba(255,0,0,0.5)',
        color:'white',
        
    }}

    onClick={()=>{

        
    }}
    >
        <input type="range" id="" name="" 

        min={0} 
        max={playBackMAXval}//dataLen - always made by modulo - eg: if step=2 > 0 but max=11 % step !== 0, then max=11+1=12 % step === 0, else: 11th will not be shown by step
                //by: [0, 0, 0, 1, 2, 3, 4, 5, 6] - modulo the start by repeating the start to match modulo num
        step={1/*playBackSTEPval*/}//1*1000sec - think of it later
        value={playBackINDEXval}

        style={{
        cursor:'pointer',
        width:'96%',
        }}

        onClick={()=>{
        setCurrIconBtnClicked(false)
        }}

        onChange={(e)=>{

        if(isPlaybackDATAloadedAndVALID(chartNum)){
            let val = e.target.value
            val = typeof(val) === 'string' ? Number(val) : val
            //setPlayBackINDEXval(Number(val))

            updateCSMchartByPLAYBACKid(
            val,
            chartNum,
            0,
            true,
            false
            )

        }
        

        //console.log(String(val), typeof(val))
        /*if(val>11){
            alert(val)
        }*/
        }}
        />

    </div>
    }


    {true &&
    <div
    style={{
        height:'100%',
        //borderLeft:'0.5px solid gray',
        width:'20%',
        display:'flex', //
        justifyContent:'center', 
        alignItems:'center',
        backgroundColor:playBackMSGstr.includes('Loading...') ? 'lightgreen' : 'rgba(0,0,0,0.6)',
        color:playBackMSGstr.includes('Loading...') ? 'black' : 'white',
        fontWeight:playBackMSGstr.includes('Loading...') ? 'bold' : '',
        //cursor:'pointer',
        fontSize:'14px',
        position:'relative',
    }}

    onClick={()=>{

        //datetime='', millisecago=1000*60*60, chartNum=1

        //--------------------------------
        //Halt all plays to LOAD data from db
        /*setIsOnPLAYmode(false)
        setIsShowPAUSEforwardICON(false) 
        setIsShowPAUSEbackwardICON(false)
        //-------------------------------

        getPlayBACKdataFromDB('',1000*60*60,1)*/


        
    }}
    >
        {playBackMSGstr} 

        {true && 
        <span style={{
            color:'gray',
            fontWeight:'bold',
            fontSize:'10px', 
            marginLeft:'5px',
            position:'absolute',
            top:'100%'
        }}>{playBackMSGstr.includes('/') ? 'minutes' : ''}</span>
        }

    </div>
    }


    {
    <div
    style={{
        borderLeft:'0.5px solid gray',
        width:'10%',
        height:'100%',
        display:'flex', //
        justifyContent:'center', 
        alignItems:'center',
        cursor:'pointer',
        backgroundColor:'rgba(255, 0,0,0.8)',
    }}

    onClick={()=>{

        //alert('hey')
        onCLOSEplaybackDataArrayRESET(chartNum)

        //--------------------------------
        //Halt/stop all plays to LOAD data from db
        setIsOnPLAYmode(false)
        setIsShowPAUSEforwardICON(false) 
        setIsShowPAUSEbackwardICON(false)

        setPlayBackINDEXval(0)
        setPlayBackSTEPval(1)
        //--------------------------------

        setIsPlayBackBtnClicked(!isPlayBackBtnClicked)
        setIsViewChartBtnClicked(false)
        setCurrIconBtnClicked(false)
    }}
    >
        {
        <GrClose
        style={{
            height:'100%',
            //borderRadius:'50%',
            //padding:'5px',
            color:'white',
            //marginRight:'5px',
            //position:'absolute',
            //top:'-10%'
        }}

        
        />
        }
    </div>
    }

</div>
)
}

export default PlayBackCompo
