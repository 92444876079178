
import {useState, useEffect} from 'react'

import {curr_perf_result_scaled} from '../../../Helpers/curr_perf_result_scaled'




const CURRheader = ({
    forexORcrypto,
    csmDATAtypeIDstr,
    isChartHeaderOptionsSwitchedAtleastOnce,setIsChartHeaderOptionsSwitchedAtleastOnce,

    curr,
    setCurr,
    curr_image_loc,
    curr_name_str_array,
    curr_image_index_array,
    setTimeframeClockBtnClicked,
    get_rgb_color_val_by_perc_val,
    tfArray,
    forexTFdataArray2,

    totalTFcurrPerfSumObj,
    setTotalTFcurrPerfSumObj,
    calc_total_TF_perf,

    forex_data_length,
    forex_data_index,

    setCurrentORhistoric,
}) => {


    




return (
<div
style={{
    //border: `1px solid black`,
    //height: "6%",
    width: "91%",
    margin:'35px auto',
    marginBottom:'5px',
    borderRadius : '15px',
    //marginTop:'20px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    boxShadow:`rgba(1, 100, 111, 0.1) 0px 7px 29px 0px`,
}}
>

{
    [...curr_name_str_array].map((curr_name, index)=>{
    return (
        <div 
        key={index}

        style={{
            backgroundColor: `${curr_name === curr ? 'rgba(0,0,255,0.65)' : '' }`,
            color: `${curr_name === curr ? 'white' : '' }`,
            fontSize: "12px",
            height: "5vh",
            //border: `1px solid black`,
            width: "5vh",
            //fontWeight:'bold',
            //margin:'0px auto',
            //marginRight:'2vh',
            borderRadius : '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            boxShadow:`${curr_name === curr ? `rgba(0, 0, 211, 0.9) 0px 7px 29px 0px` : `rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`}`,
            position:'relative'
        }}

        onClick={()=>{
            /*setTF(timeframe)
            if(tf_time_step_obj[timeframe].includes(defaultTimeStep) === false){
            setDefaultTimeStep('m1')
            }*/
            //isChartHeaderOptionsSwitchedAtleastOnce,setIsChartHeaderOptionsSwitchedAtleastOnce,
            if(isChartHeaderOptionsSwitchedAtleastOnce===false){
                setIsChartHeaderOptionsSwitchedAtleastOnce(true)
            }

            setCurr(curr_name)
            window.localStorage.setItem(`selectedCURR` + forexORcrypto, JSON.stringify(curr_name))
            setTimeframeClockBtnClicked(false)
            
        }}
        >
        {curr_name}

        <div
        style={{
            backgroundColor:`${
            'white'
            }`,
            boxShadow:`${`rgba(1, 100, 111, 0.9) 0px 7px 29px 0px`}`,
            backgroundImage: `url(${curr_name === curr ? curr_image_loc[curr_image_index_array.indexOf(curr_name)] : ''})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            //border: '1px solid black',
            position:'absolute',
            width:'50%',
            height:'50%',
            borderRadius : '50%',
            top:'-15%',
            left:'-25%'
        }}
        >

        </div>



        {true && <div
        style={{
            backgroundColor:`${
            'white'
            }`,
            //boxShadow:`${`rgba(1, 100, 111, 0.9) 0px 7px 29px 0px`}`,
            boxShadow:`${curr_name === curr ? 
                get_rgb_color_val_by_perc_val(calc_total_TF_perf(
                    forexTFdataArray2, 
                    csmDATAtypeIDstr.includes('candle')===false ? tfArray : tfArray.filter(((tfEL, tfELInd)=>{
                        return ['m45', 'h2', 'h6', 'h8', 'h12'].includes(tfEL)===false
                    }))//tfArray 
                )[curr_name]).replace('rgb', 'rgba').replace(')', ', 0.8)') + ' 0px 2px 10px 0px' 
                : 
                `rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`
            }`,
            backgroundImage: `url(${curr_name === curr && false ? curr_image_loc[curr_image_index_array.indexOf(curr_name)] : ''})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            //border: '1px solid black',
            position:'absolute',
            width:'50%',
            height:'50%',
            borderRadius : '50%',
            bottom:'-15%',
            right:'-25%',

            color:'gray',
            fontSize:'0.7em',

            display: 'flex',
            justifyContent:'center',
            alignItems: 'center',
        }}
        >
            {calc_total_TF_perf(
                forexTFdataArray2, 
                csmDATAtypeIDstr.includes('candle')===false ? tfArray : tfArray.filter(((tfEL, tfELInd)=>{
                    return ['m45', 'h2', 'h6', 'h8', 'h12'].includes(tfEL)===false
                })) 
            )[curr_name]}
        </div>}


        </div>
    )
    })
}

</div>
)
}

export default CURRheader
