

import { useState, useEffect } from 'react'

import imageFAKERwhite from './../../../Assets/Images/imageFAKERwhite.png'


const CSMheader = ({
    forexORcrypto,
    tf,
    setTF,
    tableType,
    /*dataAgoType,
    setDataAgoType,
    candleAgoType,
    setCandleAgoType,*/

    csmDATAtypeIDstr, setCsmDATAtypeIDstr,
    switchPairChartTOindexFUNC,
    setIsDateGetterClicked, isDateGetterClicked
}) => {


    //'time ago_current'
    //csmDATAtypeIDstr.split('_')[0]
    const [dataAgoType, setDataAgoType] = useState(csmDATAtypeIDstr.split('_')[0]/*'time ago'*/)
    const [candleAgoType, setCandleAgoType] = useState(csmDATAtypeIDstr.split('_')[1]/*'current'*/)
    //setCsmDATAtypeIDstr(`${dataAgoType}_${candleAgoType}`)

    
//const forexORcrypto = 'forex'

return (
<div className="csmBodyWidthResizer"
style={{
    //border:'1px solid black',
    margin:'0 auto'
}}
>


    <h3 id={`adCompoParentId-${tableType==='TF vs. CURRs' ? 100 : 200}`}
    style={{
        //position:'relative',
        //margin: '30px auto',
        //width:'100%',
        //border:'1px solid black',
        //backgroundColor:'rgba(0, 0, 0, 0.15)',//'rgba(0, 0, 255, 0.3)',
        //height:'50px',//`${isTouchScreenDevice && isShowChartSettingsScreenCover ? '2px' : '20px'}`
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        color:'gray',
        fontWeight:'bold'
    }}
    >
        {tableType==='TF vs. CURRs' ? `Timeframe Vs. Currencies` : 'Currency Vs Timeframes'//'Ad#id - up/down arrow scroll onto'
        }

        {
          <img src={imageFAKERwhite} alt="" 
          style={{
            height:'2px',
            width:'2px'
          }}
          onLoad={(e)=>{
            //console.log('image Loaded')

            // Get the element by its id
            const element = document.getElementById(`adCompoParentId-${tableType==='TF vs. CURRs' ? 100 : 200}`);
            //const element = e.target;

            if (element) {
              // Get the bounding client rect of the element
              const rect = element.getBoundingClientRect();
              // Get the Y position of the element relative to the viewport
              const yPos = rect.top + window.pageYOffset;

              //console.log('Y position of the element:', yPos);

              window.localStorage.setItem(`adCompoParentId-${tableType==='TF vs. CURRs' ? 100 : 200}` + forexORcrypto, JSON.stringify(yPos))
              
            } 
          }}
          />
        }
    </h3>


    <div id={``}
    style={{
        backgroundColor:'rgba(0, 0, 255, 0.2)',
        //position:'relative',
        //margin: '30px auto',
        //width:'100%',
        height:'10px',//`${isTouchScreenDevice && isShowChartSettingsScreenCover ? '2px' : '20px'}`
    }}
    >
    </div>



    <div
    style={{
        display:'flex'
    }}
    >
    
        {//
            <select //id="" 
            value={
                tableType
            } 
            onChange={(e)=>{
                //e.target.value
                
                //nav to 1st chart 1
                const targetDiv = document.getElementById(`adCompoParentId-${tableType==='TF vs. CURRs' ? 200 : 100}`);
                targetDiv.scrollIntoView({
                    behavior: 'smooth' // You can change this to 'auto' for immediate scrolling without smooth animation.
                })
            }}
            style={{cursor:'pointer', marginLeft:'2px', height:'27px'}}
            >
                {//const [optionsNamesArray, setOptionsNamesArray] = useState([])
                    (tableType==='TF vs. CURRs' ? ['TF vs. CURRs', 'CURR vs. TFs'] : ['CURR vs. TFs', 'TF vs. CURRs']).map((el, ind)=>{
                        return(
                            <option value={el} key={ind} style={{cursor:'pointer'}}>{
                                el
                            }</option>
                        )
                    })
                }
                
            </select>
        }


        {//
            <select //id="" 
            value={
                dataAgoType
            } 
            onChange={(e)=>{
                //e.target.value
                if(e.target.value==='---------------'){//do nothing

                }
                else if([ 'candlestick chart', 'RSI index chart', 'stochastic index chart'].includes(e.target.value)){
                    
                    const targetSTR = e.target.value

                    let paramVAL = ''

                    paramVAL = targetSTR.includes('rsi') || targetSTR.includes('RSI') ? 'rsi' : targetSTR.includes('stoch') || targetSTR.includes('STOCH') ? 'stoch' : 'candle' 

                    switchPairChartTOindexFUNC(paramVAL)//param 'candle', 'rsi', 'stoch'

                    //nav to 1st chart 1
                    const targetDiv = document.getElementById(`adCompoParentId-${1}`);
                    targetDiv.scrollIntoView({
                        behavior: 'smooth' // You can change this to 'auto' for immediate scrolling without smooth animation.
                    })
                }
                else{
                    if(tableType==='TF vs. CURRs' && e.target.value==='candle ago' && ['m45', 'h2', 'h6', 'h8', 'h12'].includes(tf)){
                        setTF('h1')
                        localStorage.setItem('tf' + forexORcrypto, 'h1')
                    }

                    setDataAgoType(e.target.value)
                    setCandleAgoType(e.target.value==='time ago' ? 'current' : /*candleAgoType*/'current')
                    const LStorageSTRval = `${e.target.value}_${e.target.value==='time ago' ? 'current' : candleAgoType}`
                    setCsmDATAtypeIDstr(LStorageSTRval)
                    //LStorage as well
                    window.localStorage.setItem(`csmDATAtypeIDstr-${tableType==='TF vs. CURRs' ? 100 : 200}` + forexORcrypto, JSON.stringify(LStorageSTRval))  
                }

            }}
            style={{cursor:'pointer', marginLeft:'2px', height:'27px'}}
            >
                {//
                    ['time ago', 'candle ago', '---------------', 'candlestick chart', 'RSI index chart', 'stochastic index chart'].map((el, ind)=>{
                        return(
                            <option value={el} key={ind} style={{cursor:'pointer'}}>{
                                el
                            }</option>
                        )
                    })
                }
                
            </select>
        }


        {//
            <select //id="" 
            value={
                candleAgoType
            } 
            onChange={(e)=>{
                //e.target.value
                if(dataAgoType==='time ago'){
                    //setCandleAgoType('current')
                    setCandleAgoType(e.target.value)

                    if(e.target.value==='current'){
                        const LStorageSTRval = `${dataAgoType}_${'current'}`
                        setCsmDATAtypeIDstr(LStorageSTRval)
                        //LStorage as well
                        window.localStorage.setItem(`csmDATAtypeIDstr-${tableType==='TF vs. CURRs' ? 100 : 200}` + forexORcrypto, JSON.stringify(LStorageSTRval))  
                    }
                    else if(false){
                        //history - included for content creation sake - to show csm at a given time & after
                        //in the history datetime selection cover-screen compo - include the GMT converter Compo
                        //for users to select or validate their current location time
                        //from which we calc it into MT4 server time to return a suited history time 
                        //that fit the historical current local time of the user
                        
                        //open the history datetime selection Compo - as screenCover - include some gold color in compo 
                        //use data.length to know if historical data loaded
                        //find ways to make 'current' & 'history' selection appear right
                        //for history - display the live csm datetime but not the candle datetime
                        setIsDateGetterClicked(!isDateGetterClicked)

                    }
                }
                else{
                    setCandleAgoType(e.target.value)

                    if(false && e.target.value==='history'){
                        //for history - display the live csm datetime but not the candle datetime
                        setIsDateGetterClicked(!isDateGetterClicked)
                    }
                    else{
                        const LStorageSTRval = `${dataAgoType}_${e.target.value}`
                        setCsmDATAtypeIDstr(LStorageSTRval)
                        //LStorage as well
                        window.localStorage.setItem(`csmDATAtypeIDstr-${tableType==='TF vs. CURRs' ? 100 : 200}` + forexORcrypto, JSON.stringify(LStorageSTRval))  
                    }
                }
            }}
            style={{cursor:'pointer', marginLeft:'2px', height:'27px'}}
            >
                {//const [optionsNamesArray, setOptionsNamesArray] = useState([])
                    (csmDATAtypeIDstr.includes('candle') ? ['current', 'previous'/*, 'history'*/] : ['current'/*, 'history'*/]).map((el, ind)=>{
                        return(
                            <option value={el} key={ind} style={{cursor:'pointer'}}>{
                                el
                            }</option>
                        )
                    })
                }
                
            </select>
        }


        {/*dataAgoType==='time ago' && */false && candleAgoType==='history' &&
            <div
            style={{
                fontSize:'13px',
                marginLeft:'2px', height:'27px',
                display:'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:'rgba(0,0,0,0.5)',
                color:'white', 
                borderRadius:'2px', cursor: 'pointer',
                userSelect:'none'
            }}

            onClick={()=>{
                setIsDateGetterClicked(!isDateGetterClicked)
            }}
            >
                <span style={{marginRight:'4px', marginLeft:'2px',}}>+Add</span> 
            </div>
        }


    </div>
    
</div>
)
}

export default CSMheader
