




const HistReqAlert = ({
    datetimeNotAvailable,
    setIsDatetimeAvailable,
    setIsDateGetterClicked,
}) => {




return (
<div
style={{
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
    position: 'fixed',
    height: '30vh',
    width: '50vh',
    margin:'25vh auto',
    borderRadius:'15px',
    top:'0px',
    right:`0px`,
    bottom:'0px',
    left:`0px`,
    zIndex: '22'
}}
>

    <div
    style={{
        margin:'0px auto',
        marginTop:'5%',
        textAlign:'center',
        color:'white',
        //width:'80%',
    }}
    >
        <div
        style={{
            marginBottom:'5%',
        }}
        >
            <u>OOPS!!!</u> 
        </div>


        <span>Sorry, the historical data for datetime</span> <br />
        <span>{`(${datetimeNotAvailable}) is not available,`}</span> <br />
        <span>Please Add another historical datetime</span>
    </div>



    <div
    style={{
    //backgroundColor: 'red',
    //borderBottom: '1px solid black',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop:'4%',
    height: '15%',
    }}
    >

    <div
        style={{
        marginTop: '8vh',
        color: 'black',//'rgba(0,0,255,0.65)',
        backgroundColor: 'white',
        borderBottom: '1px solid black',
        width: '30%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        borderRadius: '25px',
        cursor:'pointer',
        //boxShadow:`rgba(255, 255, 255, 0.5) 0px 7px 29px 0px`,
        }}

        onClick={()=>{
            setIsDatetimeAvailable(true)
            setIsDateGetterClicked(true)
        }}
    >
        Add
    </div>

    <div
        style={{
        marginTop: '8vh',
        color: 'black',//'rgba(0,0,255,0.65)',
        backgroundColor: 'white',
        borderBottom: '1px solid black',
        width: '30%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        borderRadius: '25px',
        cursor:'pointer',
        //boxShadow:`rgba(255, 255, 255, 0.5) 0px 7px 29px 0px`,
        }}

        onClick={()=>{
            setIsDatetimeAvailable(true)
        }}
    >
        Close
    </div>

    </div>

</div>
)
}

export default HistReqAlert
