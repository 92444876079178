

//const perc_val = 100

//console.log("result : ", get_rgb_color_val_by_perc_val(perc_val))

function get_rgb_color_val_by_perc_val2(perc_val){

    //rgb(255, 255, 0), r:red, g:green, b:blue  
    let rgb;
    //const perc_val = 100

    //base_red_val
    const base_red_val = 255

    //base_green_val
    const base_green_val = 255

    //base_blue_val
    const base_blue_val = 0

    //rgb(255, 255, 0) = yellow = 50%
    const base_perc_color_as_yellow = 50
    if(perc_val === 50){
        //constant red_val 
        const red_val = 255

        //constant green_val 
        const green_val = 255
        
        rgb = `rgb(${red_val}, ${green_val}, 0)`
    }

    //>50%, means: getting closer to green, therefore: make green constant, g=255
    //and: calc & add a given red perc val to the red constant
    else if(perc_val > 50){
        //constant red_val 
        const red_val = 255

        //make green_val constant
        const green_val = 255

        //calc perc_red_val
        let perc_red_val = 1 - ( (perc_val - base_perc_color_as_yellow)/base_perc_color_as_yellow )

        rgb = `rgb(${red_val * perc_red_val}, ${green_val}, 0)`
    }

    //<50%, means: getting closer to red, therefore: make red constant, r=255
    //and: calc & add a given green perc val to the green constant
    else if(perc_val < 50){
        //make red_val constant
        const red_val = 255

        //constant green_val 
        const green_val = 255

        //calc perc_red_val
        let perc_green_val = 1 - ( (base_perc_color_as_yellow - perc_val)/base_perc_color_as_yellow )

        rgb = `rgb(${red_val}, ${green_val * perc_green_val}, 0)`
    }


    //console.log('rgb : ', rgb)

    return rgb

}


//0-49   = red
//50-69  = orange
//70-100 = deep green

function get_rgb_color_val_by_perc_val(perc_val){

    let rgb;


    if(perc_val >= 0 && perc_val < 50){
        rgb = 'rgb(255,0,0)'
    }
    /*else if(perc_val >= 50 && perc_val < 70){
        rgb = 'rgb(255,140,0)'
    }*/
    else{
        rgb = 'rgb(86,197,31)'
    }



    //console.log('rgb : ', rgb)

    return rgb

}


export{
    get_rgb_color_val_by_perc_val,
    get_rgb_color_val_by_perc_val2
}
