//console.log('res : ', timeZoneDatetimeConverter('2023-01-01 04:59:40', -1, 3))

function timeZoneDatetimeConverter(datetime, gmt_hour_count, zero_gmt_deviation){

    //const datetime = '2023-01-01 01:59:40';
    const dateObject = new Date(datetime);
    const timestamp = dateObject.getTime();
    //console.log(timestamp); //1672538380000

    const millisec_in_hour = 3600000
    //const gmt_hour_count = -2
    const final_gmt_hour_count = (-(zero_gmt_deviation)) + (gmt_hour_count)
    const new_timestamp = timestamp + (millisec_in_hour * final_gmt_hour_count)

    const datetimeObj = new Date(new_timestamp);
    //console.log('datetimeObj : ', datetimeObj) //2023-01-01T01:59:40.000Z


    const year = datetimeObj.getFullYear();
    const month = String(datetimeObj.getMonth() + 1).padStart(2, '0'); // Month is 0-based, so add 1 and pad with leading zero if needed.
    const day = String(datetimeObj.getDate()).padStart(2, '0');
    const hours = String(datetimeObj.getHours()).padStart(2, '0');
    const minutes = String(datetimeObj.getMinutes()).padStart(2, '0');
    const seconds = String(datetimeObj.getSeconds()).padStart(2, '0');

    const new_datetime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    //console.log(datetime, ' : ', new_datetime); //'2023-01-01 01:59:40'

    return new_datetime
}

export {
    timeZoneDatetimeConverter
}