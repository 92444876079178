

import {TfiAlarmClock} from 'react-icons/tfi' 
import {MdOutlineKeyboardArrowDown} from 'react-icons/md'
import {VscRefresh, VscClose} from 'react-icons/vsc'
import {FaHourglassStart} from 'react-icons/fa'
import {FiFastForward, FiPlay, FiPause} from 'react-icons/fi'
import {TfiControlBackward, TfiControlForward, TfiControlPlay, TfiClose} from 'react-icons/tfi'
import { IoPlayBackSharp } from "react-icons/io5";

import TimeframeClockCompo from './TimeframeClockCompo'


const PlayerHeader = ({

    playBackMSGstr,
    setPlayBackMSGstr,
    getPlayBACKdataFromDB,
    isPlayBackBtnClicked,
    setIsPlayBackBtnClicked,


    forexORcrypto,
    csmDATAtypeIDstr,

    setLocalInstruTFuseEffectReRunCounter,
    isChartDATArefreshedAtleastOnce,setIsChartDATArefreshedAtleastOnce,

    setDefaultORchartBtnClicked,
    timeframeClockBtnClicked,
    setTimeframeClockBtnClicked,
    switchOnAndInsertTFbyOrder,
    defaultTfArray,
    tfArray,
    extra_tf, 
    setTfArray,
    setIsAllTFsSelected,
    get_live_forex_data,

    forex_data_index,
    forex_data_length,

    remove_hist_data,
    headerName,

    currentORhistoric,
    setCurrentORhistoric,

    forexTFdataArray2,
    calc_total_TF_perf,
    isDateGetterClicked
}) => {


  const chartNum = 2


return (
<div className="" 
style={{
    backgroundColor:`rgba(255,255,255,0.0)`,
    width: `90%`,
    height: '10.6%',
    //border:'1px solid black',
    margin:'0px auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: '3'
}}

onClick={()=>{
    setDefaultORchartBtnClicked(false)
}}
>
    
    
    <TimeframeClockCompo
    timeframeClockBtnClicked={timeframeClockBtnClicked}
    setTimeframeClockBtnClicked={setTimeframeClockBtnClicked}
    setIsAllTFsSelected={setIsAllTFsSelected}
    forexORcrypto={forexORcrypto}
    setTfArray={setTfArray}
    tfArray={tfArray}
    csmDATAtypeIDstr={csmDATAtypeIDstr}
    switchOnAndInsertTFbyOrder={switchOnAndInsertTFbyOrder}
    extra_tf={extra_tf}
    defaultTfArray={defaultTfArray}
    isDateGetterClicked={isDateGetterClicked}
    />


    {
      <div
      style={{
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor:'pointer',
        whiteSpace: 'nowrap',
        boxShadow:`${/*currIconBtnClicked === true ? `rgba(0, 0, 211, 0.9) 0px 7px 29px 0px` : */`rgba(0, 0, 211, 0.4) 0px 7px 29px 0px`}`,
        position: 'relative',
        zIndex: '11',
        borderRadius: '15px',
        height:'3vh',
        paddingLeft: '15px', paddingRight: '15px',
        //marginLeft:'30%',
        marginRight:'0%',
      }}
  
      onClick={()=>{
        setIsPlayBackBtnClicked(!isPlayBackBtnClicked)
  
        setPlayBackMSGstr('Loading...')
  
        setTimeout(()=>{
          getPlayBACKdataFromDB(chartNum)
        }, 1)
  
        setTimeframeClockBtnClicked(false)
      }}
      >
        <IoPlayBackSharp
          style={{
            color:'rgba(0,0,255,0.5)',
            transform:'scale(1.5)'
          }}
        />
        <span style={{
          fontSize:'0.7em',
          color: 'gray',
          marginLeft:'11px'
        }}>play-back</span>
      </div>
    }




    {false &&
      <div
      style={{
        //backgroundColor: 'blue',
        //border: '1px solid black',
        borderRadius: '15px',
        height: '80%',
        width: `${'100%'}`,
        marginLeft:'4%',
        display:'flex', //justifyContent:'center', 
        alignItems:'center',
        position:'relative',
        zIndex: '5'
      }}
      >

        {forex_data_index===forex_data_length-1 && 
        
        <div
        style={{
        //border: '1px solid black',
        //height: '100%',
        width: '100%',
        //marginLeft:'-10%',
        display:'flex', 
        //justifyContent:`${ 'end'}`, 
        alignItems:'center',
        }}
        >

            {
              <div style={{width:'100%', //border: '1px solid black',
            }}></div>
            }

            <VscRefresh 
            style={{
                width:'2vh',
                height:'2vh',
                transform: 'rotate(90deg)',
                backgroundColor: 'white',
                borderRadius: '50%',
                padding: '0.5vh',
                boxShadow:`${ `rgba(1, 100, 111, 0.5) 0px 7px 29px 0px` }`,
                color:'rgba(128,128,128,1)',
                position:'relative',
                zIndex: '5',
                cursor: 'pointer',
                marginRight: '0.4em'
            }}

            onClick={()=>{

              if(window.navigator.onLine===false && window.location.hostname !== 'localhost') {
                //console.log("Oops! You're offline. Please check your network connection..."); 
                setTimeout(()=>{
                  if(document.getElementById('isOnLineId')){
                    document.getElementById('isOnLineId').style.display = ''
                  }
    
                    setTimeout(()=>{
                      if(document.getElementById('isOnLineId')){
                        document.getElementById('isOnLineId').style.display = 'none'
                      }
                    }, 1500)
                }, 100)
              } 
              else {
                if(document.getElementById('isLoadingId')){
                  document.getElementById('isLoadingId').style.display = ''
                }

                
                //isChartDATArefreshedAtleastOnce,setIsChartDATArefreshedAtleastOnce
                if(isChartDATArefreshedAtleastOnce===false){
                  setIsChartDATArefreshedAtleastOnce(true)
                }

                //get_live_forex_data()//(headerName)
                setLocalInstruTFuseEffectReRunCounter(Date.now())

              }
              /*///console.log('hello world')
              const chartContainer = document.getElementById('chartContainerId')
              ///console.log('chartContainer : ', chartContainer)
              const chatContainerFullScrollSize = (chartParentBarWidth * (hist_forex_data.length + 1))
              chartContainer.scrollLeft = chatContainerFullScrollSize;*/
            }}
            />

            <div
            style={{
                fontSize:'0.7em',
                color: 'gray',
                position: 'absolute',
                bottom:`-0.6em`,
                right:'0.0015em'
            }}
            >
                {'Refresh'}
            </div>



        </div>}
        


        {forex_data_index!==forex_data_length-1 && <div
        style={{
        //border: '1px solid black',
        //height: '100%',
        width: '100%',
        //marginLeft:'-10%',
        display:'flex', 
        justifyContent:`${ 'end'}`, 
        alignItems:'center',
        }}
        >

            <VscClose 
            style={{
                width:'2vh',
                height:'2vh',
                //transform: 'rotate(55deg)',
                backgroundColor: 'white',
                borderRadius: '50%',
                padding: '0.5vh',
                boxShadow:`${ `rgba(1, 100, 111, 0.5) 0px 7px 29px 0px` }`,
                color:'rgba(128,128,128,1',
                position:'relative',
                zIndex: '5',
                cursor: 'pointer',
                marginRight: '0.4em'
            }}

            onClick={()=>{
                ///console.log('remove hist data')
                if(forex_data_length===2){
                    setCurrentORhistoric('current')
                }
                remove_hist_data(forex_data_index, headerName)

                document.getElementById('curr_tfs_id').scrollLeft += 50 
                setTimeout(()=>{
                    document.getElementById('curr_tfs_id').scrollLeft -= 50 
                },500)
            }}
            />

            {true && <div
            style={{
                fontSize:'0.7em',
                color: 'gray',
                position: 'absolute',
                bottom:`-0.6em`,
                right:'0.4em'
            }}
            >
                {'Close'}
            </div>}



        </div>}

        

      </div>
    }

</div>
)
}

export default PlayerHeader
