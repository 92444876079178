





const Body = ({

    /*
    const csmDATAtypeIDstrObj = {//csmDATAtypeIDstrObj, csmDATAtypeIDstr
        'time ago_current'    : 0,
        'candle ago_current'  : 1,
        'candle ago_previous' : 2
    }
    */
    csmDATAtypeIDstrObj, csmDATAtypeIDstr,//access data - csmDATAtypeIDstrObj[csmDATAtypeIDstr = 'time ago_current', 'candle ago_current', 'candle ago_previous'] = [0, 1, 2] 


    curr,
    tfArray,
    tf_bar_array,
    forexTFdataArray,
    forexTFdataArray2,
    get_rgb_color_val_by_perc_val,
}) => {




return (
<div className="mainContainer"
style={{
    //border: `1px solid black`,
    width: "90%",
    height: "62%",
    margin:'0px auto',
    marginBottom:'1.6vh',
    display:``,
    padding: '1%'
}}
>



<div className="barParentContainer"
    style={{
    //border: '1px solid black',
    backgroundColor:`white`,
    height:`90%` ,
    width:`` ,
    position:`relative`,
    //borderRadius : '50%', 
    }}
>

    <div
    style={{
        position:`absolute` ,
        width:`100%`,
        height:`87%`,
        bottom:`1%` 
    }}
    >

    <div className="barContainer"
        style={{
        //border: `1px solid black`,
        display: `flex` ,
        //position: `relative',
        height:`100%` ,
        width: `100%` ,
        justifyContent:'space-around',
        //alignItems:'end' ,
        }}
    >
        {
        ( csmDATAtypeIDstr.includes('candle')===false ? tfArray : tfArray.filter(((tfEL, tfELInd)=>{
            return ['m45', 'h2', 'h6', 'h8', 'h12'].includes(tfEL)===false
        })) ).map((tf, index)=>{

            //const tf = 'm30'

            let curr_perc_perf_obj;

            //const tf_obj2 = forexTFdataArray[tf]//curr
            //const tf_obj = JSON.parse(forexTFdataArray2[tf])//curr
            const tf_obj = forexTFdataArray2[tf]//curr
            //console.log(`${tf}_live_data : `, tf_obj)
            //const tf_candle_id_obj = tf_obj[candleId]
            //console.log(`tf_candle_id_obj : `, tf_candle_id_obj)

            //obj key to grab: candle_body_perc_perf_scaled_curr_obj
            curr_perc_perf_obj = tf_obj.curr_perf_scaled
            //console.log(`${tf}_curr_perc_perf_obj : `, curr_perc_perf_obj)

            const dataTypeID = csmDATAtypeIDstrObj[csmDATAtypeIDstr]//0, 1, 2
            const curr_tf_perf_val = curr_perc_perf_obj[curr][dataTypeID]//curr_perc_perf_obj[curr][csmDATAtypeIDstrObj[csmDATAtypeIDstr]]

            /*const [key_array, val_array] = orderCurrPerf(curr_perc_perf_obj)

            //const curr_name_str_array=key_array
            const curr_val_array=val_array
            //console.log(`curr_name_str_array : `, curr_name_str_array)*/


            return(
            <div key={index}
            style={{height:'100%', width:'100%'}}
            >

                <div className="HistogramBarClass" 
                style={{
                    //border: `1px solid red`,
                    height:`${100-(curr_tf_perf_val )}%`,
                    width:'100%',
                    position: `relative` ,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                ></div>

                <div key={index} className="HistogramBarClass"
                    style={{
                    height:`${curr_tf_perf_val +1}%`,
                    width:`100%`,
                    //border: `1px solid black`,
                    position: `relative` ,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    }}
                >

                    <div
                    style={{
                        color: 'rgba(128,128,128,1)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        //border: `1px solid black`,
                        fontSize:'55%',
                        borderRadius:'50%',
                        padding: '2%',
                        position:`absolute` ,
                        width:`2vh` ,
                        height:`2vh`,
                        top:`-25px` ,
                        right:``,
                        boxShadow:`${get_rgb_color_val_by_perc_val(curr_tf_perf_val).replace('rgb', 'rgba').replace(')', ', 0.5)')} 0px 2px 10px 0px`,
                        //boxShadow:`${'rgba(100, 100, 111, 0.5)'} 0px 7px 29px 0px`,
                        //boxShadow:'rgba(100, 100, 111, 0.5) 0px 7px 29px 0px',
                    }}
                    >
                    {curr_tf_perf_val}
                    </div>


                    <div
                    style={{
                        //color: 'rgba(128,128,128,1)',
                        backgroundColor:'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        //border: `1px solid black`,
                        fontSize:'70%',
                        borderRadius:'5px',
                        padding: '2%',
                        position:`absolute` ,
                        width:`${2.7 +  ((13-tf_bar_array.length) * 0.1)}vh` ,
                        height:`${2.7 +  ((13-tf_bar_array.length) * 0.1)}vh`,
                        bottom:`-4vh` ,
                        right:``,
                        //boxShadow:`${get_rgb_color_val_by_perc_val(curr_tf_perf_val).replace('rgb', 'rgba').replace(')', ', 0.5)')} 0px 2px 10px 0px`,
                        //boxShadow:`${'rgba(100, 100, 111, 0.5)'} 0px 7px 29px 0px`,
                        boxShadow:`rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`,
                        //boxShadow:'rgba(100, 100, 111, 0.5) 0px 7px 29px 0px',
                    }}
                    >
                    {tf}
                    </div>


                    <div className="currBar" 
                    style={{
                        backgroundColor: get_rgb_color_val_by_perc_val(curr_tf_perf_val),
                        height:'100%',
                        width: '30%',
                        //border: `1px solid black`,
                        borderTopRightRadius:'15px',
                        borderTopLeftRadius:'15px',
                        borderBottomRightRadius:'15px',
                        borderBottomLeftRadius:'15px',
                        textAlign: 'center',
                        boxShadow:`rgba(1, 100, 111, 0.3) 0px 7px 29px 0px`,
                        //width: '10%',
                    }}
                    >
                    
                    </div>

                </div>

            </div>
            )
        })
        }
    </div>

    </div>

</div>

</div>
)
}

export default Body
