

//visa

import {timeZoneDatetimeConverter} from "../../../../src/Helpers/timeZoneDatetimeConverter"
import {VscClose} from "react-icons/vsc"


const GMTlist = ({
    id,
    gmtArrayList,
    gmtValListObj,
    zero_gmt_deviation,
    datetime,
    setIsShowGMTlist,
    reloadALLchartGlobalFUNC
}) => {




return (
<div 
style={{
    /*border: '1px solid black',
    marginLeft:'5px',
    position:'absolute',
    backgroundColor:'white',

    top:'-400px',
    right:'',
    bottom:'',
    left:'',*/


    width: id==='300' ? '70%' : window.innerWidth <= 500 ? window.innerWidth *0.53 : 500*0.4,
    height: window.innerWidth <= 742 ? window.innerHeight *0.53 : 742*0.6,
    backgroundColor:'rgba(255, 255, 255, 0.99)',
    position:'absolute',
    top:`${ id==='300' ? -50 : (window.innerWidth <= 742 ? -(window.innerHeight *0.54) : -(742*0.61))+8}px` ,
    right: id==='300' ? '' : window.innerWidth <= 500 ? window.innerWidth * - 0.15 : 500* - 0.15,
    boxShadow:`rgba(128, 128, 128, 0.99) 0px 7px 29px 0px`,
    borderRadius:'15px',
    whiteSpace:'normal',

    backgroundColor:'white',
    zIndex:12,

    
    userSelect:'none'
}}
>
    {
        <div
        style={{
            color:'black',
            position:'sticky',
            top:'0px',
            backgroundColor:'white',
            height:'8%',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            color:'blue',
            fontWeight:'bold',
            position:'relative',
        }}
        >
            <span>{'choose your '}</span> <span
            style={{
                marginLeft:'4px',
                color:'gray'
            }}
            >{' (current time)'}</span>

            <div
            style={{
                //border:'1px solid black',
                backgroundColor:'rgba(255, 255, 255, 0.3)',
                position:'absolute',
                top:'-12px',
                right:'-12px',
                borderRadius:'50%',
                display:'flex', justifyContent: 'center', alignItems: 'center',
                cursor:'pointer',
                padding:'8px',
                //zIndex:'100'
            }}

            onClick={()=>{
                /*setPageSessionType('charts')//nullify it later
                setIsPagesDropDownClicked(!isPagesDropDownClicked)
                setIsAlertIconClicked(false)
                setIsTelegramIconClicked(false)*/
                setIsShowGMTlist(false)
                
                //console.log('window width : ', window.innerHeight)
            }}
            >
                <VscClose 
                style={{
                    backgroundColor:'white',
                    color:'red',
                    borderRadius:'50%',
                    boxShadow:`rgba(0, 0, 211, 0.75) 0px 7px 29px 0px`,
                    padding:'6px',
                    transform:'scale(1.3)',
                    zIndex:'20'
                }}
                />
            </div>
        </div>
    }

    <div className="pageDropDownScroolBar"
    style={{
        overflowY:'scroll', 
        overscrollBehavior: 'contain',
        cursor:'default',
        height:'90%'
    }}
    >

        {
            gmtArrayList.map((gmt_el, gmt_el_ind)=>{

                return(
                    <div key={gmt_el_ind} id={gmt_el + id}

                    style={{
                        fontWeight:'bold',
                        borderTop:'0.1px solid gray',
                        height: '10%',
                        width:'80%',
                        margin:'0 auto',
                        cursor:'pointer',

                        backgroundColor:`${
                            //`${'gmt+3'}`
                            (
                                localStorage.getItem('gmt_hour_count')===null ? ('gmt+' + String(zero_gmt_deviation)) : 
                            Number(localStorage.getItem('gmt_hour_count')) < 0 ? ('gmt' + String(Number(localStorage.getItem('gmt_hour_count')))) : 
                            ('gmt+' + String(Number(localStorage.getItem('gmt_hour_count'))))
                            ) === gmt_el ? 'blue' : ''
                        }`,

                        color:`${
                            //`${'gmt+3'}`
                            (
                                localStorage.getItem('gmt_hour_count')===null ? ('gmt+' + String(zero_gmt_deviation)) : 
                            Number(localStorage.getItem('gmt_hour_count')) < 0 ? ('gmt' + String(Number(localStorage.getItem('gmt_hour_count')))) : 
                            ('gmt+' + String(Number(localStorage.getItem('gmt_hour_count'))))
                            ) === gmt_el ? 'white' : ''
                        }`
                    }}
                    
                    onClick={()=>{
                        localStorage.setItem(
                            'gmt_hour_count',
                            String(gmtValListObj[gmt_el])
                        )

                        //reload all charts new time set for them
                        reloadALLchartGlobalFUNC()
                    }}

                    onMouseOver={(e)=>{
                        if(
                            (
                                localStorage.getItem('gmt_hour_count')===null ? ('gmt+' + String(zero_gmt_deviation)) : 
                            Number(localStorage.getItem('gmt_hour_count')) < 0 ? ('gmt' + String(Number(localStorage.getItem('gmt_hour_count')))) : 
                            ('gmt+' + String(Number(localStorage.getItem('gmt_hour_count'))))
                            ) !== gmt_el
                        ){
                            document.getElementById(gmt_el + id).style.backgroundColor = 'rgba(0,0,255,0.99)'
                            document.getElementById(gmt_el + id).style.color = 'white'
                            document.getElementById('gmt' + String(gmt_el_ind) + id).style.color = 'lightgreen'
                        }
                    }}
            
                    onMouseOut={(e)=>{
                        if(
                            (
                                localStorage.getItem('gmt_hour_count')===null ? ('gmt+' + String(zero_gmt_deviation)) : 
                            Number(localStorage.getItem('gmt_hour_count')) < 0 ? ('gmt' + String(Number(localStorage.getItem('gmt_hour_count')))) : 
                            ('gmt+' + String(Number(localStorage.getItem('gmt_hour_count'))))
                            ) !== gmt_el
                        ){
                            document.getElementById(gmt_el + id).style.backgroundColor = 'white'
                            document.getElementById(gmt_el + id).style.color = 'black'
                            document.getElementById('gmt' + String(gmt_el_ind) + id).style.color = 'gray'
                        }
                    }}
                    >

                        { 
                            (
                                timeZoneDatetimeConverter(
                                    datetime, 
                                    gmtValListObj[gmt_el], 
                                    zero_gmt_deviation
                                ).split(' ')[1].split(':')[0] === datetime.split(' ')[1].split(':')[0]
                            )
                            &&
                            <div
                            style={{
                                color:'red',
                                width:'100%',
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                            }}
                            >
                                {true ? 'Server Time' : 'MT4 Server Time'}
                            </div>
                        }


                        <div
                        style={{
                            height:(
                                timeZoneDatetimeConverter(
                                    datetime, 
                                    gmtValListObj[gmt_el], 
                                    zero_gmt_deviation
                                ).split(' ')[1].split(':')[0] === datetime.split(' ')[1].split(':')[0]
                            ) ? '' : '100%',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                        }}
                        >

                            <span id={'gmt' + String(gmt_el_ind) + id}
                            style={{
                                //color:'gray',
                                color:`${
                                    //`${'gmt+3'}`
                                    (
                                        localStorage.getItem('gmt_hour_count')===null ? ('gmt+' + String(zero_gmt_deviation)) : 
                                    Number(localStorage.getItem('gmt_hour_count')) < 0 ? ('gmt' + String(Number(localStorage.getItem('gmt_hour_count')))) : 
                                    ('gmt+' + String(Number(localStorage.getItem('gmt_hour_count'))))
                                    ) === gmt_el ? 'lightgreen' : 'gray'
                                }`
                            }}
                            >
                                {`${timeZoneDatetimeConverter(
                                datetime, 
                                gmtValListObj[gmt_el], 
                                zero_gmt_deviation).split(' ')[1].split(':')[0]} : 
                                ${timeZoneDatetimeConverter(
                                    datetime, 
                                    gmtValListObj[gmt_el], 
                                    zero_gmt_deviation).split(' ')[1].split(':')[1]}`}
                            </span>


                            <span>
                                {/*' - ' + gmt_el*/ }
                            </span>

                        </div>
                        

                        

                        
                    </div>
                )

            })
        }

    </div>

    {
        <div
        style={{
            height:'2%',
        }}
        >
           
        </div>
    }
    
</div>
)
}

export default GMTlist
