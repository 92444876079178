
import React, { useEffect, useRef, useState } from 'react';


import { FaRegCheckCircle } from "react-icons/fa";
import { RiCloseCircleLine } from "react-icons/ri";
import { IoCloseOutline } from "react-icons/io5";
import { IoIosRefresh } from "react-icons/io";
import { FaRegEye } from "react-icons/fa";
import { VscTools } from "react-icons/vsc";
import { BsLock, BsUnlock } from "react-icons/bs";
import { MdZoomOutMap, MdZoomInMap   } from "react-icons/md";
import { MdDoNotTouch } from "react-icons/md";
import { MdTouchApp } from "react-icons/md";
import { LiaFastBackwardSolid } from "react-icons/lia";
import { FaThumbsUp } from "react-icons/fa";




const AllChartSettingsCompo = ({

    setIsShowAllChartSettingsCompo,
    isShowAllChartSettingsCompo,

    isScrollAllChartsTogether, setIsScrollAllChartsTogether,

    canvasWidth,

    scrollAllChartsTogetherInfoTracker, 
    scrollAllChartsTogetherDefaultSettingsObj,

    setScrollAllChartsTogetherInfoTracker,//setScrollAllChartsTogetherInfoTracker(scrollAllChartsTogetherDefaultSettingsObj)
    setChartScrollTogetherUseEffectReRunCounter,//(Date.now()) - setTimeout
    setAllChartsToDEFAULTstructureUseEffectReRunCounter
}) => {

    //const [isScrollAllChartsTogether, setIsScrollAllChartsTogether] = useState(false)

return (
<div
style={{
    //
    
    position:'fixed',
    top:`${canvasWidth < 450 ? 1 :  10}%`,
    //bottom:`${canvasWidth < 450 ? '38%' : '38%'}`,
    left:`${canvasWidth < 450 ? canvasWidth * (1-0.95) * 0.5 :  canvasWidth * (1-0.8) * 0.5}px`,
    right:`${canvasWidth < 450 ? canvasWidth * (1-0.95) * 0.5 :  canvasWidth * (1-0.8) * 0.5}px`,
    zIndex:'12'
}}
>
    <div
    style={{
        position:'relative',
        backgroundColor: 'rgba(0,0,0,0.70)',
        margin:'0px auto',
        //width:'80%'
        width:`${canvasWidth < 450 ? canvasWidth * 0.95 :  canvasWidth * 0.8}px`,
        height:'100%',
        borderRadius:'25px',
        padding:`${canvasWidth < 450 ? 3 : 5}px`,
        paddingBottom:'20px'
        //paddingLeft:`${canvasWidth < 500 ? '1px' : '7px'}`,
    }}
    >
        <div
        style={{
            position:'absolute',
            right:'5%',
            cursor:'pointer'
        }}

        onClick={()=>{
            setIsShowAllChartSettingsCompo(!isShowAllChartSettingsCompo)
        }}
        >
            <IoCloseOutline
            style={{
                backgroundColor:'white',
                borderRadius:'50%',
                transform:'scale(1.8)',
                marginTop:'5px'
            }}
            />
        </div>

        <h4
        style={{
            color:'gold',
            marginLeft:'5%', marginRight:'5%', //marginTop:'5px'
        }}
        >
            <span style={{
                backgroundColor:`${isScrollAllChartsTogether ? 'red' : 'gold'}`,
                color:'black', paddingLeft:'5px', paddingRight:'5px'
            }}>{isScrollAllChartsTogether ? 'Turn OFF' : 'SET'}</span> <span style={{
                color:'gold', paddingLeft:'5px', paddingRight:'5px'
            }}>{isScrollAllChartsTogether ? '' : 'to'}</span>  <span
            style={{
                backgroundColor:'lightblue',
                color:'black', paddingLeft:'5px', paddingRight:'5px'
            }}
            >Scroll  backward or forward & Backtest</span> (All) Charts, currency pairs & index  <u>
                AT Equal datetime & horizontal distance</u>  :
        </h4>

        <div
        style={{
            marginTop:'20px',
            borderTop:'1px solid gold',
            borderBottom:'1px solid gold',
            padding:'5px',
            borderRadius:'5px',
            //minWidth:'50%'
            marginLeft:'5%', marginRight:'5%'
        }} 
        >
            
            {//WARNING NOTICE:
                //isScrollAllChartsTogether &&
                <div
                style={{
                    backgroundColor:`${isScrollAllChartsTogether ? 'lightblue' : 'red'}`,
                    color:`${isScrollAllChartsTogether ? 'black' : 'white'}`,
                    padding:'5px',
                }}
                >
                  <b>WARNING : </b> <span style={{fontSize:'13px'}}> always remember to turn off this Equal chart scroll settings after backtesting,
                  else any horizontal scroll you make will affect the scroll & positioning of other charts or
                  currency pairs & index charts </span>

                    <br />

                  <b>NOTE : </b> <span style={{fontSize:'13px'}}> vertical scrolls & magnification of charts are DEACTIVATED when Equal chart scroll 
                  settings is activated, in order for the settings to focus & structure all charts scrolls & positioning by datetime & horizontal positionings </span>

                  <br />

                  <b>BETTER : </b> <span style={{fontSize:'13px'}}> to have or analyse all charts under Equal scroll UNDER A SPECIFIC Timeframe at a time(, eg: h1),
                  since mixed Timeframes analysis under this settings will results in difference in chart scroll size and positionings
                  </span>

                </div>
            }

            <div
            style={{marginLeft:'', marginTop:'12px'}}
            >
                
                <button //className={'coin'}
                style={{
                    fontWeight:'bold',
                    border:'0px',
                    borderRadius:'5px',
                    padding: '5px',
                    backgroundColor:`${isScrollAllChartsTogether ? 'red' : 'gold'}`,
                    color:'black',
                    cursor:'pointer',
                    width:'50%'
                }}

                onClick={()=>{

                    //Activate AllChartScrollTogether
                    setIsScrollAllChartsTogether(!isScrollAllChartsTogether)

                    //set all charts positioning to default of 0
                    setScrollAllChartsTogetherInfoTracker(scrollAllChartsTogetherDefaultSettingsObj)

                    if(isScrollAllChartsTogether===false){
                        //alert('i am scroll same')
                        //reRun useEffect of All chart to start loading & tracking AllChartScrollTogether
                        //setChartScrollTogetherUseEffectReRunCounter(Date.now())// - setTimeout
                        setAllChartsToDEFAULTstructureUseEffectReRunCounter(Date.now())
                    }

                    //ScreenCoverCompo
                    setIsShowAllChartSettingsCompo(!isShowAllChartSettingsCompo)
                }}
                > 
                
                    {isScrollAllChartsTogether ? 'Turn OFF' : 'SET'}


                    {isScrollAllChartsTogether ? <RiCloseCircleLine className={'coin'}
                    style={{marginLeft:'7px', marginTop:'2px', transform:'scale(1.2)'}}
                    /> : <FaThumbsUp className={'coin'}
                    style={{marginLeft:'7px', marginTop:'2px', transform:'scale(1.2)'}}
                    />}
                </button>
 
                <br />
                <span style={{color:'gold', fontSize:'13px'}}>Always remember to turn this Settings OFF after an All charts under an Equal scroll use</span>
                
                {false &&<button
                style={{
                    fontWeight:'bold',
                    border:'0px',
                    borderRadius:'5px',
                    padding: '5px',
                    backgroundColor:'red',
                    color:'white',
                    cursor:'pointer',
                    marginLeft:'5%', 
                }}

                onClick={()=>{
                    setIsShowAllChartSettingsCompo(!isShowAllChartSettingsCompo)
                }}
                >Close
                <RiCloseCircleLine 
                style={{marginLeft:'4px', transform:'scale(1.5)'}}
                />
                </button>}
                
            </div>

        </div>



        <div
        style={{
            color:'white',//'lightblue',
            fontSize:'12px',
            marginTop:'25px',
            marginLeft:'5%', marginRight:'5%'
        }}
        >
             
             <span  style={{fontWeight:'bold', color:'white'}}>HINT :</span> if your are tired of scrolling backward or forward
             to a specific datetime or position for multiple charts, currency pairs or index at Equal datetime & distance, then use this Settings and later turn it OFF after use.
        </div>


    </div>
    
</div>
)
}

export default AllChartSettingsCompo
