

import {TfiAlarmClock} from 'react-icons/tfi' 
import {MdOutlineKeyboardArrowDown} from 'react-icons/md'




const TimeframeClockCompo = ({
    isPlayBackBtnClicked,
    timeframeClockBtnClicked,
    setTimeframeClockBtnClicked,
    setIsAllTFsSelected,
    forexORcrypto,
    setTfArray,
    tfArray,
    csmDATAtypeIDstr,
    switchOnAndInsertTFbyOrder,
    extra_tf,
    defaultTfArray,
    isDateGetterClicked,
}) => {




return (
<div
style={{
    border: `${timeframeClockBtnClicked === true ? '' : ''}`,
    boxShadow:`${timeframeClockBtnClicked === true ? `rgba(0, 0, 211, 0.9) 0px 7px 29px 0px` : `rgba(0, 0, 211, 0.9) 0px 7px 29px 0px`}`,
    /*boxShadow:`rgba(128, 128, 128, 1.0) 0px 7px 29px 0px`,
    backgroundColor:'rgba(255, 255, 255, 0.0)',*/
    //width:'3vh',
    height:'3vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop:'0vh',
    borderRadius: '15px',
    backgroundColor:`${timeframeClockBtnClicked === true ? 'rgba(0,0,255,0.65)' : '' }`,
    position: 'relative',
    zIndex: '11',

    position : isPlayBackBtnClicked ? 'absolute' : 'relative',
    top : isPlayBackBtnClicked ? '26%' : '',
    right : (isPlayBackBtnClicked && timeframeClockBtnClicked) ? '65%' : isPlayBackBtnClicked ? '3%' : '',
    //marginLeft: isPlayBackBtnClicked ? '30%' : ''
}}
>
    <TfiAlarmClock style={{
    width:'2.5vh',
    height:'2.5vh',
    padding: '0.2vh',
    color:`${timeframeClockBtnClicked === true ? 'white' : '' }`,
    cursor:'pointer',
    }}
    
    onClick={()=>{
    setTimeframeClockBtnClicked(!timeframeClockBtnClicked)
    //alert('timeframeClockBtnClicked')
    }}
    />

    <MdOutlineKeyboardArrowDown style={{
    width:'2.5vh',
    height:'2.5vh',
    padding: '0.2vh',
    transform:`${timeframeClockBtnClicked === true ? `rotate(180deg)` : '' }`,
    color:`${timeframeClockBtnClicked === true ? 'white' : '' }`,
    cursor:'pointer',
    }}
    
    onClick={()=>{
    setTimeframeClockBtnClicked(!timeframeClockBtnClicked)
    }}
    />


    {
    timeframeClockBtnClicked === true
    && isDateGetterClicked===false
    
    &&

    <div
        style={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor:'white',
        position:'absolute',
        width:'450%',
        borderBottomLeftRadius:'7px', 
        borderBottomRightRadius:'7px',
        //height:'100%',
        borderTopRightRadius:`${true ? '7px' : ''}` ,
        bottom:`${ -((defaultTfArray.length * 37)/6) }vh` ,
        left:'0vh',
        //border:'1px solid black',
        //cursor:'pointer',
        }}
    >

        <div>

        {
        defaultTfArray.map((timef, timef_index)=>{
            return (
            <div key={timef_index}
                style={{
                //border: '1px solid black', 
                height:'6vh',
                display:'flex', justifyContent:'center', alignItems:'center',
                borderBottomLeftRadius:'7px', 
                borderBottomRightRadius:'7px',
                borderTopRightRadius:`${timef_index===0 ? '7px' : ''}` ,
                boxShadow:`rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`,
                position: 'relative'
                }}
            >

                <div>
                {timef}
                </div>


                <div
                style={{
                color: 'white',
                backgroundColor:`${tfArray.includes(timef) ? 'rgba(0,0,255,0.7)' : 'gray'}`,
                width: '7vh',
                height: '2.5vh',
                borderRadius: '15px',
                marginLeft:`${timef !== 'm15' && timef !== 'm30' ? '2.3vh' : '1vh'}` ,
                cursor:'pointer',

                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                }}

                onClick={()=>{
                //console.log('heollo world')
                if(tfArray.includes(timef)){
                    
                    setTfArray(
                    tfArray.filter((tf_str, tf_str_index)=>{
                        return tf_str !== timef
                    })
                    )

                    localStorage.setItem('tf_array' + forexORcrypto, 
                    JSON.stringify(
                        tfArray.filter((tf_str, tf_str_index)=>{
                        return tf_str !== timef
                        })
                    )
                    )
                }
                else{
                    switchOnAndInsertTFbyOrder(timef)
                }
                }}
                >

                {
                tfArray.includes(timef) === true
                && 
                <div
                style={{
                //border:'1px solid black'
                fontSize:'0.8em'
                }}
                >
                ON
                </div>}
                
                <div
                style={{
                backgroundColor:'white',
                borderRadius: '50%',
                width: '2.3vh',
                height: '2.3vh',
                }}
                >

                </div>

                {
                tfArray.includes(timef) === false
                && 
                <div
                style={{
                fontSize:'0.8em'
                }}
                >
                OFF
                </div>
                }

                </div>


                
                
            </div>
            )
        })
        }
        </div>



        <div>

        {
        [...extra_tf].map((timef, timef_index)=>{
            return (
            <div key={timef_index}
                style={{
                //border: '1px solid black', 
                height:'6vh',
                width: '90%',
                marginLeft: '10%',
                display:'flex', justifyContent:'center', alignItems:'center',
                borderBottomLeftRadius:'7px', 
                borderBottomRightRadius:'7px',
                borderTopRightRadius:`${timef_index===0 ? '7px' : ''}` ,
                boxShadow:`rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`,
                position: 'relative',

                color:`${csmDATAtypeIDstr.includes('candle') ? 'rgba(255,255,255,0.0)' : ''}`,
                userSelect:'none',
                }}
            >

                <div>
                {/*csmDATAtypeIDstr.includes('candle') ? 'white' : */timef}
                </div>


                <div
                style={{
                color: 'white',
                backgroundColor:`${csmDATAtypeIDstr.includes('candle') ? 'white' : tfArray.includes(timef) ? 'rgba(0,0,255,0.7)' : 'gray'}`,
                width: '8vh',
                height: '2.5vh',
                borderRadius: '15px',
                marginLeft:`${timef !== 'm15' && timef !== 'm30' && timef !== 'h12' && timef !== 'm45' && timef !== 'ALL' ? '2.3vh' : '1vh'}` ,
                cursor: `${csmDATAtypeIDstr.includes('candle')===false ? 'pointer' : ''}`,

                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                }}

                onClick={()=>{
                //console.log('heollo world')

                if(csmDATAtypeIDstr.includes('candle')===false){

                    if(tfArray.includes(timef)){
                    setTfArray(
                        tfArray.filter((tf_str, tf_str_index)=>{
                        return tf_str !== timef
                        })
                    )

                    localStorage.setItem('tf_array' + forexORcrypto, 
                        JSON.stringify(
                        tfArray.filter((tf_str, tf_str_index)=>{
                            return tf_str !== timef
                        })
                        )
                    )

                    setIsAllTFsSelected(false)
                    }
                    else{
                    switchOnAndInsertTFbyOrder(timef)
                    if(tfArray.length===13){
                        setIsAllTFsSelected(true)
                    }
                    
                    }

                }

                }}
                >

                {
                tfArray.includes(timef) === true
                && 
                <div
                style={{
                //border:'1px solid black'
                fontSize:'0.8em'
                }}
                >
                ON
                </div>}
                
                <div
                style={{
                backgroundColor:'white',
                borderRadius: '50%',
                width: '2.3vh',
                height: '2.3vh',
                }}
                >

                </div>

                {
                tfArray.includes(timef) === false
                && 
                <div
                style={{
                fontSize:'0.8em'
                }}
                >
                OFF
                </div>
                }

                </div>


                
            </div>
            )
        })
        }


        {
        true
        
        && 

        <div
        style={{
            //color: 'white',
            //backgroundColor:'rgba(0,0,255,0.7)',
            //position: 'absolute',
            marginLeft: '10%',
            marginTop: '9.5%',
            width: '90%',
            height: '10%',
            right:'-18vh',
            display:'flex', justifyContent:'space-between', alignItems:'center',
            borderRadius: '15px',
            /*borderTopRightRadius:'7px', 
            borderBottomRightRadius:'7px',*/
            fontSize: '0.8em',
            cursor:'pointer',
            //boxShadow:`rgba(1, 100, 111, 1.0) 0px 7px 29px 0px`,
        }}

        onClick={()=>{
            //setTimeframeClockBtnClicked(!timeframeClockBtnClicked)
        }}
        >
            <div
            style={{
            color: `${( csmDATAtypeIDstr.includes('candle')===false ? tfArray : tfArray.filter(((tfEL, tfELInd)=>{
                return ['m45', 'h2', 'h6', 'h8', 'h12'].includes(tfEL)===false
            })) ).length===(13 - (csmDATAtypeIDstr.includes('candle')===false ? 0 : ['m45', 'h2', 'h6', 'h8', 'h12'].length)) ? 'white' : 'gray'}`,
                backgroundColor:`${( csmDATAtypeIDstr.includes('candle')===false ? tfArray : tfArray.filter(((tfEL, tfELInd)=>{
                return ['m45', 'h2', 'h6', 'h8', 'h12'].includes(tfEL)===false
            })) ).length===(13 - (csmDATAtypeIDstr.includes('candle')===false ? 0 : ['m45', 'h2', 'h6', 'h8', 'h12'].length)) ? 'rgba(0,0,255,0.7)' : 'white'}`,
            //border:'1px solid black',
            height: '100%',
            width:'47%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderTopLeftRadius: '15px',
            borderBottomLeftRadius: '15px',
            boxShadow:`rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`,
            }}

            onClick={()=>{
            setTfArray(
                csmDATAtypeIDstr.includes('candle')===false ? 
                ["mn", "w1", "d1", "h12", "h8", "h6", "h4", "h2", "h1", "m45", "m30", "m15", "m5"]
                : ["mn", "w1", "d1", "h12", "h8", "h6", "h4", "h2", "h1", "m45", "m30", "m15", "m5"].filter(((tfEL, tfELInd)=>{
                return ['m45', 'h2', 'h6', 'h8', 'h12'].includes(tfEL)===false
                }))
            )

            localStorage.setItem('tf_array' + forexORcrypto, 
                JSON.stringify(
                csmDATAtypeIDstr.includes('candle')===false ? 
                ["mn", "w1", "d1", "h12", "h8", "h6", "h4", "h2", "h1", "m45", "m30", "m15", "m5"]
                : ["mn", "w1", "d1", "h12", "h8", "h6", "h4", "h2", "h1", "m45", "m30", "m15", "m5"].filter(((tfEL, tfELInd)=>{
                    return ['m45', 'h2', 'h6', 'h8', 'h12'].includes(tfEL)===false
                }))
                )
            )

            /*calc_total_TF_perf(forexTFdataArray2, 
                ["mn", "w1", "d1", "h12", "h8", "h6", "h4", "h2", "h1", "m45", "m30", "m15", "m5"]
            )*/
            
            setIsAllTFsSelected(true)
            }}
            >
            ALL
            </div>

            <div
            style={{
            color: `${( csmDATAtypeIDstr.includes('candle')===false ? tfArray : tfArray.filter(((tfEL, tfELInd)=>{
                return ['m45', 'h2', 'h6', 'h8', 'h12'].includes(tfEL)===false
            })) ).length===0 ? 'white' : 'gray'}`,
                backgroundColor:`${( csmDATAtypeIDstr.includes('candle')===false ? tfArray : tfArray.filter(((tfEL, tfELInd)=>{
                return ['m45', 'h2', 'h6', 'h8', 'h12'].includes(tfEL)===false
            })) ).length===0 ? 'rgba(0,0,255,0.7)' : 'white'}`,
            //border:'1px solid black',
            height: '100%',
            width:'47%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderTopRightRadius: '15px',
            borderBottomRightRadius: '15px',
            boxShadow:`rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`,
            }}

            onClick={()=>{
            setTfArray(
                []
            )

            localStorage.setItem('tf_array' + forexORcrypto, 
                JSON.stringify(
                ['d1']
                )
            )

            setIsAllTFsSelected(false)
            }}
            >
            NONE
            </div>


        </div>
        }


        {
        true
        
        && 

        <div
        style={{
            color: 'white',
            backgroundColor:'rgba(0,0,255,0.7)',
            //position: 'absolute',
            marginLeft: '10%',
            marginTop: '9.5%',
            width: '90%',
            height: '20%',
            right:'-18vh',
            display:'flex', justifyContent:'space-around', alignItems:'center',
            borderRadius: '7px',
            /*borderTopRightRadius:'7px', 
            borderBottomRightRadius:'7px',*/
            fontSize: '0.8em',
            cursor:'pointer',
            boxShadow:`rgba(1, 100, 111, 0.5) 0px 7px 29px 0px`,
        }}

        onClick={()=>{
            setTimeframeClockBtnClicked(!timeframeClockBtnClicked)
        }}
        >
            {'DONE'}
        </div>
        }
        </div>

    </div>
    
    }


</div>
)
}

export default TimeframeClockCompo
